import ClearIcon from '@mui/icons-material/Clear';
import React, { useState } from 'react';
import Swal from 'sweetalert2';
import { HideItem } from '../../ProductManager/productHelper';
import { SubmitShippingOption } from '../EcommerceHelper';

const ShippingOptionForm = ({ node1, getShippingOptionList }) => {
  const [shippingOption, setShippingOption] = useState({
    ShippingName: "",
    ShipiCode: "",
    IsActive: Boolean(true),
    CmsStatus: Boolean(true)
  })
  const { ShippingName, ShipiCode, IsActive, CmsStatus } = shippingOption;
  const onchangeText = (e) => {
    const { name, value } = e.target;
    setShippingOption({
      ...shippingOption,
      [name]: value
    })
  }
  const submitShippingOption = (e) => {
    e.preventDefault()
    Swal.fire({
      title: "In progress!",
      text: "Please wait while doing your request",
      allowOutsideClick: false,
      footer: "&#169; An Nam 2021",
      didOpen: () => {
        Swal.showLoading();
      }
    });
    SubmitShippingOption(shippingOption)
      .then(res => {
        if (res.status === "success") {
          getShippingOptionList(1, "")
          HideItem("#addShippingOption")
          Swal.close()
        }
      })
      .catch(err => {
        console.log(err.response)
        Swal.fire({
          icon: "error",
          title: "Failed",
          text: "There was a problem occurred while doing your request! 😔",
          footer: "&#169; An Nam 2021",
          didOpen: () => {
            Swal.hideLoading();
          }
        })
      })
  }
  return (
    <div className="add-shipping-area" id="addShippingOption">
      <div ref={node1} className="add-shipping-area-container">
        <div className="add-shipping-area-container-header">
          <h3 className="add-shipping-area-container-header-title">
            Shipping Option
          </h3>
          <button className="close-shipping-modal" onClick={(e) => {
            e.preventDefault()
            HideItem("#addShippingOption")
          }}>
            <ClearIcon />
          </button>
        </div>
        <form onSubmit={(e) => { submitShippingOption(e) }}>
          <div className="form">
            <div className="form-container column">
              <label htmlFor="shipingName">Shipping Name</label>
              <input type="text" onChange={(e) => onchangeText(e)} value={ShippingName} name="ShippingName" id="shipingName" />
            </div>
            <div className="form-container column">
              <label htmlFor="shipingCode">Shipping Code</label>
              <input type="text" onChange={(e) => onchangeText(e)} value={ShipiCode} name="ShipiCode" id="shipingCode" />
            </div>
            <div className="form-container column">
              <label htmlFor="showOnCheckout">Show On Checkout</label>
              <select name="IsActive" onChange={(e) => onchangeText(e)} value={IsActive} id="showOnCheckout">
                <option value={Boolean(true)}>Yes</option>
                <option value={Boolean(false)}>No</option>
              </select>
            </div>
            <div className="form-container column">
              <label htmlFor="showOnCMS">Show ON Cms Selection</label>
              <select name="CmsStatus" onChange={(e) => onchangeText(e)} value={CmsStatus} id="showOnCMS">
                <option value={Boolean(true)}>Yes</option>
                <option value={Boolean(false)}>No</option>
              </select>
            </div>

            <div className="form-container row-right">
              <button className="btn-submit" type="submit" >
                CREATE
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ShippingOptionForm;

