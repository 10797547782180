import { useTitle } from 'react-use';
import Slider from '../../ProductManager/Slider';
import AddBannerButton from './AddBannerButton';
import BannerTable from './BannerTable';
import BannerFilter from './Filter/BannerFilter';
const Banner = () => {
  useTitle("An Nam | Banners")
  const productFilter = (e, searchValues) => {
    console.log(searchValues);
  }
  const clearFilter = (e) => {
  }
  return (
    <main className="content-product">
      <section className="content-product-breadcrumbs">
        public management / banner list
      </section>
      <BannerFilter {...{ productFilter, clearFilter }} />
      <BannerTable tableContents={[]} />
      <Slider tableId={"bannerTable"} />
      <AddBannerButton tooltip={"Add Banner"} />
    </main>
  )
}

export default Banner