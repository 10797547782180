import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Popover, Table, Button } from "element-react";
import 'element-theme-default';
import { GetBranchDetails, getPriceLogs } from "./productHelper";

const ProductPerBranchEdit = ({ index, branchId, typeOfProduct, submitBranchProduct, editBranchProduct, value, productId }) => {

  const [branch, setBranch] = useState();
  
  const [branchProduct, setBranchProduct] = useState({
    BranchId: branchId,
    Stocks: 0,
    IsAvailable: true,
    SaleDateFrom: "",
    SaleDateTo: "",
    SalePrice: 0,
    OrderCount: 0,
    IfFeatured: false,
    Price: {
      Regular: 0,
      DineIn: 0,
      KTV: 0,
      Delivery: 0
    }
  });
  const [editable, setEditable] = useState(true)
  const [pricelogData, setPricelogData] = useState([]);
  const tableColumns = [
    {
      label: "Type",
      prop: "PriceType",
      width: 150,
      fixed: "left"
    },
    {
      label: "Old Price",
      prop: "OldPrice",
      width: 100
    },
    {
      label: "Updated Price",
      prop: "NewPrice",
      width: 150
    },
    {
      label: "Updated Date",
      prop: "DateChanged",
      width: 200
    },
    {
      label: "Updated By",
      prop: "ChangedBy",
      width: 150
    },
  ]
  let selectedBranch = localStorage.getItem("branchId");
  const getData = () => {
    let params = {
        ProductId: productId,
        BranchId: selectedBranch
    }
    getPriceLogs(params).then(res => {
        if (res.status === "success") {
          setPricelogData(res?.data[0]?.Logs)
          // ShowItemFlex("#pricelog")
        }else {
          Swal.fire({
            icon: "warning",
            title: "Price Logs",
            text: "Oops, no price logs found.",
            footer: "&#169; An Nam 2021",
          });
        }
        
    }).catch(error => {
      Swal.fire({
        icon: "warning",
        title: "Price Logs",
        text: "Oops, no price logs found.",
        footer: "&#169; An Nam 2021",
      });
    })
  }

  const handleShow = () => {
    getData()
  };

  useEffect(() => {
    Object.keys(value).length > 1 ? setEditable(false) : setEditable(true)
    setBranchProduct({
      BranchId: value?.BranchId ?? branchId,
      Stocks: value?.Stocks ?? 0,
      IsAvailable: value?.IsAvailable ?? true,
      SaleDateFrom: value?.SaleDateFrom ?? "",
      SaleDateTo: value?.SaleDateTo ?? "",
      SalePrice: value?.SalePrice ?? 0,
      OrderCount: value?.OrderCount ?? 0,
      IfFeatured: value?.IfFeatured ?? true,
      Price: {
        Regular: value.Price?.Regular ?? 0,
        DineIn: value.Price?.DineIn ?? 0,
        KTV: value.Price?.KTV ?? 0,
        Delivery: value.Price?.Delivery ?? 0
      }
    })
    console.log(value, 'PRODUCTSS')
    GetBranchDetails(branchId)
      .then(res => setBranch(res.data))
      .catch(error => console.log(error.response))
    return () => {

    };
  }, [branchId, typeOfProduct, value]);
  const { IsAvailable, Stocks, SaleDateFrom, SaleDateTo, SalePrice, Price, IfFeatured } = branchProduct
  const { Regular, DineIn, KTV, Delivery } = Price
  console.log("Price: ", Price)
  const branchProductChange = (e) => {
    const { name, value } = e.target
    setBranchProduct({ ...branchProduct, [name]: value })
  }
  const branchPrizeChange = (e) => {
    const { name, value } = e.target
    setBranchProduct({ ...branchProduct, Price: { ...branchProduct.Price, [name]: Number(value) } })
  }
  const submitBranch = (e) => {
    e.preventDefault()
    let button = "submitBranch" + branchId
    let textButton = document.querySelector(`#${button}`).innerHTML
    if (textButton === "Set Branch") {
      setEditable(false)
      submitBranchProduct(e, branchProduct)
      document.querySelector(`#${button}`).innerHTML = "Edit Branch";
      document.querySelector(`#${button}`).style.backgroundColor = "#f08d19"
    } else {
      setEditable(true)
      editBranchProduct(e, branchId)
      document.querySelector(`#${button}`).innerHTML = "Set Branch";
      document.querySelector(`#${button}`).style.backgroundColor = "#50311b"
    }

  }
  return typeOfProduct === 1 ? (
    <div className="item-container" key={index}>
      {/* <PriceLogModal
        handleClose={handleClose}
        priceLogData={pricelogData}
      /> */}
      <div className="item-row">
        <span> {branch ? branch.Name : ""}</span>
        <Popover placement="right" title="Product: Price Logs" width="500" trigger="click" content={(
          <Table
          style={{width: '100%'}}
          maxHeight={300}
          stripe={true}
          columns={tableColumns}
          data={pricelogData} />
        )}>
          <Button plain={true} type="success" size="small" onClick={(e) => {
            e.preventDefault()
            handleShow()
          }}>
            Price Logs
          </Button>
        </Popover>
      </div>
      
      <div className="item-row">
        <label htmlFor={"isactive" + branchId}>Status</label>
        <select disabled={!editable} onChange={(e) => { branchProductChange(e) }} name="IsAvailable" value={IsAvailable} id={"isactive" + branchId}>
          <option value={Boolean(true)}>Active</option>
          <option value={Boolean(false)}>Inactive</option>
        </select>
      </div>
      <div className="item-row">
        <label htmlFor={"stock" + branchId}>Stock</label>
        <input disabled={!editable} onChange={(e) => { branchProductChange(e) }} value={Stocks} type="number" name="Stocks" id={"stock" + branchId} />
      </div>
      <div className="item-row">
        <label htmlFor={"saleFrom" + branchId}>Sale from</label>
        <input disabled={!editable} onChange={(e) => { branchProductChange(e) }} type="date" name="SaleDateFrom" value={moment(SaleDateFrom).tz("Asia/Manila").format("YYYY-MM-DD")} id={"saleFrom" + branchId} />
      </div>
      <div className="item-row">
        <label htmlFor={"saleto" + branchId}>Sale to</label>
        <input disabled={!editable} onChange={(e) => { branchProductChange(e) }} name="SaleDateTo" value={moment(SaleDateTo).tz("Asia/Manila").format("YYYY-MM-DD")} type="date" id={"saleto" + branchId} />
      </div>
      <div className="item-row">
        <label htmlFor={"saleprice" + branchId}>Sale price</label>
        <input disabled={!editable} onChange={(e) => { branchProductChange(e) }} value={SalePrice} type="number" name="SalePrice" id={"saleprice" + branchId} />
      </div>
      <div className="item-row">
        <label htmlFor={"priceregular" + branchId}>Regular price</label>
        <input disabled={!editable} onChange={(e) => { branchPrizeChange(e) }} value={Regular} name="Regular" type="number" id={"priceregular" + branchId} />
      </div>
      <div className="item-row">
        <label htmlFor={"pricedinein" + branchId}>Dine in price</label>
        <input disabled={!editable} onChange={(e) => { branchPrizeChange(e) }} value={DineIn} name="DineIn" type="number" id={"pricedinein" + branchId} />
      </div>
      <div className="item-row">
        <label htmlFor={"ktvprice" + branchId}>KTV price</label>
        <input disabled={!editable} onChange={(e) => { branchPrizeChange(e) }} value={KTV} name="KTV" type="number" id={"ktvprice" + branchId} />
      </div>
      <div className="item-row">
        <label htmlFor={"deliveryprice" + branchId}>Delivery price</label>
        <input disabled={!editable} onChange={(e) => { branchPrizeChange(e) }} value={Delivery} name="Delivery" type="number" id={"deliveryprice" + branchId} />
      </div>
      <div className="item-row">
        <label htmlFor={"isfeatured" + branchId}>Featured</label>
        <select disabled={!editable} onChange={(e) => { branchProductChange(e) }} value={IfFeatured} name="IfFeatured" id={"isfeatured" + branchId}>
          <option value={Boolean(true)}>Yes</option>
          <option value={Boolean(false)}>No</option>
        </select>
      </div>
      <div className="item-row-right">
        <button id={"submitBranch" + branchId} style={{ backgroundColor: editable ? "#50311b" : "#f08d19" }} onClick={(e) => { submitBranch(e) }}>{editable ? "Set Branch" : "Edit Branch"}</button>
      </div>
    </div>
  ) : typeOfProduct === 2 ? (
    <div className="item-container" key={index}>
      <span> {branch ? branch.Name : ""}</span>
      <div className="item-row">
        <label htmlFor={"isactive" + branchId}>Status</label>
        <select disabled={!editable} onChange={(e) => { branchProductChange(e) }} name="IsAvailable" value={IsAvailable} id={"isactive" + branchId}>
          <option value={Boolean(true)}>Active</option>
          <option value={Boolean(false)}>Inactive</option>
        </select>
      </div>

      <div className="item-row">
        <label htmlFor={"isfeatured" + branchId}>Featured</label>
        <select disabled={!editable} onChange={(e) => { branchProductChange(e) }} value={IfFeatured} name="IfFeatured" id={"isfeatured" + branchId}>
          <option value={Boolean(true)}>Yes</option>
          <option value={Boolean(false)}>No</option>
        </select>
      </div>
      <div className="item-row-right">
        <button id={"submitBranch" + branchId} style={{ backgroundColor: editable ? "#50311b" : "#f08d19" }} onClick={(e) => { submitBranch(e) }}>{editable ? "Set Branch" : "Edit Branch"}</button>
      </div>
    </div >
  ) : (
    <div className="item-container" key={index}>
      <span> No Type Selected</span>
    </div>
  )
};

export default ProductPerBranchEdit;
