import ClearIcon from '@mui/icons-material/Clear';
import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { HideItem } from '../../ProductManager/productHelper';
import { UpdateRate } from "../CurrencyHelper";

const RateUpdate = ({ node, getRateList, rates }) => {
    const [rate, setRate] = useState({
        _id: "",
        rate: 0,
        status: Boolean(true)
    })
    useEffect(() => {
        setRate(rates) 
      }, [rates])

    const submitRate = (e) => {
        e.preventDefault()
        Swal.fire({
            title: "In progress!",
            text: "Please wait while fetching data",
            allowOutsideClick: false,
            footer: "&#169; An Nam 2021",
            didOpen: () => {
              Swal.showLoading();
            }
        });

        let status = (rate.status === "true" || rate.status === true) ? true : false
        let rateBody = {
            _id: rate._id,
            rate: parseFloat(rate.rate),
            status: status
        }
        UpdateRate(rateBody)
        .then(res => {
            getRateList(1, {})
            Swal.close()
            HideItem("#updateRate")
        })
        .catch(err => {
            console.log(err)
          Swal.fire({
            icon: "error",
            title: "Failed",
            text: "There was a problem occurred while doing your request! 😔",
            footer: "&#169; An Nam 2021",
            didOpen: () => {
              Swal.hideLoading();
            }
          })
        })
    }
  
    return (
        <div className="add-shipping-area" id="updateRate">
            <div ref={node} className="add-shipping-area-container">
                <div className="add-shipping-area-container-header">
                    <h3 className="add-shipping-area-container-header-title">
                        UPDATE: Rate Details
                    </h3>
                    <button className="close-shipping-modal" 
                        onClick={(e) => {
                            e.preventDefault()
                            HideItem("#updateRate")
                        }}>
                        <ClearIcon />
                    </button>
                </div>
                {/* -- Forms */}
                <form onSubmit={(e) => { submitRate(e) }}>
                    <div className="form">
                        <div className="form-container column">
                            <label htmlFor="rate">Rate</label>
                            <input type="number" 
                                onChange={(e) => //onchangeText(e)
                                    setRate({
                                        ...rate,
                                        rate: e.target.value
                                    })              
                                } 
                            value={rate.rate} name="rate" id="rate" />
                        </div>

                        <div className="form-container column">
                            <div htmlFor="status">Status</div>
                            <select name="status" 
                            onChange={(e) => //onchangeText(e)
                                setRate({
                                    ...rate,
                                    status: e.target.value
                                })              
                            } 
                            value={rate.status} id="status">
                                <option value={Boolean(true)}>Active</option>
                                <option value={Boolean(false)}>Inactive</option>
                            </select>
                        </div>

                        <div className="form-container row-right">
                            <button className="btn-submit" type="submit" >
                                Update
                            </button>
                        </div>
                    </div>
                </form>
                {/* ---- */}
            </div>
        </div>
    );
}

export default RateUpdate;