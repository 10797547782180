import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import PlusOneIcon from '@mui/icons-material/PlusOne';
import { Tooltip } from "@mui/material";
import React, { useEffect, useState } from 'react';
import { useParams } from "react-router";
import { useTitle } from 'react-use';
import Swal from "sweetalert2";
import { IdSelector, ShowItemFlex } from '../../ProductManager/productHelper';
import { DeleteBank, GetPaymentMethodDetail, UpdatePaymentMethod, UpdatePaymentMethodStatus } from "../EcommerceHelper";
import AddBankForm from './AddBankForm';
import BankTable from './BankTable';
const EditPaymentBankList = () => {
  useTitle('An Nam | Edit Payment method');
  let urlParameter = useParams();
  const [paymentMethodList, setPaymentMethodList] = useState({
    Code: "",
    IsActive: true,
    Name: "",
    Banks: [],
    _id: ""
  })
  const [bankList, setBanklist] = useState([])

  useEffect(() => {
    getPaymentMethodDetails()
    return () => {

    }
    // eslint-disable-next-line
  }, [])
  const getPaymentMethodDetails = () => {
    Swal.fire({
      title: "In progress!",
      text: "Please wait while fetching data",
      allowOutsideClick: false,
      footer: "&#169; An Nam 2021",
      didOpen: () => {
        Swal.showLoading();
      }
    });
    GetPaymentMethodDetail(urlParameter.id)
      .then(
        res => {
          console.log(res.data);
          const { Code, Name, IsActive, Banks, _id } = res.data
          setPaymentMethodList({
            Code: Code,
            Name: Name,
            IsActive: Boolean(IsActive),
            Banks: Banks,
            _id: _id
          })
          setBanklist([...Banks])
          Swal.close()
        }
      )
      .catch(err => {
        console.log(err.response);
        Swal.fire({
          icon: "error",
          title: "Oops!",
          text: "No Product(s) found!",
          footer: "&#169; An Nam 2021",
          didOpen: () => {
            Swal.hideLoading();
          }
        });
      })
  }

  const tableScrollLeft = () => {
    if (scrollHandle === 0) {
      scrollHandle = setInterval(function () {
        IdSelector("paymentMethodTable").style.scrollBehavior = "smooth";
        IdSelector("paymentMethodTable").scrollLeft -= 75;
      }, 100);

    }
  };
  let scrollHandle = 0;
  const tableScrollRight = () => {
    if (scrollHandle === 0) {
      scrollHandle = setInterval(function () {
        IdSelector("paymentMethodTable").style.scrollBehavior = "smooth";

        IdSelector("paymentMethodTable").scrollLeft += 75;
      }, 100);
    }
  };
  const stopScrolling = () => {
    clearInterval(scrollHandle);
    scrollHandle = 0;
  };
  const refreshTable = (e) => {
    e.preventDefault()
  }
  const handleChange = (e) => {
    const { name, value } = e.target
    setPaymentMethodList({ ...paymentMethodList, [name]: value })
  }
  const addBank = (e, bank) => {

    e.preventDefault()
    Swal.fire({
      title: "In progress!",
      text: "Please wait while doing your request",
      allowOutsideClick: false,
      footer: "&#169; An Nam 2021",
      didOpen: () => {
        Swal.showLoading();
      }
    });
    setPaymentMethodList({
      ...paymentMethodList,
      Banks: [...paymentMethodList.Banks, bank]
    })
    UpdatePaymentMethod(paymentMethodList._id, {
      ...paymentMethodList,
      Banks: [...paymentMethodList.Banks, bank]
    })
      .then(res => {
        getPaymentMethodDetails()
        Swal.close()
      })
      .catch(
        err => {
          console.log(err.response);
          Swal.fire({
            icon: "error",
            title: "Failed",
            text: "There was a problem occurred while doing your request! ",
            footer: "&#169; An Nam 2021",
            didOpen: () => {
              Swal.hideLoading();
            }
          })
        }
      )
  }
  const submitPaymentEdit = (e) => {
    e.preventDefault()
    Swal.fire({
      title: "In progress!",
      text: "Please wait while doing your request",
      allowOutsideClick: false,
      footer: "&#169; An Nam 2021",
      didOpen: () => {
        Swal.showLoading();
      }
    });
    UpdatePaymentMethod(paymentMethodList._id,
      {
        ...paymentMethodList,
        Banks: bankList
      })
      .then(res => {
        getPaymentMethodDetails()
        Swal.close()
      })
      .catch(
        err => {
          console.log(err.response);
          Swal.fire({
            icon: "error",
            title: "Failed",
            text: "There was a problem occurred while doing your request! :pensive:",
            footer: "&#169; An Nam 2021",
            didOpen: () => {
              Swal.hideLoading();
            }
          })
        }
      )
  }
  const deleteBank = (e, id) => {
    e.preventDefault()
    console.log(id);
    let parentId = paymentMethodList._id
    DeleteBank(id, parentId)
      .then(res => {
        console.log(res)
        getPaymentMethodDetails()
      })
      .catch(
        err => {
          console.log(err.response);
        }
      )
  }
  const handleChangeBankStatus = (i, id) => {
    setBanklist(
      bankList.map((bankList) =>
        bankList._id === id
          ? { ...bankList, IsActive: Boolean(!bankList.IsActive) }
          : { ...bankList }
      )
    )


    UpdatePaymentMethodStatus(paymentMethodList._id, {
      BankId: id,
      IsActive: !bankList[i].IsActive
    })
      .then(
        res => {
          console.log(res)
        }
      )
      .catch(err => {
        console.log(err.response);
      })

  }
  const handleChangeStatus = (e) => {
    setPaymentMethodList({
      ...paymentMethodList,
      IsActive: Boolean(!paymentMethodList.IsActive)
    })
  }
  const { Code, Name, IsActive } = paymentMethodList
  return (
    <main className="content-product">
      <section className="content-product-breadcrumbs">
        e-commerce / payment method / edit payment-method
      </section>
      {/* start of main Edit payment route */}

      <div className='form-container-edit'>
        <form onSubmit={(e) => { submitPaymentEdit(e) }}>
          <div className='form-column container-w'>
            <div className="input">
              <input type="text" name="Code" id="code" value={Code} disabled={true} />
              <label htmlFor="code">Code</label>
            </div>
            <div className="input">
              <input type="text" onChange={(e) => { handleChange(e) }} name="Name" id="name" value={Name} />
              <label htmlFor="name">Name</label>
            </div>
            <div className="status">
              <label className="switch">
                <input
                  type="checkbox"
                  name="IsActive"
                  value={IsActive}
                  checked={IsActive}
                  onChange={() => handleChangeStatus()}
                />
                <span className="slider round"></span>
              </label>
              {" "}
              {IsActive ? "Active" : "Inactive"}
            </div>
            <div className="row-right">
              <button className="btn-submit" type="submit">
                SAVE
              </button>
            </div>
          </div>
        </form>
        <div className='form-column container-100'>
          <div className='container-60'>
            <BankTable {...{ bankList, deleteBank, handleChangeBankStatus }} />
            <div className="table-slider-left" onMouseEnter={tableScrollLeft} onMouseLeave={stopScrolling}>
              <div className="table-slider-left-button">
                <ChevronLeftIcon />
              </div>
            </div>
            <div
              className="table-slider-right"
              onMouseEnter={tableScrollRight}
              onMouseLeave={stopScrolling}
            >
              <div className="table-slider-right-button">
                <ChevronRightIcon />
              </div>
            </div>
          </div>
        </div>

      </div>

      {/* End of main Edit payment route */}
      <Tooltip title="Add Bank" placement="top-end" arrow>
        <div className='btn-open-modal'>
          <button onClick={(e) => {
            ShowItemFlex("#addBankForm")
            //add bank method form
          }}><PlusOneIcon sx={{ fontSize: "16px" }} /></button>
        </div>
      </Tooltip>
      <AddBankForm {...{ refreshTable, addBank }} />
    </main>
  )
}

export default EditPaymentBankList
