import React from "react";
//import annamIcon from "./../../assets/subicon.svg";
import annam from './../../assets/annamLogo.svg';
const IconLogo = () => {
  return (
    <div>
      <img
        src={annam}
        className=".img-responsive"
        alt="An Nam Icon"
        width="100"
        height="100"
      />
    </div>
  );
};

export default IconLogo;
