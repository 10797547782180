import Proptypes from "prop-types";
import SearchProduct from "./SearchProduct";
import {useEffect, useMemo, useRef, useState} from "react";
import css from "../../OrderDineIn.module.scss"
import Category from "./Category";
import {
    CheckCartAvailability,
    GetPublicProductListPerBranch
} from "../../../../../components/public/PublicHelper";
import moment from "moment";
import translation from "../../../../../translation/translation.json"
import {getCurrentLang, getCurrentUser, getSelectedBranch} from "../../helper";
import Cart from "./Cart";
import Swal from "sweetalert2";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {get, post} from "../../../../../helpers/axiosHelper";

const Body = (props) => {
    const {orderSelected} = props

    const [searchValue, setSearchValue] = useState("")
    const cacheListCategories = useRef([])

    const refExtraProducts = useRef([]) //it is stupid. it save list extra product because when callback call to onSelectProduct extraProducts return init state is empty array
    const [extraProducts, setExtraProducts] = useState([])

    const [listCategories, setListCategories] = useState([])
    const {t} = useTranslation()
    const token = useSelector(state => state.token)

    const SendOption = orderSelected?.order?.SendOption ?? "DINEIN"
    const filterData = (searchValue = "") => {
        searchValue = (searchValue !== null && searchValue !== undefined) ? searchValue.toString().toLowerCase() : ""
        if (searchValue === "") {
            return Object.assign([], cacheListCategories.current)
        }

        const filterProduct = (branchProduct) => {
            const lang = getCurrentLang()

            const product = branchProduct?.Product ?? {}
            const Name = product?.Name ?? {}
            const VN = Name?.VN ?? ""
            const EN = Name?.EN ?? ""
            const CN = Name?.CN ?? ""
            const TH = Name?.TH ?? ""

            let productName = EN
            switch (lang) {
                case "VN":
                    productName = VN;
                    break;
                case "CN":
                    productName = CN;
                    break;
                case "TH":
                    productName = TH;
                    break;
                default:
                    break;
            }

            if (productName === "" || productName === null || productName === undefined) {
                if (EN) {
                    productName = EN
                } else if (VN) {
                    productName = VN
                } else if (CN) {
                    productName = CN
                } else if (TH) {
                    productName = TH
                }
            }

            return (
                productName?.toString().toLowerCase().includes(searchValue)
            )
        }

        const output = []

        for (const index in cacheListCategories.current) {
            let category = Object.assign({}, cacheListCategories.current[index])

            const BranchProducts = category?.BranchProducts ?? []
            const newListProduct = BranchProducts.filter(filterProduct)

            if (newListProduct.length) {
                category.BranchProducts = newListProduct
                output.push(category)
            }
        }

        return output
    }
    const onChangeTextSearch = (searchValue) => {
        setSearchValue(searchValue)
    }

    useEffect(() => {
        const categories = filterData(searchValue)
        setListCategories(categories)
    }, [searchValue])

    const setRefExtraProducts = (value) => {
        refExtraProducts.current = value
    }

    useEffect(() => {
        setRefExtraProducts([])
        setExtraProducts([])

        // eslint-disable-next-line
    }, [orderSelected])

    const onSelectProduct = async (brandProduct) => {
        let productHasBeenAdded = false

        const handleCheckProdStatus = async (extraProduct) => {
            Swal.fire({
                title: t("pleaseWait"),
                text: t("checkingProductAvailable"),
                icon: 'info',
                showCancelButton: false,
                showConfirmButton: false,
                didOpen: () => {
                    Swal.showLoading();
                },
            })


            try {
                const result = await checkProductAvailable(extraProduct)
                if (!result) {
                    Swal.fire({
                        title: t("oops"),
                        text: t("productNotAvailable"),
                        icon: 'error',
                        showCancelButton: false,
                        showConfirmButton: true,
                        didOpen: () => {
                            Swal.hideLoading();
                        },
                    })
                } else {
                    Swal.close()

                    return true
                }
            } catch (e) {
                Swal.fire({
                    title: t("oops"),
                    text: t("someThingWentWrong"),
                    icon: 'error',
                    showCancelButton: false,
                    showConfirmButton: true,
                    didOpen: () => {
                        Swal.hideLoading();
                    },
                })
            }

            return false
        }

        let newListExtraProduct = [...refExtraProducts.current]
        for (let i = 0; i < newListExtraProduct.length; i++) {
            let extraProduct = newListExtraProduct[i]
            const {product: prodInfo, quantity} = extraProduct

            if (prodInfo._id === brandProduct.product._id) {
                productHasBeenAdded = true
                let tempExtraProd = Object.assign({}, extraProduct)
                tempExtraProd.quantity = quantity

                const ok = await handleCheckProdStatus(tempExtraProd)

                if (ok) {
                    extraProduct.quantity = extraProduct.quantity + brandProduct.quantity
                    newListExtraProduct[i] = extraProduct
                }
            }
        }

        if (!productHasBeenAdded) {
            const ok = await handleCheckProdStatus(brandProduct)
            if (ok) {
                newListExtraProduct.push(brandProduct)
                setRefExtraProducts([...newListExtraProduct])
                setExtraProducts(newListExtraProduct)
            }
        }
        else {
            setRefExtraProducts([...newListExtraProduct])
            setExtraProducts(newListExtraProduct)
        }
    }

    const checkProductAvailable = async (extraProduct) => {
        const product = extraProduct.product
        let cartDetail = {
            "ProductId": product.Product._id,
            "Quantity": extraProduct.quantity,
        }

        const requestResult = await CheckCartAvailability([cartDetail])

        const result = requestResult.data
        let isOk = true
        result.forEach(product => {
            if (!product.IsAvailable || !product.IsStock) {
                isOk = false
            }
        })

        return isOk
    }

    const viewListCategories = useMemo(() => {
        const hasOrderSelected = !!orderSelected
        return listCategories.map((category) => {
            return <Category onSelectProduct={onSelectProduct} category={category} key={`list_cate_${category._id}`}
                             SendOption={SendOption} hasOrderSelected={hasOrderSelected}/>
        })
        // eslint-disable-next-line
    }, [listCategories, orderSelected, token])

    const UNLIMITED = -1

    const createSpecialCategory = (products = []) => {
        const now = moment().format("DD_MM_YYYY")
        const id = "SPECIAL_CATE_" + now

        const translate = (id, lang) => {
            const defaultLang = "EN"
            const trans = translation[lang] ?? translation[defaultLang]
            const translationList = trans?.translation ?? {}

            return translationList[id] ?? id
        }

        return {
            _id: id,
            "Attribute": {
                "_id": id,
                "Name": {
                    "EN": translate("todaysspecialdish", "EN"),
                    "VN": translate("todaysspecialdish", "VN"),
                    "CN": translate("todaysspecialdish", "CN"),
                    "TH": translate("todaysspecialdish", "TH")
                },
            },
            BranchProducts: products ?? []
        }
    }

    const GetCategoryList = async (currentBranch) => {
        const res = await get("/cms/order/fetch/dine-in/category", {params: {branch: currentBranch}})
        return res?.data ?? {}
    }

    const getListCategories = async () => {
        const currentBranch = getSelectedBranch()
        if (!currentBranch) {
            return false
        }

        const listCategories = await GetCategoryList(currentBranch)
        const toDayCategory = await GetPublicProductListPerBranch(currentBranch, UNLIMITED)
        const productsTodayCategory = toDayCategory?.data?.paginatedResults ?? []
        const dataNormalCate = listCategories?.data?.paginatedResults ?? []
        const specialCate = createSpecialCategory(productsTodayCategory)
        const mergeCate = [specialCate].concat(dataNormalCate)
        cacheListCategories.current = [specialCate].concat(dataNormalCate)
        setListCategories(mergeCate)
    }

    useEffect(() => {
        async function fetchData() {
            await getListCategories()
        }

        fetchData().then((res) => res)
        // eslint-disable-next-line
    }, [])

    const updateExtraProducts = (value = []) => {
        setRefExtraProducts([...value])
        setExtraProducts(value)
    }

    const updateOrder = (order) => {
        const orderId = order?._id ?? ""
        const branchId = getSelectedBranch()

        const updateData = (data) => {
            props.updateOrder({
                ...props.orderSelected,
                order: data
            })

            setExtraProducts([])
            setRefExtraProducts([])
        }

        Swal.fire({
            title: t("pleaseWait"),
            text: t("updating"),
            icon: 'info',
            showCancelButton: false,
            showConfirmButton: false,
            didOpen: () => {
                Swal.showLoading();
            },
        })

        const handleUpdate = (newOrder) => {
            const orderDetails = newOrder.OrderDetails ?? []
            /**
            const subTotal = calcSubTotal(orderDetails)
            const newDiscount = calcDiscount(newOrder, subTotal)
            const shippingFee = newOrder?.ShippingAmount ?? 0
            let { totalAddedFee } = computeAddedFee(order?.AddFee, subTotal)
            let newTotalAmount = calcTotalAmount(subTotal, newDiscount, totalAddedFee, shippingFee)
            newOrder["TotalAmount"] = Number(newTotalAmount)
            newOrder["OrderAmount"] = Number(subTotal)
            newOrder["Discount"] = Number(newDiscount)


            UpdateOrder(orderId, JSON.stringify(newOrder)).then(
                res => {
                    Swal.close();
                    Swal.fire({
                        toast: true,
                        icon: 'success',
                        title: 'Order updated',
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', Swal.stopTimer)
                            toast.addEventListener('mouseleave', Swal.resumeTimer)
                        }
                    })
                    updateData(res.data)
                }
            ).catch(
                err => {
                    Swal.fire({
                        icon: 'error',
                        title: "Failed!",
                        text: "An error occured, please try again later",
                        footer: "&#169; An Nam 2021"
                    })
                    const { data } = err?.response?.data
                    updateData(data)
                }
            )
             **/


            //new logic handle
            const newOrderDetails = orderDetails.map(detail => {
                    return {
                        productId: detail.ProductId,
                        quantity: detail.Quantity
                    }
                })
            const payload = {
                products: newOrderDetails, orderId, branchId
            }

            post("/cms/order/order-extra/create", payload).then(
                res => {
                    Swal.close();
                    Swal.fire({
                        toast: true,
                        icon: 'success',
                        title: 'Order updated',
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', Swal.stopTimer)
                            toast.addEventListener('mouseleave', Swal.resumeTimer)
                        }
                    })
                    updateData(res.data?.data)
                }
            ).catch(
                () => {
                    Swal.fire({
                        icon: 'error',
                        title: "Failed!",
                        text: "An error occured, please try again later",
                        footer: "&#169; An Nam 2021"
                    })
                    // const { data } = err?.response?.data
                    // updateData(data)
                }
            )
        }

        handleUpdate(order)
    }

    const handleUpdateOrder = (order, newOrderDetail) => {
        const user = getCurrentUser()
        const UserName = user?.UserName ?? ""

        const notes = order?.StatusNote ?? []
        const newNote = (note) => {
            return {
                StatusNum: null,
                Note: note,
                DateAdded: moment().tz("Asia/Manila").format(),
                StatusUpdateBy: UserName
            }
        }

        const orderDetailsFormatted = newOrderDetail.map(product => {
            const Quantity = product?.Quantity ?? 0
            const ExtraQuantity = product?.ExtraQuantity ?? 0
            const Price = product?.Price ?? 0
            const newQuantity = Quantity + ExtraQuantity
            if(Quantity <= 0 && ExtraQuantity > 0) {
                const note = `<strong>${UserName}</strong> added an item ${product?.ProductName?.EN}, Quantity: <strong>${ExtraQuantity}</strong>`
                notes.push(newNote(note))
            }

            if(Quantity > 0 && ExtraQuantity > 0) {
                const note = `<strong>${UserName}</strong> edited an item ${product?.ProductName?.EN}, Quantity: <strong>${Quantity}</strong> -> <strong>${newQuantity}</strong>`
                notes.push(newNote(note))
            }

            if(product.hasOwnProperty("ExtraQuantity")) {
                delete product.ExtraQuantity
            }
            product.Quantity = newQuantity
            product.TotalPrice = newQuantity * Price

            return product
        })
        const newOrder = {
            ...order,
            OrderDetails: orderDetailsFormatted,
            StatusNote: notes
        }

        updateOrder(newOrder)
    }

    return (
        <div className={css.bodyContent}>
            <div className={css.listCategoryContainer}>
                <SearchProduct onChange={onChangeTextSearch} searchValue={searchValue}/>
                <div className={css.categoriesWrapper}>
                    <div className={css.wrapper}>
                        <div className={css.listProductCategory}>
                            {viewListCategories}
                        </div>
                    </div>
                </div>
            </div>
            <div className={css.listProductInCart}>
                <Cart extraProducts={extraProducts} order={orderSelected?.order ?? {}} updateExtraProducts={updateExtraProducts} updateOrder={handleUpdateOrder}/>
            </div>
        </div>
    )
}

Body.propTypes = {
    orderSelected: Proptypes.any,
    updateOrder: Proptypes.func,
}

export default Body