import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import { Pagination } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useTitle } from "react-use";
import Swal from "sweetalert2";
import { countPage } from "../ProductManager/productHelper";
import Slider from "../ProductManager/Slider";
import AddUserForm from "./AddUserForm";
import SearchFilter from "./SearchFilter";
import UpdateUser from "./UpdateUser";
import UpdateUserAccess from "./UpdateUserAccess";
import UserTable from './UserTable';
const UserManager = () => {
  useTitle("An Nam | User List")
  const closeStyle = {
    fontSize: "18px",
    "&:hover": {
      fontWeight: "bold",
      color: "#50311b",
      cursor: "pointer",
    },
  };
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [userList, setUserList] = useState();
  const axios = require("axios");
  const tokens = useSelector((state) => state.token);
  const { token } = tokens.token;
  const node = useRef(null);

  const [dataState, setState] = useState({
    username: "",
    role: "",
    userid: "",
    page: 1,
    search: "",
    isActive: "",
    pageCount: 0
  });
  const [userUpdate, setUserUpdate] = useState({
    _id: "",
    usernameUpdate: "",
    passwordUpdate: "",
    roleUpdate: "",
    isActiveUpdate: "",
    accessUpdate: [],
    branchesUpdate: [],
  });
  const { page, isActive, search } = dataState;
  let pageCount = 0;

  useEffect(() => {
    getUser(page, search, isActive);
    const handleClick = (e) => {
      if (node.current.contains(e.target)) {
        return;
      }
      document.querySelector(".content-main-update-user").style.display =
        "none";
    };

    document.querySelector(".content-main-update-user").style.display = "none";
    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };

    // eslint-disable-next-line
  }, [dataState.page]);
  const handlePage = (e, p) => {
    e.preventDefault()
    setState({
      ...dataState,
      page: p
    })
  }
  const showAddUserForm = () => {
    document.querySelector(".content-main-add-user").style.display = "flex";
  };
  const showUpdateUserForm = (id, selector) => {
    Swal.fire({
      title: "In progress!",
      text: "Please wait while fetching user's data",
      allowOutsideClick: false,
      footer: "&#169; An Nam 2021",
      didOpen: () => {
        Swal.showLoading();
      },
    });
    getSpecificUser(id, selector);
  };
  const closeUpdateUserForm = () => {
    document.querySelector(".content-main-update-user").style.display = "none";
    setUserUpdate({
      _id: "",
      usernameUpdate: "",
      passwordUpdate: "",
      roleUpdate: "",
      isActiveUpdate: "",
      accessUpdate: "",
      branchesUpdate: "",
    });
  };

  const handleOnChangeUpdate = (e) => {
    const { name, value } = e.target;
    setUserUpdate({ ...userUpdate, [name]: value });
  };
  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setState({ ...dataState, [name]: value });
  };

  const getSpecificUser = (id, selector) => {
    let config = {
      method: "get",
      url: `${baseUrl}/cms/users/details/${id}`,
      headers: {
        "Content-Type": "application/json",
        "X-Auth-Token": token,
      },
    };

    axios(config)
      .then(function (response) {
        const { data } = response.data;
        setUserUpdate({
          ...userUpdate,
          _id: data._id,
          usernameUpdate: data.UserName,
          roleUpdate: data.Role,
          isActiveUpdate: data.IsActive,
          accessUpdate: data.Access,
          branchesUpdate: data.Branches,
        });
        Swal.close();
        document.querySelector(selector).style.display = "flex";
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const getUser = (newPage, newSearch, newIsActive) => {
    const config = {
      method: "get",
      url: `${baseUrl}/cms/users/list?page=${newPage}&search=${newSearch}&isActive=${newIsActive}`,
      headers: {
        "Content-Type": "application/json",
        "X-Auth-Token": token,
      },
    };

    axios(config)
      .then(function (response) {
        const { paginatedResults, metadata } = response.data.data;

        const { total, limit } = metadata;
        setUserList({ ...paginatedResults });
        let pages = [];
        for (let i = 1; i <= pageCount; i++) {
          pages.push(i);
        }
        newPage === "0"
          ? setState({
            ...dataState,
            pageNumber: pages,
            page: 1,
            search: newSearch,
            isActive: newIsActive,
            pageCount: countPage(total, limit)
          })
          : setState({
            ...dataState,
            pageNumber: pages,
            page: newPage,
            search: newSearch,
            isActive: newIsActive,
            pageCount: countPage(total, limit)
          });
      })
      .catch(function (error) {
        setState({ ...dataState, pageNumber: [0] });
        setUserList("");
      });
  };
  const resetFilter = () => {
    getUser(1, "", "");
  };



  const showText = () => {
    document.getElementById("title").style.display = "block";
  };
  const hideText = () => {
    document.getElementById("title").style.display = "none";
  };
  return (
    <main className="content-product">
      <section className="content-product-breadcrumbs">users manager / user list</section>
      <SearchFilter
        {...{
          dataState,
          handleOnChange,
          getUser,
          resetFilter,
        }}
      />
      <UserTable {...{ userList, showUpdateUserForm }} />
      <Slider tableId={"userTable"} />

      <Pagination count={dataState.pageCount} onChange={handlePage} page={dataState.page} shape="rounded" />
      <div className="add-user">
        <div id="title">Add User</div>
        <div
          className="icon"
          onMouseEnter={showText}
          onMouseLeave={hideText}
          onClick={showAddUserForm}
        >
          <PersonAddAltIcon sx={{ fontSize: "18px" }} />
        </div>
      </div>


      <UpdateUser
        {...{
          node,
          closeUpdateUserForm,
          handleOnChangeUpdate,
          alert,
          userUpdate,
          token,
          getUser,
          dataState,
          setState,
        }}
      />
      <UpdateUserAccess
        {...{
          closeStyle,
          userUpdate,
          token,
          baseUrl,
        }}
      />
      <AddUserForm
        {...{
          dataState,
          getUser,
          token,
          baseUrl,
        }}
      />
    </main>
  );
};

export default UserManager;
