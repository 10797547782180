import ClearIcon from '@mui/icons-material/Clear';
import _ from 'lodash';
import React, { useState } from 'react';
import Swal from 'sweetalert2';
import { HideItem } from '../../ProductManager/productHelper';
import { SubmitPaymentOption } from '../EcommerceHelper';

const PaymentMethodForm = ({ refreshTable }) => {
  let selectedBranch = localStorage.getItem("branchId");
  const [paymentMethod, setPaymentMethod] = useState({
    Code: "",
    Name: "",
    Banks: [],
    BranchId: selectedBranch,
    IsActive: true
  })
  const [paymentMethodError, setPaymentMethodError] = useState({
    Code: "",
    Name: ""
  })
  const refreshAll = () => {
    setPaymentMethod({
      Code: "",
      Name: "",
      Banks: [],
      BranchId: selectedBranch,
      IsActive: true
    })
    setPaymentMethodError({
      Code: "",
      Name: ""
    })
  }
  const submitPaymentMethod = (e) => {
    e.preventDefault()
    Swal.fire({
      title: "In progress!",
      text: "Please wait while doing your request",
      allowOutsideClick: false,
      footer: "&#169; An Nam 2021",
      didOpen: () => {
        Swal.showLoading();
      }
    });
    SubmitPaymentOption(paymentMethod)
      .then(res => {
        refreshAll()
        refreshTable(e)
        Swal.close()
        HideItem("#addPaymentMethod")
      })
      .catch(
        err => {
          console.log(err.response);
          Swal.close()
          if (err.response) {
            if (_.isEmpty(err.response.data.data)) {
              Swal.fire({
                icon: 'error',
                title: err.response.data.mess,
                text: "Status : " + err.response.status,
                footer: "&#169; An Nam 2021",
                didOpen: () => {
                  Swal.hideLoading();
                }
              })
            } else {
              setPaymentMethodError({ ...err.response.data.data })
            }
          }
        }
      )
  }
  const handleChange = (e) => {
    const { name, value } = e.target
    setPaymentMethod({
      ...paymentMethod,
      [name]: value
    })
  }
  const { Code, Name } = paymentMethod
  return (
    <div className="add-shipping-area" id='addPaymentMethod'>
      <div className="add-shipping-area-container">
        <div className="add-shipping-area-container-header">
          <h3 className="add-shipping-area-container-header-title">
            {' '}
            Payment Method{' '}
          </h3>
          <button className="close-shipping-modal" onClick={(e) => {
            e.preventDefault()
            HideItem("#addPaymentMethod")
          }}>
            <ClearIcon />
          </button>
        </div>
        <form onSubmit={(e) => { submitPaymentMethod(e) }}>
          <div className="form">
            <div className="form-container column">
              <label htmlFor="Code">Code</label>
              <input onChange={(e) => { handleChange(e) }} type="text" name="Code" value={Code} id="Code" />
              <small className='error-message'>{paymentMethodError.Code}</small>
            </div>
            <div className="form-container column">
              <label htmlFor="Name">Name</label>
              <input onChange={(e) => { handleChange(e) }} type="text" name="Name" value={Name} id="Name" />
              <small className='error-message'>{paymentMethodError.Name}</small>
            </div>

            <div className="form-container row-right">
              <button className="btn-submit" type="submit">
                CREATE
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PaymentMethodForm;