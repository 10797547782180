import Swal from "sweetalert2";
import {EXTRA_ORDER_STATUS} from "../ExtraOrder/constant";

const StatusC = ({ Status, value, name, id, text, order, setOrder, currentStatus, currentPaymentStatus }) => {
  const orderPendingResolved = () => {
    const OrdersPending = order?.OrdersPending ?? []
    const ORDER_STT_RESOLVED = [EXTRA_ORDER_STATUS.done, EXTRA_ORDER_STATUS.cancel]
    for (let i = 0; i < OrdersPending.length; i++) {
      const orderPending = OrdersPending[i]
      const orderPendingStatus = orderPending?.status ?? EXTRA_ORDER_STATUS.pending
      if(ORDER_STT_RESOLVED.includes(orderPendingStatus) === false) {
        return false
      }
    }
    return true
  }
  const handleOnchange = (e) => {
    const { name, value } = e.target
    const newStatus = Number(value)
    const oldStatus = Number(currentStatus)
    if (oldStatus > newStatus) {
      return false
    } else {
      if (!currentPaymentStatus && newStatus === 6) {
        Swal.fire({
          icon: "error",
          title: "Failed",
          text: "Customer is not yet paid!",
          footer: "&#169; An Nam 2021",
          confirmButtonColor: '#50311b',
          didOpen: () => {
            Swal.hideLoading();
          }
        })
        return false
      }
      else if (!orderPendingResolved() && newStatus === 6) {
        Swal.fire({
          icon: "error",
          title: "Failed",
          text: "Order Extra in progress. Please check again!",
          footer: "&#169; An Nam 2021",
          confirmButtonColor: '#50311b',
          didOpen: () => {
            Swal.hideLoading();
          }
        })
        return false
      }
      else {
        setOrder({
          ...order,
          [name]: newStatus
        })
      }

    }
  }
  return (
    <label className="sendoption-label option-border" htmlFor={id}>
      <input
        type="radio"
        checked={Status === value}
        value={value}
        name={name}
        id={id}
        onChange={(e) => {
          handleOnchange(e)
        }}
      />{' '}
      {text}
    </label>
  );
};

export default StatusC;
