import _ from "lodash";
import moment from 'moment-timezone';
import {get} from '../../helpers/apiHelpers' 
const axios = require( "axios" );

const baseUrl = process.env.REACT_APP_BASE_URL;
// const baseUrl = 'https://db90-165-22-102-82.ap.ngrok.io'
const branchId = localStorage.getItem( 'branchId' )
const token = localStorage.getItem( 'cmsToken' )
const config = {

  method: undefined,
  url: undefined,
  responseType: undefined,
  headers: {
    'X-Auth-Token': token,
    'Content-Type': 'application/json',
    'cache-control': 'no-cache'
  },
  data: undefined,
  params: undefined

}

// ======================== GET Requests ======================== //

export const GetReceiptImage = async ( requestImage ) => {
  
  config.method = 'get'
  config.url = requestImage
  config.responseType = 'arraybuffer'

  const res = await axios( config );
  return res.data;

};

export const GetOrderList = async ( page, search, searchValue ) => {
  
  const searchValues = _.isEmpty(searchValue) ? {
    Status: "",
    PaymentMethod: "",
    SendOption: "",
    Paid: "",
    Locale: "",
    HasOrderPriority: "",
    DateOrderFrom: "",
    DateOrderTo: "",
    DateCompletedFrom: "",
    DateCompletedTo: "",
  } : {
    ...searchValue
  }

  const {
    Status,
    PaymentMethod,
    SendOption,
    Paid, 
    Locale, 
    HasOrderPriority, 
    DateOrderFrom, 
    DateOrderTo, 
    DateCompletedFrom, 
    DateCompletedTo
  } = searchValues

  const date = ( date ) => {
    return _.isEmpty( date ) || date === undefined || date === null ? "" : moment( date ).tz( "Asia/manila" ).format( "YYYY-MM-DD" )
  }

  const filters = `&Status=${Status}&PaymentMethod=${PaymentMethod}&SendOption=${SendOption}&Paid=${Paid}&Locale=${Locale}&HasOrderPriority=${HasOrderPriority}&DateOrderFrom=${date( DateOrderFrom )}&DateOrderTo=${date( DateOrderTo )}&DateCompletedFrom=${date( DateCompletedFrom )}&DateCompletedTo=${date( DateCompletedTo )}`

  
  config.method = "get"
  config.url = `${baseUrl}/cms/order/fetch/order-list?BranchId=${branchId}&SelectedBranch=${branchId}&page=${page}&search=${search}${filters}`

  const res = await axios( config );
  return res.data;

};

export const GetOrderDetail = async ( id ) => {
  
  config.method = "get"
  config.url = `${baseUrl}/cms/order/${id}?SelectedBranch=${branchId}`
    
  const res = await axios( config );
  return res.data;

};

/**
 * Function for retrieving shipping area list 
 * @param {number=} page   The page number you want to search from
 * @param {string=} search Name of certain area you want to search
 * @param {*} searchValue 
 * @return {Promise<{}>} 
 * 
 */
export const GetShippingAreaList = async ( page = undefined, search = undefined, searchValue = undefined ) => {

  const searchValues = _.isEmpty(searchValue) ? {
    isActive: "",
  } : {
    ...searchValue
  }
  const { isActive } = searchValues
  const filters = isActive ? `&isActive=${isActive}` : ""
  page = page ? `&page=${page}` : ""
  search = search ? `&search=${search}` : ""
  
  config.method = "get"
  config.url = `${baseUrl}/cms/order/shipping-area/list?SelectedBranch=${branchId}${page}${search}${filters}`
  
  const res = await axios( config );
  return res.data;

};

export const GetShippingOptionList = async ( page, search ) => {
  
  
  config.method = "get"
  config.url = `${baseUrl}/cms/order/courier/list?page=${page}&search=${search}`
    
  const res = await axios( config );
  return res.data;

};

export const GetPaymentMethodList = async ( page, search, searchValue ) => {
  
  const searchValues = _.isEmpty(searchValue) ? {
    IsActive: "",
  } : {
    ...searchValue
  }
  const { IsActive } = searchValues
  const filters = `&IsActive=${IsActive}`

  config.method = "get"
  config.url = `${baseUrl}/cms/order/payment-method/list?BranchId=${branchId}&page=${page}&search=${search}${filters}`

  const res = await axios( config );
  return res.data;

};

export const GetPaymentMethodDetail = async ( id ) => {
  
  
  config.method = "get"
  config.url = `${baseUrl}/cms/order/payment-method/${id} `
    
  const res = await axios( config );
  return res.data;

};

export const CheckCoupon = async ( couponCode ) => {
  
  const config = {
    method: "get",
    url: `${baseUrl}/web/get-coupon-list-code/${couponCode}/${branchId}`,
    headers: {
      "Content-Type": "application/json",
    },
  };
  const res = await axios( config );
  return res.data;

};

/**
 * A function for requesting platform list of selected branch
 * @param {boolean=} status      The status of the platform list to be taken (default: null)
 * @param {string=}  searchName  The given search name to query search by name in platform list (default: null)
 * @param {number=}  page        The page of what set of data to look into
 * @param {number=}  limit       The amount of data to be sent per page
 * @returns {Promise<Array>}     Returns the data provided from retrieving the list of platforms
 */
export const GetPlatformList = async ( status = null, searchName = null, page = 1, limit = 10 ) => {

  // If status is indicated then status should equal to `&status=false` or `&status=true` 
  if (status) status = `&status=${status}` 
  else status = ""

  // Same concept as status however for searchName
  if (searchName) searchName = `&searchName=${searchName}`
  else searchName = ""
  
  config.method = 'get'
  config.url =`${baseUrl}/cmsplatforms/getPlatform?branchId=${branchId}${status}${searchName}&page=${page}&limit=${limit}&SelectedBranch=${branchId}`
    
  try {
    
    const res = await axios(config)
    const branchArr = res.data.data.paginatedResults
    return branchArr.filter(x => x.BranchId === branchId)

  } catch (e) {

    if (e.response) {
      console.log(e.response.data)
      console.error(`${e.response.status} - ${e.response.data.mess}`)
    } else {
      console.error(e)
    }

  }

}

/**
 * Function that retrieves Product Platform List
 * @param {number=} search   Name of the product to search for.
 * @param {number=} page     The page of what set of data to look into
 * @param {number=} limit    The amount of data to be sent per page
  * @return {Promise<Array>} Returns the product platform list data from page and limit
 */
export const GetProductPlatformList = async ( search = null, page = 1, limit = 10 ) => {
  
  search = search ? `&search=${search}` : `&search=`
  page = `&page=${page}`
  limit = `&limit=${limit}`

  config.method = 'get'
  config.url = `${baseUrl}/cmsplatforms/getproductlist?branchId=${branchId}${search}${page}${limit}`

  const res = await axios( config )
  return res.data.data.paginatedResults

}

/**
 * Function to call order list depending on platform ID with optional date range filter
 * @param {string} id        The Platform Id of to Filter Out
 * @param {string} from      YYYY-MM-DD format for from value
 * @param {string} to        YYYY-MM-DD format for to value
 * @returns {Promise<Array>} Returns an array with all the information of each order
 */
export const GetOrdersByPlatformId = async ( id, from = null, to = null ) => {

  from = from ? `&DateOrderFrom=${from}` : ""
  to = to ? `&DateOrderTo=${to}` : ""
  const platformId = `&platformId=${id}`
  
  config.method = 'get'
  config.url = `${baseUrl}/cms/order/fetch/order-list?BranchId=${branchId}&SelectedBranch=${branchId}${from}${to}${platformId}`

  try {

    const res = await axios( config )
    return {
      id: id,
      orders: res.data.data.paginatedResults
    }
  
  } catch ( err ) {
    
    console.warn( err.response )
    return err.response ? err.response : err

  }

}


// ======================== POST Requests ======================== //

/**
 * Function for retrieving the coupon discount with the orders
 * @param {string} code   The coupon code to be used
 * @param {Array} orders  The orders that would be associated with the discount
 * @param {number} total  The total amount of all the orders provided
 * @returns {Promise<{}>} Returns the output for retreiving the coupon discount details 
 */
export const CouponDiscount = async ( code, orders, total ) => {

  const data = {

    BranchId: branchId,
    CouponCode: code, 
    CustomerId: "",
    OrderDetails: orders,
    TotalPrice: total 
  }

  config.method = 'post'
  // config.url = `${baseUrl}/cms/order/coupon-discount?SelectedBranch=${branchId}`
  config.url = `${baseUrl}/cms/order/cmsDiscount?SelectedBranch=${branchId}`
  config.data = data

  try {
    
    const res = await axios( config )
    return res.data

  } catch (err) {

    return {
      data: null,
      res: err.response ? err.response : err
    }

  }

}

/**
 * Function to provide the cart products for creating an order listing
 * @param {Array} orders  An array of product id's in the cart
 * @returns {Promise<{}>} Returns the needed data to complete order listing
 */
export const CartProducts = async ( orders ) => {
  
  const data = {
    BranchId: branchId,
    CartProducts: orders
  }

  try {

    config.method = 'post'
    config.url = `${baseUrl}/cms/order/getCartProducts`
    config.data = data
    const res = await axios( config )
    return res.data

  } catch ( err ) {

    return err
    
  }
}

export const SubmitShippingArea = async ( data ) => {
  
  config.method = "post"
  config.url = `${baseUrl}/cms/order/shipping-area/add` 
  config.data = data

  const res = await axios( config );
  return res.data;

};

export const SubmitNewOrder = async ( data ) => {

  config.method = "post"
  config.url = `${baseUrl}/cms/order/create?SelectedBranch=${branchId}`
  // config.url = `https://1899-165-22-102-82.ap.ngrok.io/cms/order/create?SelectedBranch=${branchId}`
  config.data = data


  try {

    const res = await axios( config );
    return res.data;

  } catch (err) {
    console.warn("errorresponse: ", err.response)
    return err.response; 
  }

};

export const SubmitShippingOption = async ( data ) => {
  
  config.method = "post"
  config.url = `${baseUrl}/cms/order/courier/add`
  config.data = data

  const res = await axios( config );
  return res.data;

};

export const SubmitPaymentOption = async ( data ) => {
  
  config.method = "post"
  config.url = `${baseUrl}/cms/order/payment-method/add`
  config.data = data

  const res = await axios( config );
  return res.data;

};

export const SubmitPlatform = async ( data ) => {
  
  config.method = "post"
  config.url = `${baseUrl}/cmsplatforms/create?SelectedBranch=${branchId}`
  config.data = data

  const res = await axios( config );
  return res.data;

};

// ======================== DELETE Requests ======================== //

export const DeleteShippingArea = async ( id ) => {
  
  config.method = "delete"
  config.url = `${baseUrl}/cms/order/payment-method/${id}`

  const res = await axios( config );
  return res.data;

};

export const DeleteBank = async ( id, parentId ) => {
  
  const data = { "BankId": id }
   
  config.method = "delete"
  config.url = `${baseUrl}/cms/order/payment-method/${parentId}/bank`
  config.data = data

  const res = await axios( config );
  return res.data;

};

// ======================== PATCH Requests ======================== //

export const UpdateShippingArea = async ( id, data ) => {
  
  config.method = "patch"
  config.url = `${baseUrl}/cms/order/shipping-area/update/${id}`
  config.data = data

  const res = await axios( config );
  return res.data;

};

export const UpdatePaymentMethodStatus = async ( id, data ) => {
  
  config.method = "patch"
  config.url = `${baseUrl}/cms/order/payment-method/${id}/bank-status`
  config.data = data

  const res = await axios( config );
  return res.data;

};

export const UpdateShippingOption = async ( id, data ) => {
  
  config.method = "patch"
  config.url = `${baseUrl}/cms/order/courier/update/${id}`
  config.data = data

  const res = await axios( config );
  return res.data;

};

// ======================== PUT Requests ======================== //

export const UpdatePaymentMethod = async ( id, data ) => {
  
  config.method = "put"
  config.url = `${baseUrl}/cms/order/payment-method/${id}`
  config.data = data

  const res = await axios( config );
  return res.data;

};

export const UpdateOrder = async ( orderId, data ) => {
  
  config.method = "put"
  config.url = `${baseUrl}/cms/order/${orderId}/update`
  config.data = data

  const res = await axios( config );
  return res.data;

};

/**
 * Function for updating the platform
 * @param {string} id         ID of the platform to be changed
 * @param {string} name       Desired name for change
 * @param {string} status     Desired status for change
 * @param {number} percentage Desired percentage for change
 * @return {Object}           Returns an object data for status of the update
 */
export const UpdatePlatform = async ( id, name, status, percentage ) => {
  
  config.method = 'put'
  config.url = `${baseUrl}/cmsplatforms/updateplatform/${id}?SelectedBranch=${branchId}`
  config.data = {
    "PlatformName": name,
    "Status": status,
    "PricePercentage": percentage
  }

  try {

    const res = await axios( config )
    return res

  } catch ( e ) {

    if ( e.response ) {

      console.error( `${e.response.status} - ${e.response.data.mess}` )
      console.log( e.response.data )

    } else {

      console.error( e )
      
    }
  }
}

//// this field is for export helper only

export const ExportOrderNormalList = async ( dateFrom, dateTo, completedFrom, completedTo ) => {
  
  config.method = "get"
  config.url = `${baseUrl}/cms/order/export/normal-list?SelectedBranch=${branchId}&DateOrderFrom=${dateFrom}&DateOrderTo=${dateTo}&DateCompletedFrom=${completedFrom}&DateCompletedTo=${completedTo}`
  config.responseType = 'arraybuffer'

  const res = await axios( config );
  return res.data;

};


export const ExportOrderHourlySale = async ( dateFrom, dateTo ) => {
  
  config.method = "get"
  config.url = `${baseUrl}/cms/order/export/hour-sale?SelectedBranch=${branchId}&DateCompletedFrom=${dateFrom}&DateCompletedTo=${dateTo}&BranchId=${branchId}`
  config.responseType = 'arraybuffer'

  const res = await axios(config);
  return res.data;

};

export const ExportSoldItems = async ( dateFrom, dateTo ) => {
  
  config.method = "get"
  config.url = `${baseUrl}/cms/order/export/sold-items?SelectedBranch=${branchId}&DateCompletedFrom=${dateFrom}&DateCompletedTo=${dateTo}&BranchId=${branchId}`
  config.responseType = 'arraybuffer'

  const res = await axios( config );
  return res.data;

};

export const getListOrderDineInAndKTV = async ( page, limit, search ) => {

  const language = localStorage.getItem( "language" );
  
  config.method =  "get"
  config.url = `${baseUrl}/cms/order/fetch/order-list-dine-in`
  config.params = {
    page,
    limit,
    search,
    BranchId: branchId
  }

  const isInvalid = ( res ) => {
    
    if ( res.status === "fail" && res.hasOwnProperty( "mess" ) ) {

      const { mess: messEN, messCn, messVn, messTh } = res
      let mess = messEN
      switch ( language ) {
        
        case "VN":
          mess = messVn;
          break
        
        case "TH":
          mess = messTh;
          break
        
        case "CN":
          mess = messCn;
          break
        
        default:
          break
        
      }

      throw new Error( mess || "Something went wrong!" )

    }

    return res

  }

  //handle request internal server error
  const isInternalServerError = ( res ) => {

    if ( res.status === 500 ) {
      throw new Error( "Something went wrong!" )
    }

  }

  const handleError = async ( error ) => {
    
    const response = error.response
    const data = response.data
    isInvalid( data )
    isInternalServerError( data )
    return Promise.reject( error )
    
  }

  const axiosApi = axios.create( config )
  axiosApi.interceptors.response.use(
      response => {
        return response
      },
      handleError
  );

  const res = await axiosApi.get(config.url);
  return res.data;

}

export const getShipingFee = async ( selectedBranch, area, SendOption, totalamount ) => {
  
  config.method = "post"
  config.url = `${baseUrl}/cms/order/shipping-area/shipping-fee`
  config.data = {
    BranchId: selectedBranch,
    Area: area,
    Service: SendOption,
    OrderAmount: totalamount,
    bypassMinOrderAmount: 1
  }

  const res = await axios(config);
  return res.data

}

export const GetLalamoveOrderDetails = async ( id ) => {
    
  config.method = "get"
  config.url = `${baseUrl}/cmslalamove/getorderdriver/${id}`

  const res = await axios( config );
  return res.data;

};

export const GetCouriers = async ( id ) => {
    
  config.method = "get"
  config.url = `${baseUrl}/cms/order/courier/getcourierstatus`

  const res = await axios( config );
  return res.data; 
};

export const SubmitShipCode = async ( orderId, data ) => {
  
  config.method = "put"
  config.url = `${baseUrl}/cmslalamove/updatecouriercode/${orderId}`
  config.data = data

  const res = await axios( config );
  return res.data;

}; 

export const ReCreateQuotation = async ( data ) => {
  
  config.method = "post"
  config.url = `${baseUrl}/cmslalamove/recreatequotation`
  config.data = data

  const res = await axios( config );
  return res.data;

}; 

export const ApplyShipOrder = async ( data ) => {
  
  config.method = "post"
  config.url = `${baseUrl}/cmslalamove/applyshiplalamove`
  config.data = data

  const res = await axios( config );
  return res.data;

}; 

export const GetQuotation = async ( id ) => {
    
  config.method = "get"
  config.url = `${baseUrl}/cmslalamove/getquotation/${id}`

  const res = await axios( config );
  return res.data; 
};

// export const GetCMSLalamoveShippingAddressFee = async (data) => {
//   let token = localStorage.getItem("publicToken"); 
//   let config = {
//     method: "post",
//     url: `${baseUrl}/cms/order/getshippingfee?SelectedBranch=${branchId}`,
//     headers: {
//       "Content-Type": "application/json",
//       "X-Auth-Token": token
//     },
//     data: data
//   };
//   let res = await axios(config);
//   return res.data;
// };


export const GetCMSLalamoveShippingAddressFee = async ( data ) => {
  
  config.method = 'post'
  config.url = `${baseUrl}/cms/order/getshippingfee?SelectedBranch=${branchId}`
  config.data = data

  const res = await axios( config )
    return res.data 
}

export const GetShippingTransactionList = async ( page, search, searchValue ) => {

  const searchValues = _.isEmpty(searchValue) ? {
    Status: "", 
    DateOrderFrom: "",
    DateOrderTo: "",
    City: ""
  } : {
    ...searchValue
  }

  const {
    Status, 
    DateOrderFrom, 
    DateOrderTo,
    City
  } = searchValues
  
  const date = ( date ) => {
    return _.isEmpty( date ) || date === undefined || date === null ? "" : moment( date ).tz( "Asia/manila" ).format( "YYYY-MM-DD" )
  }

  const filters = `&start_time=${date( DateOrderFrom )}&end_time=${date( DateOrderTo )}&city=${City}&order_display_id=${search}&order_status=${Status}&page_no=${page}`

  config.method = "get"
  // config.url = `${baseUrl}/cmslalamove/getquotation/${id}`
  // config.url = `${baseUrl}/cmslalamove/lalamovePortalTransaction?SelectedBranch=${branchId}&page=${page}&search=${search}${filters}`
  config.url = `${baseUrl}/cmslalamove/lalamovePortalTransaction?SelectedBranch=${branchId}${filters}`

  const res = await axios( config );
  // console.log("Lalamove List:", res.data)
  return res.data; 
};

export const ExportShippingTransactionList = async ( page, search, searchValue ) => {

  const searchValues = _.isEmpty(searchValue) ? {
    Status: "", 
    DateOrderFrom: "",
    DateOrderTo: "",
    City: ""
  } : {
    ...searchValue
  }

  const {
    Status, 
    DateOrderFrom, 
    DateOrderTo,
    City
  } = searchValues

  let searchCheck = search ? search : ""
  
  const date = ( date ) => {
    return _.isEmpty( date ) || date === undefined || date === null ? "" : moment( date ).tz( "Asia/manila" ).format( "YYYY-MM-DD" )
  }

  const filters = `&start_time=${date( DateOrderFrom )}&end_time=${date( DateOrderTo )}&city=${City}&order_display_id=${searchCheck}&order_status=${Status}&page_no=${page}`

  // config.method = "get" 
  // config.url = `${baseUrl}/cmslalamove/exportLalamoveTransaction?SelectedBranch=${branchId}${filters}`
  // config.responseType = 'arraybuffer'
 
  const res = await axios( {
    method: "get",
    url: `${baseUrl}/cmslalamove/exportLalamoveTransaction?SelectedBranch=${branchId}${filters}`,
    responseType: 'arraybuffer',
    headers: {
      'X-Auth-Token': token,
      'Content-Type': 'blob',
      'cache-control': 'no-cache'
    }
  } );
  return res.data; 
};
 
export const GetWalletBalance = async () => { 
  const res = await axios( {
    method: "get",
    url: `${baseUrl}/cmslalamove/getLalamoveWalletBalance?SelectedBranch=${branchId}`, 
    headers: {
      'X-Auth-Token': token,
      'Content-Type': 'application/json',
      'cache-control': 'no-cache'
    }
  } );
  return res.data; 
};

export const VietQRCode = async (config) => {
  const res = await get(`${baseUrl}/cms/order/generateQRCode`, config)
  return res.data
}

export const GcashQRCode = async (config) => {
  const res = await get(`${baseUrl}/cms/order/checkSum`, config)
  return res.data
}

export const ConvertTOVND = async (config) => {
  const res = await get(`${baseUrl}/cms/paymentRate/getPaymentRate`, config)
  return res.data
}

export const getQRCodeByStatus = async (config) => {
  const res = await get(`${baseUrl}/cms/order/payment-method/getQRCode`, config)
  return res.data
}

export const GetVoucher = async (data) => {  
  let config = {
    method: "post",
    url: `${baseUrl}/web/fetch/coupon-discount`,
    headers: {
      "Content-Type": "application/json"
    },
    data: data
  };
  let res = await axios(config);
  return res.data;
};