import _ from 'lodash'; 
import {useEffect, useState} from 'react';
import css from "./ShippingOption.module.scss" 
import { GetCouriers, SubmitShipCode } from '../../EcommerceHelper'
import Swal from 'sweetalert2';
import { shipCodeDet } from '../OrderUtils';

const ShippingCouriers = ({ closeModalEdit, setOrder, order,  setCurrentPlaceOrderId, currentPlaceOrderId }) => {
  
  const [shippingOption, setShippingOption] = useState([])
  const [shipCode, setShipCode] = useState(shipCodeDet)

  useEffect(() => { 
      getShippingCourier() 
  }, [])
  
  const getShippingCourier = () => {
    GetCouriers().then(res => {   
      setShippingOption(res?.data)
    }).catch(err => {
      setShippingOption([])
      console.log(err.response);
    })
  }
 
  const submitForm = (e) => {
    e.preventDefault()
    
    Swal.fire({
      title: "In progress!",
      text: "Please wait while doing your request",
      allowOutsideClick: false,
      footer: "&#169; An Nam 2021", 
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: 'Save',
      denyButtonText: `Don't save`,
    }).then((result) => {
      if (result.isConfirmed) {  
        let couriercode = {
          "courierCode"  : shipCode.Code
        }
        
        SubmitShipCode(order._id, couriercode)
          .then(res => {   
            setOrder({
              ...order,
              Courier: {
                Code: shipCode.Code,
                LalamoveDetails: {
                  PlaceOrderId: res.data.Courier.LalamoveDetails.PlaceOrderId,
                  QuotationId: order.Courier.LalamoveDetails.QuotationId,
                  Status: order.Courier.LalamoveDetails.Status,
                  Stops: order.Courier.LalamoveDetails.Stops
                },
                PickupAddress:  order.Courier.PickupAddress,
                TrackingId: order.Courier.TrackingId
              }
            })

            setCurrentPlaceOrderId({
              ...currentPlaceOrderId,
              PlaceOrderID: res.data.Courier.LalamoveDetails.PlaceOrderId
            })

            Swal.fire('Saved!', '', 'success')
            closeModalEdit()

            console.log(couriercode)
          })
          .catch(err => {
            Swal.close()
            if (err.response) {
              if (_.isEmpty(err.response.data.data)) {
                Swal.fire({
                  icon: 'error',
                  title: err.response.data.mess,
                  text: "Status : " + err.response.status,
                  footer: "&#169; An Nam 2021",
                  didOpen: () => {
                    Swal.hideLoading();
                  }
                })
              }
            }
          })        
      } else if (result.isDenied) {
        Swal.fire('Changes are not saved', '', 'info')
      }
    }) 
  }
 
  return (
    <div className='order-container-right-container-content'>
      <h3>Order: Shipping Option</h3>
      <span>Do you want to change the shipping option? Please select one from the list below:</span>  
      <form onSubmit={(e) => { submitForm(e) }}>
        {
          _.map(shippingOption, (value, indx) => {
            //  console.log("value: ", value)
            return (
              <button 
                className={css.buttoncourier} 
                key={indx}
                value={value.ShipiCode}
                onClick={() => { 
                  setShipCode({
                    ...shipCode,
                    Code: value.ShipiCode
                  })
                }}
              ><span >{value.ShipiCode}</span></button>
            )
          })
        }
      </form> 
    </div>
  )
}

export default ShippingCouriers