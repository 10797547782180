import EditIcon from '@mui/icons-material/Edit';
import { Tooltip } from '@mui/material';
import _ from 'lodash';
import React, { useEffect, useRef } from "react";
import ScrollContainer from 'react-indiana-drag-scroll';
const ProductTable = ({ product, changeStatus }) => {
  const container = useRef(null);
  useEffect(() => {
    container.current.getElement().id = "producttable";
    return () => {
    }
  }, [])

  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }
  return (
    <ScrollContainer className="scroll-container content-product-table " ref={container}>
      <div className="content-product-table-head">
        <div className="content-product-table-head-item big-item item-name">Product</div>
        <div className="content-product-table-head-item">SKU & Barcode</div>
        <div className="content-product-table-head-item center">Web stock</div>
        <div className="content-product-table-head-item center">Proc. Stock</div>
        <div className="content-product-table-head-item center">
          In Store Stock
        </div>
        <div className="content-product-table-head-item ml-3">Price</div>
        <div className="content-product-table-head-item item-name">Category</div>
        <div className="content-product-table-head-item">Status</div>
        <div className="content-product-table-head-item center">Actions</div>
      </div>
      {!_.isEmpty(product) ? (
        Object.keys(product).map((key, index) => {
          const {
            Product,
            OrderCount,
            Price,
            IsAvailable,
            Stocks,
            InStoreStock,
            _id
          } = product[key];
          const { Barcode, Categories, Images, Name, SKU } = Product;

          return (
            <div className="content-product-table-data" key={index}>
              <div className="content-product-table-data-item big-item item-name">
                <div className="product-name">
                  <img
                    src={Images.DineIn}
                    alt={Name.EN}
                    width="75px"
                    className="product-name-image"
                  />
                  <div className="product-name-title">
                    <div>
                      <span>
                        <b>{Name.EN}</b>
                      </span>
                    </div>
                    <div>
                      <small>{Name.VN}</small>
                    </div>
                    <div>
                      <small>{Name.CN}</small>
                    </div>
                    <div>
                      <small>{Name.TH}</small>
                    </div>
                  </div>
                </div>
              </div>
              <div className="content-product-table-data-item">
                <div className="product-sku">
                  <div className="product-sku-item">
                    <small>SKU:</small>
                    <span>{SKU}</span>
                  </div>
                  <div className="product-sku-item">
                    <small>BARCODE:</small>
                    <span>{Barcode}</span>
                  </div>
                </div>
              </div>
              <div className="content-product-table-data-item center">
                {Stocks}
              </div>
              <div className="content-product-table-data-item center">
                {OrderCount}
              </div>
              <div className="content-product-table-data-item center">
                {InStoreStock}
              </div>
              <div className="content-product-table-data-item ml-3">
                <div className="product-price">
                  <div className="product-price-item">
                    <small>Delivery:</small>
                    <span>₱ {Price.Delivery}</span>
                  </div>
                  <div className="product-price-item">
                    <small>Dine in:</small>
                    <span>₱ {Price.DineIn}</span>
                  </div>
                  <div className="product-price-item">
                    <small>KTV:</small>
                    <span>₱ {Price.KTV}</span>
                  </div>
                  <div className="product-price-item">
                    <small>Regular:</small>
                    <span>₱ {Price.Regular}</span>
                  </div>
                </div>
              </div>
              <div className="content-product-table-data-item item-name">
                {Categories.length > 1 ? (
                  <div className="product-name-title">
                    <div>
                      <span>
                        <b>{Categories[0].Name.EN}</b>
                      </span>
                    </div>
                    <div>
                      <small>{Categories[0].Name.VN}</small>
                    </div>
                    <div>
                      <small>{Categories[0].Name.CN}</small>
                    </div>
                    <div>
                      <small>{Categories[0].Name.TH}</small>
                    </div>
                    <div>
                      <small className="product-name-title-more">
                        + 1 or more Categories
                      </small>
                    </div>
                  </div>
                ) : Categories.length < 1 ? (
                  <div className="product-name-title">
                    <div>
                      <span>
                        -
                      </span>
                    </div>
                    <div>
                      -
                    </div>
                    <div>
                      -
                    </div>
                    <div>
                      -
                    </div>
                  </div>
                ) : (
                  <div className="product-name-title">
                    <div>
                      <span>
                        <b>{Categories[0].Name.EN}</b>
                      </span>
                    </div>
                    <div>
                      <small>{Categories[0].Name.VN}</small>
                    </div>
                    <div>
                      <small>{Categories[0].Name.CN}</small>
                    </div>
                    <div>
                      <small>{Categories[0].Name.TH}</small>
                    </div>
                  </div>
                )}
              </div>
              <div className="content-product-table-data-item">
                <div className="status">
                  <label className="switch">
                    <input
                      type="checkbox"
                      value={IsAvailable}
                      checked={IsAvailable}
                      onChange={changeStatus(key, _id)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
              <div className="content-product-table-data-item center">
                <Tooltip title="Edit This Product" placement="bottom-start" arrow>
                  <button className='edit-btn' onClick={() => {
                    openInNewTab(`/admin/cms/product-list/details/${Product._id}`)
                  }}><EditIcon sx={{
                    fontSize: "20px"
                  }} /></button>
                </Tooltip>
              </div>
            </div>
          );
        })
      ) : (
        <div className="content-product-table-data">
          <div className="content-product-table-data-item big-item"></div>
          <div className="content-product-table-data-item"></div>
          <div className="content-product-table-data-item center"></div>
          <div className="content-product-table-data-item center"></div>
          <div className="content-product-table-data-item center">
            No Data Found
          </div>
          <div className="content-product-table-data-item ml-3"></div>
          <div className="content-product-table-data-item"></div>
          <div className="content-product-table-data-item"></div>
          <div className="content-product-table-data-item"></div>
        </div>
      )}

    </ScrollContainer>
  )
};

export default ProductTable;
