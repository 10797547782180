import _ from "lodash";

let axios = require("axios");

const baseUrl = process.env.REACT_APP_BASE_URL;

export const GetProductList = async (page, search, searchValue) => {
  let searchValues = _.isEmpty(searchValue) ? {
    GroupCategory: "",
    Category: "",
    Subcategory: "",
    type: "",
    stocks: "",
    sort: "Alphabet",
    sortValue: "ASC",
    IfFeatured: "",
    IsAvailable: ""
  } : {
    ...searchValue
  }
  const { GroupCategory, Category, Subcategory, type, stocks, sort, sortValue, IfFeatured, IsAvailable } = searchValues
  const filters = `&GroupCategory=${GroupCategory}&Category=${Category}&Subcategory=${Subcategory}&type=${type}&stocks=${stocks}&IfFeatured=${IfFeatured}&IsAvailable=${IsAvailable}`
  const token = localStorage.getItem("cmsToken");
  const selectedBranch = localStorage.getItem("branchId");
  const config = {
    method: "get",
    url: `${baseUrl}/cms/branch-products/${selectedBranch}/assigned-products?sort=${sort}&sortValue=${sortValue}&page=${page}&search=${search}${filters}`,
    headers: {
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    },
  };

  const res = await axios(config);
  return res.data;

};
export const GetBranchDetails = async (id) => {
  let token = localStorage.getItem("cmsToken");
  let config = {
    method: "get",
    url: `${baseUrl}/cms/branch/${id}?SelectedBranch=${id}`,
    headers: {
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    },
  };
  let res = await axios( config );
  return res.data;
};
export const GetProductDetails = async (id) => {
  let token = localStorage.getItem("cmsToken");
  let config = {
    method: "get",
    url: `${baseUrl}/cms/product/details/${id}`,
    headers: {
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    },
  };
  let res = await axios(config);
  return res.data;
};
export const ChangeProductStatus = async (id, status) => {
  let token = localStorage.getItem("cmsToken");
  var data = { IsAvailable: status };
  let config = {
    method: 'patch',
    url: `${baseUrl}/cms/branch-products/${id}/change-status`,
    headers: {
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    },
    data: data
  };

  let res = await axios(config);
  return res.data;
}


export const ChangeGroupCategory = async (id, data) => {
  let token = localStorage.getItem("cmsToken");

  let config = {
    method: 'patch',
    url: `${baseUrl}/cms/product/group-category/update/${id}`,
    headers: {
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    },
    data: data
  };

  let res = await axios(config);
  return res.data;

}

export const ChangeCategory = async (id, data) => {
  let token = localStorage.getItem("cmsToken");

  let config = {
    method: 'patch',
    url: `${baseUrl}/cms/product/category/update/${id}`,
    headers: {
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    },
    data: data
  };

  let res = await axios(config);
  return res.data;

}

export const ChangeSubCategory = async (id, data) => {
  let token = localStorage.getItem("cmsToken");

  let config = {
    method: 'patch',
    url: `${baseUrl}/cms/product/subcategory/update/${id}`,
    headers: {
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    },
    data: data
  };

  let res = await axios(config);
  return res.data;

}


export const UpdateProductAndAssign = async (data) => {
  let token = localStorage.getItem("cmsToken");
  let config = {
    method: 'patch',
    url: `${baseUrl}/cms/product/update/branch`,
    headers: {
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    },
    data: data
  };
  try {
    let res = await axios(config);
    return res.data;
  } catch (error) { }
}

export const getPriceLogs = async (data) => {
  let token = localStorage.getItem("cmsToken");
  let config = {
    method: 'get',
    url: `${baseUrl}/cms/product/get/priceLogs`,
    headers: {
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    },
    params: data
  };
  try {
    let res = await axios(config);
    return res.data;
  } catch (error) { return error }
}

export const UpdateProductStock = async (id, stockCount, reason) => {
  let token = localStorage.getItem("cmsToken");
  let selectedBranch = localStorage.getItem("branchId");
  let data = {
    "Branch": selectedBranch,
    "Product": id,
    "Stocks": stockCount,
    "Reason": reason
  };
  let config = {
    method: "post",
    url: `${baseUrl}/cms/branch-products/update-stocks`,
    headers: {
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    },
    data: data
  };
  let res = await axios(config);
  return res.data;
};

export const SubmitGroupCategory = async (groupCategory) => {
  let token = localStorage.getItem("cmsToken");
  const { categoryicon, categorydescription, categoryname, isActive } = groupCategory
  var data = {
    "Name": {
      "VN": categoryname.VN,
      "EN": categoryname.EN,
      "CN": categoryname.CN,
      "TH": categoryname.TH
    },
    "Description": {
      "VN": categorydescription.VN,
      "EN": categorydescription.EN,
      "CN": categorydescription.CN,
      "TH": categorydescription.TH
    },
    "IsActive": isActive,
    "Icon": categoryicon
  }

  let config = {
    method: 'post',
    url: `${baseUrl}/cms/product/group-category/add`,
    headers: {
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    },
    data: data
  };
  try {
    let res = await axios(config);
    return res.data;
  } catch (error) { }
}
export const SubmitSubCategory = async (subCategory) => {
  let token = localStorage.getItem("cmsToken");
  const { category, categorydescription, categoryname, isActive } = subCategory
  var data = {
    "Name": {
      "VN": categoryname.VN,
      "EN": categoryname.EN,
      "CN": categoryname.CN,
      "TH": categoryname.TH
    },
    "Description": {
      "VN": categorydescription.VN,
      "EN": categorydescription.EN,
      "CN": categorydescription.CN,
      "TH": categorydescription.TH
    },
    "IsActive": isActive,
    "CategoryId": category
  }

  let config = {
    method: 'post',
    url: `${baseUrl}/cms/product/subcategory/add`,
    headers: {
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    },
    data: data
  };

  let res = await axios(config);
  return res.data;
}
export const SubmitCreateProduct = async (product) => {
  let token = localStorage.getItem("cmsToken");
  let data = product
  let config = {
    method: 'post',
    url: `${baseUrl}/cms/product/add/branch`,
    headers: {
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    },
    data: data
  };

  let res = await axios(config);
  return res.data;
}
export const SubmitCategory = async (category) => {
  let token = localStorage.getItem("cmsToken");
  const { groupCategory, categorydescription, categoryname, isActive } = category
  var data = {
    "Name": {
      "VN": categoryname.VN,
      "EN": categoryname.EN,
      "CN": categoryname.CN,
      "TH": categoryname.TH
    },
    "Description": {
      "VN": categorydescription.VN,
      "EN": categorydescription.EN,
      "CN": categorydescription.CN,
      "TH": categorydescription.TH
    },
    "IsActive": isActive,
    "GroupCategory": groupCategory
  }

  let config = {
    method: 'post',
    url: `${baseUrl}/cms/product/category/add`,
    headers: {
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    },
    data: data
  };

  let res = await axios(config);
  return res.data;
}
export const GetGroupCategoryWithCategory = async () => {
  let token = localStorage.getItem("cmsToken");
  let config = {
    method: 'get',
    url: `${baseUrl}/cms/product/group-category/list/category`,
    headers: {
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    }
  };


  let res = await axios(config);
  return res.data;
}
export const GetCategoryListWithSubcategory = async () => {
  let token = localStorage.getItem("cmsToken");
  let config = {
    method: 'get',
    url: `${baseUrl}/cms/product/category/list/subcategory`,
    headers: {
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    }
  };


  let res = await axios(config);
  return res.data;
}
export const GetGroupCategoryList = async (page, search) => {
  let token = localStorage.getItem("cmsToken");
  let config = {
    method: 'get',
    url: `${baseUrl}/cms/product/group-category/list?page=${page}&search=${search}`,
    headers: {
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    }
  };


  let res = await axios(config);
  return res.data;
}

export const GetGroupCategoryData = async (id) => {
  let token = localStorage.getItem("cmsToken");
  let config = {
    method: 'get',
    url: `${baseUrl}/cms/product/group-category/${id}`,
    headers: {
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    }
  };


  let res = await axios(config);
  return res.data;

}

export const GetCategoryList = async (page, search, searchValue) => {
  let searchValues = _.isEmpty(searchValue) || searchValue === undefined ? {
    GroupCategory: ""
  } : {
    ...searchValue
  }
  const { GroupCategory } = searchValues
  let token = localStorage.getItem("cmsToken");
  let config = {
    method: 'get',
    url: `${baseUrl}/cms/product/category/list?page=${page}&search=${search}&GroupCategory=${GroupCategory}`,
    headers: {
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    }
  };


  let res = await axios(config);
  return res.data;

}

export const GetAllCategoryList = async (page, limit) => {
  
  let token = localStorage.getItem("cmsToken");
  let config = {
    method: 'get',
    url: `${baseUrl}/cms/product/category/list?page=${page}&limit=${limit}`,
    headers: {
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    }
  };


  let res = await axios(config);
  return res.data;

}

export const GetCategoryListByGroup = async (id, page, search) => {
  let token = localStorage.getItem("cmsToken");
  let config = {
    method: 'get',
    url: `${baseUrl}/cms/product/category/list/group-category?page=${page}&search=${search}&GroupCategory=${id}`,
    headers: {
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    }
  };
  let res = await axios(config);
  return res.data;

}

export const GetSubCategoryList = async (page, search, searchValue) => {
  let searchValues = _.isEmpty(searchValue) || searchValue === undefined ? {
    Category: ""
  } : {
    ...searchValue
  }
  const { Category } = searchValues
  let token = localStorage.getItem("cmsToken");
  let config = {
    method: 'get',
    url: `${baseUrl}/cms/product/subcategory/list?page=${page}&search=${search}&Category=${Category}`,
    headers: {
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    }
  };
  let res = await axios(config);
  return res.data;

}

export const GetSubCategoryListByCategory = async (id, page, search) => {
  let token = localStorage.getItem("cmsToken");
  let config = {
    method: 'get',
    url: `${baseUrl}/cms/product/subcategory/list/category?page=${page}&search=${search}&Category=${id}`,
    headers: {
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    }
  };
  let res = await axios(config);
  return res.data;

}


export const DeleteGroupCategory = async (id) => {
  let token = localStorage.getItem("cmsToken");
  let config = {
    method: 'delete',
    url: `${baseUrl}/cms/product/group-category/delete/${id}`,
    headers: {
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    }
  };
  let res = await axios(config);
  return res;

}
export const DeleteCategory = async (id) => {
  let token = localStorage.getItem("cmsToken");
  let config = {
    method: 'delete',
    url: `${baseUrl}/cms/product/category/delete/${id}`,
    headers: {
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    }
  };

  let res = await axios(config);
  return res;

}
export const DeleteSubCategory = async (id) => {
  let token = localStorage.getItem("cmsToken");
  let config = {
    method: 'delete',
    url: `${baseUrl}/cms/product/subcategory/delete/${id}`,
    headers: {
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    }
  };

  let res = await axios(config);
  return res;

}


export const countPage = (total, limit) => {
  let count = total % limit;
  let addend = 0;
  count > 0 ? (addend = 1) : (addend = 0);
  return parseInt(total / limit) + parseInt(addend)
}

export const IdSelector = (selector) => {
  return document.querySelector(`#${selector}`);
};

export const ClassSelector = (selector) => {
  return document.querySelector(`.${selector}`);
};

export const ShowItemFlex = (selector) => {
  return (document.querySelector(`${selector}`).style.display = "flex");
};
export const ShowItem = (selector) => {
  return (document.querySelector(`${selector}`).style.display = "block");
};
export const HideItem = (selector) => {
  return (document.querySelector(`${selector}`).style.display = "none");
};
export const ShowOpacity = (selector) => {
  return (document.querySelector(`${selector}`).style.display = "none");
};

export const openPublicCart = () => {
  const cartElement = ClassSelector("public-cart")
  if(cartElement) {
    return cartElement.classList.add("show")
  }
}

export const closePublicCart = () => {
  const cartElement = ClassSelector("public-cart")
  if(cartElement) {
    return cartElement.classList.remove("show")
  }
}

export const GetBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}











//// this field is for export helper only

export const ExportProductList = async () => {
  let token = localStorage.getItem("cmsToken");
  let selectedBranch = localStorage.getItem("branchId");
  let config = {
    method: "get",
    url: `${baseUrl}/cms/branch-products/${selectedBranch}/assigned-products/export?SelectedBranch=${selectedBranch}`,
    responseType: 'arraybuffer',
    headers: {
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    },
  };

  let res = await axios(config);

  return res.data;

};