import React, {useEffect, useMemo, useState} from 'react';
import { useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router'; 
import { useTitle } from 'react-use';
import _ from 'lodash';
import moment from 'moment-timezone';
import Swal from 'sweetalert2';
import "../listTable.scss"
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Pending from './../../../../assets/myaccount/pending.svg';
import Toship from './../../../../assets/myaccount/toship.svg';
import OrderHistory from './../../../../assets/myaccount/orderhistory.svg';
import Allowance from './../../../../assets/myaccount/allowance.svg';
import Location from './../../../../assets/myaccount/location.svg';
import User from './../../../../assets/myaccount/user.svg';
import PendingContent from './Pending';
import ToShipContent from './ToShip';
import OrderHistoryContent from './OrderHistory';
import MealAllowanceContent from './MealAllowance';
import ShippingAddressContent from './ShippingAddress';
import UserAccountContent from './UserAccount';
 
import {GetCustomerOrderList, GetCustomerOrderListother, newTabURL} from "../../customerHelper";

const CustomerDetails = () => {
    useTitle('An Nam | Customer DEtails');
    const history = useNavigate();
    const tokens = useSelector((state) => state.token);
    const { token } = tokens.token
    const jwt = require("jsonwebtoken");
    let user = jwt.decode(token)
    let { customerid } = useParams();
    const [activeBtn, setActiveBtn] = useState("")
    const [pendingOrderList, setPendingOrderList] = useState([])
    const [toShipOrderList, setToShipOrderList] = useState([])
    const [orderHistoryList, setOrderHistoryList] = useState([]) 

    useEffect(() => { 
        getOrderList("", "", 0, "", "")
        getOrderList("", "", 4, "", "")
        getOtherOrderList("", "", "", "")
        return () => { } 
      }, [])
  
    const getOrderList = (page, limit, status, orderref, datemade) => {
        GetCustomerOrderList(customerid, page, limit, status, orderref, datemade)
          .then(res => {
            const { paginatedResults } = res.data 
            if(status === 0){
                setPendingOrderList(paginatedResults)   
            }else if(status === 4){
                setToShipOrderList(paginatedResults)
            }            
          })
          .catch(err => {
            const { response } = err
            //Swal Message here
            if(status === 0){
                setPendingOrderList([])   
            }else if(status === 4){
                setToShipOrderList([])
            } 
          })
    }

    const getOtherOrderList = (page, limit, orderref, datamade) => {
        GetCustomerOrderListother(customerid, page, limit, orderref, datamade)
        .then(res => {
            const { paginatedResults } = res.data
            setOrderHistoryList(paginatedResults) 
        })
        .catch(err => {
            const { response } = err 
            setOrderHistoryList([]) 
        })
    }

    const gotoHomepage = (e) => {
        e.preventDefault()
        history("/admin/cms/customer-list")
    }
    
      const changeActive = React.useCallback(
        (url) => {
          return activeBtn === url ? 'customer-container-left-content-buttons-button active' : 'customer-container-left-content-buttons-button'
        },
        [activeBtn],
      )
    return (
        <main className="content-main">
            <section className="content-main-breadcrumbs">
                customer management / customer  details
                <div className="homepage-button-customer">
                    <button onClick={(e) => { gotoHomepage(e)}}>
                        <ArrowBackIosIcon sx={{ fontSize: "18px" }} /> go back to list
                    </button>
                </div>
            </section> 

            <div className='customer-container'>
                <div className='customer-container-left'>
                    <div className='customer-container-left-content'>
                        <div className='customer-container-left-content-title'>TRACK ORDER</div>
                        <div className='customer-container-left-content-buttons'>
                            <button className={changeActive("Pending")} onClick={(e) => {
                                e.preventDefault() 
                                setActiveBtn("Pending")
                                }}><img src={Pending} width="16" height="16" alt="icon" />Pending
                            </button>
                            <button className={changeActive("To-Ship")}
                                onClick={(e) => {
                                    e.preventDefault() 
                                    setActiveBtn("To-Ship")
                                }}
                                ><img src={Toship} width="16" height="16" alt="icon" />To Ship
                            </button>
                            <button className={changeActive("Order-History")}
                                onClick={(e) => {
                                    e.preventDefault() 
                                    setActiveBtn("Order-History")
                                }}
                                ><img src={OrderHistory} width="16" height="16" alt="icon" />Order History
                            </button>
                        </div>
                    </div>
                    <div className='customer-container-left-content'>
                        <div className='customer-container-left-content-title'>STATISTICS</div>
                        <div className='customer-container-left-content-buttons'>
                            <button className={changeActive("Meal-Allowance")} onClick={(e) => {
                                e.preventDefault() 
                                setActiveBtn("Meal-Allowance")
                                }}><img src={Allowance} width="16" height="16" alt="icon" />Meal Allowance
                            </button>
                        </div>
                    </div>
                    {/* <div className='customer-container-left-content'> 
                        <div className='customer-container-left-content-title'>USER INFORMATION</div>
                        <div className='customer-container-left-content-buttons'>
                            <button className={changeActive("Shipping-Address")} onClick={(e) => {
                                e.preventDefault() 
                                setActiveBtn("Shipping-Address")
                                }}><img src={Location} width="16" height="16" alt="icon" />Shipping Address
                            </button>
                            <button className={changeActive("User-Account")} onClick={(e) => {
                                e.preventDefault() 
                                setActiveBtn("User-Account")
                                }}><img src={User} width="16" height="16" alt="icon" />User Account
                            </button>
                        </div>
                    </div> */}
                </div>   
                <div className='customer-container-right'>
                    {(activeBtn === "Pending" || activeBtn === "") ? <PendingContent {...{ Pending, pendingOrderList, moment, newTabURL, getOrderList }}/> 
                    : (activeBtn === "To-Ship") ? <ToShipContent {...{ Toship, toShipOrderList, moment, newTabURL, getOrderList }}/> 
                    : (activeBtn === "Order-History") ? <OrderHistoryContent {...{ OrderHistory, orderHistoryList, moment, newTabURL, getOtherOrderList }}/> 
                    : (activeBtn === "Meal-Allowance") ? <MealAllowanceContent {...{ customerid, Allowance, orderHistoryList, moment, newTabURL, getOtherOrderList, Swal }}/> 
                    // : (activeBtn === "Shipping-Address") ? <ShippingAddressContent />
                    // : (activeBtn === "User-Account") ? <UserAccountContent />
                    :
                    <></>}                    
                </div> 
            </div>
        </main>
    );
};

export default CustomerDetails