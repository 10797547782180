import FileDownloadIcon from "@mui/icons-material/FileDownload"; 
import React from "react"; 
import Swal from "sweetalert2";
import { HideItem, IdSelector, ShowItem } from "../../ProductManager/productHelper";
import { ExportShippingTransactionList } from "../EcommerceHelper";
import moment from "moment";

const ShippingTransactionExport = ({ searchValues }) => { 
  const { search } = searchValues
  const showTextExport = () => {
    IdSelector("exportproduct").style.animation =
      "titleAnimeIn 1s ease-in forwards";
    ShowItem("#exportproduct");
  };
  const hideTextExport = () => {
    IdSelector("exportproduct").style.animation =
      "titleAnimeOut 0.5s ease-in forwards";
    setTimeout(() => {
      HideItem("#exportproduct");
    }, 500);
  };
  // console.log("searchValuesxx: ", searchValues)
  return (
    <div className="export-product">
      <div id="exportproduct">Export</div>
      <div
        className="icon"
        onMouseEnter={showTextExport}
        onMouseLeave={hideTextExport}
        onClick={(e) => {

          e.preventDefault()
          Swal.fire({
            title: "In progress!",
            text: "Please wait while fetching data",
            allowOutsideClick: false,
            footer: "&#169; An Nam 2021",
            didOpen: () => {
              Swal.showLoading();
            },
          });
          ExportShippingTransactionList(1, search, searchValues).then(
            res => {
              let FileSaver = require('file-saver');
              let blob = new Blob([res], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
              // let branchName = _.find(user.Branches, (value, key) => {
              //   return value.BranchId === branchId
              // })?.Name ?? ""
              let CurrentDate = moment( new Date() ).tz( "Asia/manila" ).format( "YYYY-MM-DD HH-mm-ss" )
              FileSaver.saveAs(blob, `Annam-Lalamove Transactions (${CurrentDate})`);
              Swal.close();
            }
          )
            .catch(
              err => {
                Swal.close();
                Swal.fire({
                  icon: "error",
                  title: "Failed!",
                  text: "An error occured while doing your request",
                  footer: "An Nam 2021",
                });
              }
            )
        }}
      >
        <FileDownloadIcon sx={{ fontSize: "18px" }} />
      </div>
    </div>
  );
};
export default ShippingTransactionExport;
