import React, {useEffect, useRef, useState} from "react";
import {postFormData} from "../../../../helpers/axiosHelper";
import css from "./Confirmpayment.module.scss"
import Swal from "sweetalert2";
// import {getCMSUser} from "../../../../helpers/Authentication";
// import {useSelector} from "react-redux";

const ConfirmPayment = (props) => {
    // eslint-disable-next-line
    const PaidConfirmImages = props.order?.PaidConfirmImages ?? []
    const Paid = props.order?.Paid ?? false
    const PaymentReceipt = props.order?.PaymentReceipt ?? {}
    const imageReceipt = PaymentReceipt?.Receipt ?? ""
    const USER_CONFIRM_IMG_INDEX = -1

    const inputFile = useRef(null)
    const [showBtnUploadConfirmPayment, setShowBtnUploadConfirmPayment] = useState(false)
    const reqPaidConfirmImages = useRef([])
    // const token = useSelector(state => state.token)
    //
    // const user = useMemo(() => {
    //     return getCMSUser()
    // }, [token])

    // const userCanEditConfirmImage = useMemo(() => {
    //     const listRoleCanBeEdit = ["Admin", "CS"]
    //
    //     return listRoleCanBeEdit.includes(user.Role ?? "")
    // }, [user])

    useEffect(() => {
        reqPaidConfirmImages.current = [...PaidConfirmImages]

    }, [PaidConfirmImages])

    const inputUpload = () => {
        if(!Paid) {
            return (<div className="input-confirm-image-container">
                <input ref={inputFile} type="file" multiple={true} name="input-confirm-image" accept="image/*" onChange={(e) => {
                    setShowBtnUploadConfirmPayment(e.target.files.length > 0)
                }}/>
                <button className={showBtnUploadConfirmPayment ? "show": ""} disabled={!showBtnUploadConfirmPayment} onClick={handleSubmit}>Upload</button>
            </div>)
        }
    }

    const handleSubmit = async () => {
        if(inputFile.current?.files) {
            const formData = new FormData()

            if(inputFile.current?.files) {
                for (let i = 0; i < inputFile.current?.files.length; i++) {
                    const file = inputFile.current?.files[i]
                    formData.append("files", file)
                }
            }

            formData.append("orderId", props.order._id)
            reqPaidConfirmImages.current.forEach(item => {
                formData.append("PaidConfirmImages[]", JSON.stringify(item))
            })

            const SUBMIT_URL = "/cms/order/update/confirm-payment-image"
            const headers = {
                "Content-Type": "multipart/form-data"
            }
            try {
                Swal.showLoading()
                await postFormData(SUBMIT_URL, formData, headers)
                await props.getOrderDetail(props.order._id)
                inputFile.current.value = ""
                Swal.hideLoading()
            }
            catch (e) {
                Swal.close();
                Swal.fire({
                    icon: 'error',
                    title: "Failed!",
                    text: "An error occured, please try again later",
                    footer: "&#169; An Nam 2021"
                })
                console.log(e.response);
            }
        }
    }

    const markImageIsDeleted = (deleteOnIndex, isDelete = true) => {
        reqPaidConfirmImages.current = reqPaidConfirmImages.current.map((item, index) => {
            if (deleteOnIndex === index) {
                return {
                    ...item,
                    markDeleted: isDelete
                }
            }
            return item
        })
    }

    const confirmDeleteImage =  (index, currentPaidConfirmImages) => {
         Swal.fire({
            width: "70%",
            icon: "question",
            title: "This image will be delete.",
            text: "Are you sure?",
            showCancelButton: true,
            cancelButtonColor: '#b0b0b0',
            cancelButtonText: "Cancel",
            confirmButtonColor: '#fa2727',
            confirmButtonText: 'Yes',
        })
            .then(res => {
                if(res.isConfirmed) {
                    const formData = new FormData()
                    formData.append("orderId", props.order._id)

                    console.log(currentPaidConfirmImages)

                    currentPaidConfirmImages.forEach(item => {
                        formData.append("PaidConfirmImages[]", JSON.stringify(item))
                    })

                    const SUBMIT_URL = "/cms/order/update/confirm-payment-image"
                    const headers = {
                        "Content-Type": "multipart/form-data"
                    }

                    try {
                        Swal.showLoading()

                        postFormData(SUBMIT_URL, formData, headers)
                        .then(() => {
                            Swal.hideLoading()

                            props.getOrderDetail(props.order._id)
                        })
                    }
                    catch (e) {
                        Swal.close();
                        Swal.fire({
                            icon: 'error',
                            title: "Failed!",
                            text: "An error occured, please try again later",
                            footer: "&#169; An Nam 2021"
                        })
                        console.log(e.response);
                    }
                }
                else if(res.isDenied || res.isDismissed) {
                    markImageIsDeleted(index, false)
                }
            })
    }

    const handleClickToImage = (index, src) => {
        Swal.fire({
            width: "70%",
            showCancelButton: true,
            showConfirmButton: !Paid && index !== USER_CONFIRM_IMG_INDEX,
            cancelButtonColor: '#b0b0b0',
            cancelButtonText: "Close",
            confirmButtonColor: '#fa2727',
            confirmButtonText: 'Delete',
            imageUrl: src,
            customClass: {
                image: css.modalImage
            }
        })
            .then(async res => {
                if(res.isConfirmed) {
                    markImageIsDeleted(index)
                    await confirmDeleteImage(index, reqPaidConfirmImages.current)
                }
            })
    }

    const listPaidConfirmImage = () => {
        if(PaidConfirmImages || imageReceipt) {
            return (<div className={css.listConfirmImage}>
                {imageReceipt ? <div className={css.imgWrapper} key={`confirm_payment_img_${USER_CONFIRM_IMG_INDEX}`}>
                    <img src={`${process.env.REACT_APP_DO_ANNAM_SPACE}${imageReceipt}`} className={css.confirmIMG} alt="" onClick={() => handleClickToImage(USER_CONFIRM_IMG_INDEX, `${process.env.REACT_APP_DO_ANNAM_SPACE}${imageReceipt}`)} />
                </div> : null }

                {PaidConfirmImages.map((image, index) => {
                    if(image?.deleted === 0) {
                        const src = `${process.env.REACT_APP_DO_ANNAM_SPACE}${image.url}`
                        return (
                            <div className={css.imgWrapper} key={`confirm_payment_img${index}`}>
                                <img src={src} className={css.confirmIMG} alt="" onClick={() => handleClickToImage(index, src)} />
                            </div>
                        )
                    }

                    return null
                })}
            </div>)
        }

        return null
    }

    return <>
        {inputUpload()}
        {listPaidConfirmImage()}
    </>
}

ConfirmPayment.propTypes = {
}

export default ConfirmPayment