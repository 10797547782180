import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Pagination } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useTitle } from 'react-use';
import Swal from 'sweetalert2';
import { countPage, HideItem, IdSelector, ShowItemFlex } from '../../ProductManager/productHelper';
import { GetShippingAreaList, UpdateShippingArea } from "../EcommerceHelper";
import SearchFilterShippingArea from './Filter/SearchFilterShippingArea';
import ShippingAreaForm from './ShippingAreaForm';
import ShippingAreaTable from './ShippingAreaTable';
import ShippingAreaUpdate from './ShippingAreaUpdate';
const ShippingAreaList = () => {
  useTitle("An Nam | Shipping Area")
  const node = useRef(null);
  const node1 = useRef(null);
  const [shippingAreaList, setShippingAreaList] = useState([])
  const [page, setPage] = useState(1)
  const [pageCount, setPageCount] = useState(1)


  useEffect(() => {
    getAreaList(page, "", {})
    const handleClick = (e) => {
      if (node.current.contains(e.target)) {
        return;
      }
      if (node1.current.contains(e.target)) {
        return;
      }
      HideItem("#addShippingArea")
      HideItem("#updateShippingArea")
    };
    HideItem("#addShippingArea")
    HideItem("#updateShippingArea")
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };

  }, [page])
  const getAreaList = (page, search, searchValue) => {
    setShippingAreaList([])
    setPageCount(0)
    Swal.fire({
      title: "In progress!",
      text: "Please wait while fetching data",
      allowOutsideClick: false,
      footer: "&#169; An Nam 2021",
      didOpen: () => {
        Swal.showLoading();
      }
    });
    GetShippingAreaList(page, search, searchValue)
      .then(res => {
        Swal.close()
        const { paginatedResults, metadata } = res?.data;
        const { total, limit } = metadata;
        setPageCount(countPage(total, limit))
        setShippingAreaList(paginatedResults)
      })
      .catch(

        err => {
          setPageCount(0)
          Swal.fire({
            icon: "error",
            title: "Oops!",
            text: "No Shipping area(s) found!",
            footer: "&#169; An Nam 2021",
            didOpen: () => {
              Swal.hideLoading();
            }
          });
          console.log(err.response)
        }
      )
  }
  const handlePage = (e, p) => {
    e.preventDefault()
    setPage(p)
  }
  const tableScrollLeft = () => {
    if (scrollHandle === 0) {
      scrollHandle = setInterval(function () {
        IdSelector("shippingTable").style.scrollBehavior = "smooth";
        IdSelector("shippingTable").scrollLeft -= 75;
      }, 100);
    }
  };
  let scrollHandle = 0;
  const tableScrollRight = () => {
    if (scrollHandle === 0) {
      scrollHandle = setInterval(function () {
        IdSelector("shippingTable").style.scrollBehavior = "smooth";

        IdSelector("shippingTable").scrollLeft += 75;
      }, 100);
    }
  };
  const stopScrolling = () => {
    clearInterval(scrollHandle);
    scrollHandle = 0;
  };
  const changeStatus = (i, id) => {
    Swal.fire({
      title: "In progress!",
      text: "Please wait while doing your request",
      allowOutsideClick: false,
      footer: "&#169; An Nam 2021",
      didOpen: () => {
        Swal.showLoading();
      }
    });
    setShippingAreaList(
      shippingAreaList.map((shippingAreaLists) =>
        shippingAreaLists._id === id
          ? { ...shippingAreaLists, IsActive: Boolean(!shippingAreaLists.IsActive) }
          : { ...shippingAreaLists }
      )
    )
    let newStatus = {
      ...shippingAreaList[i],
      IsActive: Boolean(!shippingAreaList[i].IsActive)
    }

    UpdateShippingArea(id, newStatus).then(res => {
      Swal.close()
      console.log(res)
    }).catch(err => {
      Swal.fire({
        icon: "error",
        title: "Failed",
        text: "There was a problem occurred while doing your request! 😔",
        footer: "&#169; An Nam 2021",
        didOpen: () => {
          Swal.hideLoading();
        }
      })
      console.log(err.response)
    }
    )
  }
  const [areaToUpdate, setAreaToUpdate] = useState({
    _id: "",
    Area: "",
    BranchId: "",
    MinOrderAmount: 0,
    TotalOrderAmount: 0,
    ShippingFee: 0,
    IsActive: Boolean(true),
    AreaNote: {
      EN: "",
      VN: "",
      TH: "",
      CN: ""
    }
  })
  const showUpdateForm = (key) => {
    setAreaToUpdate(shippingAreaList[key])
    ShowItemFlex("#updateShippingArea");
  }
  const clearFilter = (e) => {
    e.preventDefault()
    getAreaList(1, "", {})
  }

  const productFilter = (e, searchValue) => {
    e.preventDefault()
    const { search } = searchValue
    getAreaList(1, search, searchValue)
  }
  return (
    <main className="content-product">
      <section className="content-product-breadcrumbs">
        e-commerce / shipping areas and conditions
      </section>
      <SearchFilterShippingArea {...{ clearFilter, productFilter }} />
      <ShippingAreaTable {...{ shippingAreaList, changeStatus, showUpdateForm }} />

      {
        pageCount === 0 ? <></> :
          <Pagination count={pageCount} onChange={handlePage} page={page} shape="rounded" />
      }


      <div className="table-slider-left" onMouseEnter={tableScrollLeft} onMouseLeave={stopScrolling}>
        <div className="table-slider-left-button">
          <ChevronLeftIcon />
        </div>
      </div>
      <div
        className="table-slider-right"
        onMouseEnter={tableScrollRight}
        onMouseLeave={stopScrolling}
      >
        <div className="table-slider-right-button">
          <ChevronRightIcon />
        </div>
      </div>
      <ShippingAreaForm {...{ getAreaList, node1 }} />
      <ShippingAreaUpdate {...{ getAreaList, areaToUpdate, node }} />
      <div className='btn-open-modal'>
        <button onClick={(e) => {
          ShowItemFlex("#addShippingArea")
        }}><AddLocationAltIcon sx={{ fontSize: "16px" }} /></button>
      </div>
    </main>
  )
}

export default ShippingAreaList