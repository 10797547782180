import _ from 'lodash';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { ExportSalesReportByType } from '../analyticsHelper';

const SalesModalContent = ({ dateFrom, dateTo }) => {
  const jwt = require("jsonwebtoken");
  const tokens = useSelector((state) => state.token);
  const { branchId, token } = tokens.token;
  const user = jwt.decode(token);

  const submitExportReport = (e, type) => {
    e.preventDefault()
    ExportSalesReportByType(dateFrom, dateTo, type)
      .then(
        res => successExport(res, `${type} Sales Report`)
      )
      .catch(
        err => errorModal(err)
      )
  }

  const successExport = (res, type) => {
    let FileSaver = require('file-saver');
    let blob = new Blob([res], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
    let branchName = _.find(user.Branches, (value, key) => {
      return value.BranchId === branchId
    })?.Name ?? ""
    FileSaver.saveAs(blob, `Annam (${branchName}) ${type} List`);
    Swal.close();
  }
  const errorModal = err => {
    Swal.close();
    const { status } = err?.response
    status === 404 ? Swal.fire({
      icon: "error",
      title: "Failed!",
      text: "No data found!",
      footer: "An Nam 2021",
      didOpen: () => {
        Swal.hideLoading();
      },
    }) : Swal.fire({
      icon: "error",
      title: "Failed!",
      text: "An error occured while doing your request",
      footer: "An Nam 2021",
      didOpen: () => {
        Swal.hideLoading();
      },
    });
    console.log(err.response);
  }
  return (
    <>
      <h3 className="margin-bottom-10">Export Sales Report</h3>
      <h4 className="margin-bottom-30 text-grey">
        Please choose what export type you need:
      </h4>
      <div className="flex-row-center-center width-100 gap-10">
        <button className='btn-submit' onClick={(e) => { submitExportReport(e, "NormalList") }}>Normal List</button>
        <button className='btn-cancel' onClick={(e) => { submitExportReport(e, "PaymentMethod") }}>Payment Method</button>
      </div>
    </>
  );
};
SalesModalContent.defaultProps = {
  dateFrom: moment(new Date()).tz("Asia/Manila").format("YYYY-MM-DD"),
  dateTo: moment(new Date()).tz("Asia/Manila").format("YYYY-MM-DD")
}
export default SalesModalContent;
