
import { Pagination } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTitle } from "react-use";
import Swal from "sweetalert2";
import AddGroupCategory from './ProductGroupCategory/AddGroupCategory';
import AddGroupCategoryForm from "./ProductGroupCategory/AddGroupCategoryForm";
import AddGroupCategoryFormEdit from "./ProductGroupCategory/AddGroupCategoryFormEdit";
import SearchFilterGroupCategory from "./ProductGroupCategory/Filter/SearchFilterGroupCategory";
import GroupCategoryTable from './ProductGroupCategory/GroupCategoryTable';
import { ChangeGroupCategory, countPage, DeleteGroupCategory, GetGroupCategoryList, HideItem, ShowItemFlex, SubmitGroupCategory } from "./productHelper";
import Slider from "./Slider";

const ProductGroupCategories = () => {
  useTitle("An Nam | Group Category")
  const [groupCategorylists, setGroupCategorylist] = useState([]);
  const [page, setPage] = useState(1)
  const [pageCount, setPageCount] = useState(1)

  useEffect(() => {
    getGroupCategory(1, "")

    return () => {
    };
  }, [page]);
  const handlePage = (e, p) => {
    e.preventDefault()
    setPage(p)
  }
  const getGroupCategory = (page, search) => {
    Swal.fire({
      title: "In progress!",
      text: "Please wait while fetching data",
      allowOutsideClick: false,
      footer: "&#169; An Nam 2021",
      didOpen: () => {
        Swal.showLoading();
      }
    });
    setGroupCategorylist([])
    setPageCount(0)
    GetGroupCategoryList(page, search).then((res) => {

      const { paginatedResults, metadata } = res?.data;
      console.log(paginatedResults)
      const { total, limit } = metadata;
      setPageCount(countPage(total, limit))
      let toPush = []
      Object.keys(paginatedResults).forEach(key => {
        const { CreatedAt, Description, Name, Icon, IsActive, _id } = paginatedResults[key];
        toPush.push({
          id: _id,
          icon: Icon,
          isActive: IsActive,
          categoryName: {
            EN: Name.EN,
            VN: Name.VN,
            CN: Name.CN,
            TH: Name.TH
          },
          categoryDescription: {
            EN: Description.EN,
            VN: Description.EN,
            CN: Description.EN,
            TH: Description.EN
          },
          createdAt: CreatedAt
        })
      })
      setGroupCategorylist(toPush)
      Swal.close()
    }).catch(function (error) {
      setPageCount(0)
      Swal.fire({
        icon: "error",
        title: "Oops!",
        text: "No Categories(s) found!",
        footer: "&#169; An Nam 2021",
        didOpen: () => {
          Swal.hideLoading();
        }
      });
    });
  }
  const deleteGroupCategory = (id, e) => {
    e.preventDefault();
    DeleteGroupCategory(id).then(
      (res) => {
        console.log(res?.data?.mess);
        getGroupCategory(1, "")
      }
    )
  }
  const submitGroupCategoryForm = (groupCategory, e) => {
    e.preventDefault();
    HideItem("#groupAddForm")
    Swal.fire({
      title: "In progress!",
      text: "Please wait while processing your request",
      allowOutsideClick: false,
      footer: "&#169; An Nam 2021",
      didOpen: () => {
        Swal.showLoading();
      }
    });
    SubmitGroupCategory(groupCategory).then(res => {
      getGroupCategory(1, "")
      Swal.fire({
        toast: true,
        icon: 'success',
        title: `Successfull added group category`,
        animation: false,
        position: 'top-right',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
          Swal.hideLoading();
        }
      })
    }).catch(
      () => {
        Swal.fire({
          icon: "error",
          title: "Failed",
          text: "There was a problem occurred while doing your request! 😔",
          footer: "&#169; An Nam 2021",
          didOpen: () => {
            Swal.hideLoading();
          }
        })
      }
    )

  }
  const [groupValue, setGroupValue] = useState({
    id: "",
    Name: {
      VN: "",
      EN: "",
      TH: "",
      CN: "",
    },
    Description: {
      VN: "",
      EN: "",
      TH: "",
      CN: "",
    },
    IsActive: true
  })
  const submitGroupCategoryFormEdit = (e, gcValue) => {
    e.preventDefault()
    const { id, Name, Description, IsActive } = gcValue
    let data = {
      Name,
      Description,
      IsActive
    }
    Swal.fire({
      title: "In progress!",
      text: "Please wait while processing your request",
      allowOutsideClick: false,
      footer: "&#169; An Nam 2021",
      didOpen: () => {
        Swal.showLoading();
      }
    });
    HideItem("#groupEdit")
    ChangeGroupCategory(id, data)
      .then(
        res => {
          getGroupCategory(page, "")
          Swal.fire({
            toast: true,
            icon: 'success',
            title: `${id} Updated`,
            animation: false,
            position: 'top-right',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer)
              toast.addEventListener('mouseleave', Swal.resumeTimer)
              Swal.hideLoading();
            }
          })
        }
      )
      .catch(
        err => {
          Swal.fire({
            icon: "error",
            title: "Failed",
            text: "There was a problem occurred while doing your request! 😔",
            footer: "&#169; An Nam 2021",
            didOpen: () => {
              Swal.hideLoading();
            }
          })
        }
      )
  }
  const showEditGroup = (e, gcValue) => {
    e.preventDefault()
    const { categoryDescription, categoryName, isActive, id } = gcValue
    setGroupValue({
      id: id,
      Name: categoryName,
      Description: categoryDescription,
      IsActive: isActive
    });
    ShowItemFlex("#groupEdit")
  }
  const clearFilter = (e) => {
    e.preventDefault()
    getGroupCategory(1, "")
  }
  const productFilter = (e, searchValue) => {
    e.preventDefault()
    const { search } = searchValue
    getGroupCategory(1, search)
  }
  return (
    <main className="content-product">
      <section className="content-product-breadcrumbs">
        product management / product group categories
      </section>
      <SearchFilterGroupCategory {...{ productFilter, clearFilter }} />
      <GroupCategoryTable {...{ deleteGroupCategory, groupCategorylists, showEditGroup }} />
      {pageCount <= 0 ? <></> :
        <Pagination count={pageCount} onChange={handlePage} page={page} shape="rounded" />
      }

      <Slider tableId={"groupcategoryTable"} />
      <AddGroupCategory />
      <AddGroupCategoryForm {...{ submitGroupCategoryForm }} />
      <AddGroupCategoryFormEdit {...{ submitGroupCategoryFormEdit, groupValue }} />
    </main>
  );
};

export default ProductGroupCategories;

