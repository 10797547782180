// Core 
import React, { useEffect, useState } from "react"
import { useFormik } from "formik";
import * as Yup from "yup";
import "yup-phone";

// import CreditScoreIcon from '@mui/icons-material/CreditScore';
import { FormFeedback } from "reactstrap"
import './styles.scss'
import Filter from './Filter';
import moment from 'moment';
import _ from 'lodash';
import { CartProducts, CouponDiscount, GetProductPlatformList, GetShippingAreaList, SubmitNewOrder, GetCMSLalamoveShippingAddressFee } from '../EcommerceHelper';
import { countPage, GetBranchDetails } from '../../ProductManager/productHelper';
import ShoppingCart from '@mui/icons-material/ShoppingCart';
import { IshippeeGet } from '../../../components/public/PublicHelper';

// Styles and Icons
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'; 

import Swal from "sweetalert2";
import AsyncSelect from "react-select/async"; 

const NewOrder = () => {

  // ====================== Variables ======================
  // -- Cart
  //   -- Cart without the percentages of platforms applied
  const [rawCart, setRawCart] = useState( [] )
  //   -- Cart with all the percentages applied (varies per platform)
  const [cart, setCart] = useState( [] )

  const [subtotal, setSubtotal] = useState( 0 )
  const [discount, setDiscount] = useState( 0 )
  const [shippingFee, setShippingFee] = useState( 0 )
  const [total, setTotal] = useState( 0 )

  //   -- Note: Coupon vadility is set to null to not show the status not unless clicked
  const [couponIsValid, setCouponIsValid] = useState( null )
  const [couponAmount, setCouponAmount] = useState( 0 )
  const [isNext, setIsNext] = useState( false )
  
  //   -- Delivery Info
  const [voucher, setVoucher] = useState("")
  const [areas, setAreas] = useState( [] )
  // const [areaSuggestions, setAreaSuggestions] = useState([])
  const [areaSuggestions, setAreaSuggestions] = useState("")

  const [deliveryInfo, setDeliveryInfo] = useState( { 
    // date: "",
    // time: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    deliveryMethod: "",
    area: "",
    address: {
      name: "",
      info: ""
    },
    notes: "",
    paymentMethod: "",
    typeAddress: "",
    ShippingFee: 0,
    LalamoveDetails: {
      QuotationId: "",
      Stops: []
    },
    PreferredDeliveryDate: ""
  });

  // -- Filter
  const [searchItem, setSearchItem] = useState( "" )
  //   -- Note: searchPlatform takes the percentage of a certain platform
  const [searchPlatform, setSearchPlatform] = useState( 0 )
  const [platformId, setPlatformId] = useState( "" )

  // -- Product List
  const [rawProductList, setRawProductList] = useState( [] )
  const [renderProductList, setRenderProductList] = useState( [] )
  const [pagination, setPagination] = useState( 1 )  
  // -- Error Message 
  const [otherErrorMessage, setOtherErrorMessage] = useState("")

  const swalSuccess = data => {
    Swal.fire({
      title: "Success",
      text: data?.mess,
      icon: "success",
      confirmButtonText: "Okay",
    });
  };

  const swalError = data => {
    console.log("swalError: ", data?.data[0])
    Swal.fire({
      title: "Error",
      text: data?.data[0] || data?.mess,
      icon: "error",
      confirmButtonText: "Okay",
    });
  };
 
  // ====================== Functions ======================
  // -- Preferred Delivery Time Functions and Minimum Date and Time
  const getMinimumDate = () => {
    return moment().tz("Asia/Manila").format("YYYY-MM-DD")
  };
  const [timePicker, setTimePicker] = useState([])
  const createTimePicker = (date) => {
    let newTime = []
    let today = moment(_.isEmpty(date) ? (new Date()) : date).tz("Asia/Manila").format("YYYY-MM-DD")
    let time = moment(today).isAfter(getMinimumDate()) ? 0 : moment().tz("Asia/Manila").add(1, 'hour').format("HH")
    for (let hour = parseInt(time); hour < 24; hour++) {
      for (let minutes = 0; minutes < 60;) {
        let newMinutes = minutes < 10 ? "00" : minutes
        let newHours = hour < 10 ? `0${hour}` : hour
        newTime.push(`${newHours}:${newMinutes}`)
        minutes += 15;
      }
    }
    console.log("loop time: ", newTime[0])
    setTimePicker(newTime)
  }
 
  const getMinimumTime = () => {
    // console.log("Current MinimumTime: ", moment().tz("Asia/Manila").format("HH:mm"))
    // console.log("getMinimumTime: ", moment().tz("Asia/Manila").add(1, 'hour').format("HH:mm"))
    let newTime = []
    let date = new Date()
    let today = moment(_.isEmpty(date) ? (new Date()) : date).tz("Asia/Manila").format("YYYY-MM-DD")
    let time = moment(today).isAfter(getMinimumDate()) ? 0 : moment().tz("Asia/Manila").add(1, 'hour').format("HH")
    for (let hour = parseInt(time); hour < 24; hour++) {
      for (let minutes = 0; minutes < 60;) {
        let newMinutes = minutes < 10 ? "00" : minutes
        let newHours = hour < 10 ? `0${hour}` : hour
        newTime.push(`${newHours}:${newMinutes}`)
        minutes += 15;
      }
    }
    // return moment().tz("Asia/Manila").add(15, "minutes").format("HH:mm")
    return newTime[0]
  };
  // -- Preferred Delivery Date Hooks 
  const [preferredDelDate, setPreferredDelDate] = useState({
    deliveryDate: getMinimumDate(),
    deliveryTime: getMinimumTime()
  });
  const [minOrderDate, setMinOrderDate] = useState();
  // -- Filter
  // const filter = async () => {
    
  //   if ( searchItem ) await getProductPlatformList( searchItem, 1 )
  //   if ( searchPlatform ) {
  //     setCart( rawCart.map( x => {
  //       return {...x, price: x.price + _.round((searchPlatform * x.price), 2)} 
  //     } ) )
  //     // toRenderProductList( searchPlatform )
  //   } 
  // }
  

  // -- Pagination
  const movePagination = async ( isNext = true ) => {

    // Using callback function to immediately execute setter
    if ( isNext ) setPagination( currentPagination => {
      return currentPagination + 1
    })
      
    else setPagination( currentPagination => {
      if ( currentPagination > 1 ) return currentPagination - 1
      else return 0
    })

  }

  // -- [GET] Product List
  const getProductPlatformList = async (filter = undefined, pagination = undefined) => {

    const result = await GetProductPlatformList(filter, pagination)
    if ( result ) setRawProductList( result.map( product => { 
      let CategoryId = ""
      if(product.Product.Categories.length > 0){
        CategoryId = product.Product.Categories[0]._id
      }

      return { 
        productId: product.Product._id,
        price: product.Price.Delivery,
        names: { ...product.Product.Name },
        image: product.Product.Images.Delivery,
        barcode: product.Product.Barcode,
        sku: product.Product.SKU,
        createdAt: product.Product.CreatedAt,
        updatedAt: product.Product.UpdatedAt,
        stocks: product.Stocks,
        discount: {
          from: product.SaleDateFrom,
          to: product.SaleDateTo,
          price: product.SalePrice
        },
        isActive: product.IsAvailable,
        prices: {
          delivery: product.Price.Delivery,
          dineIn: product.Price.DineIn,
          ktv: product.Price.KTV,
          regular: product.Price.Regular
        },
        categoryId: CategoryId
      } 
    })) 
  }


  // -- [GET] Shipping Area
  const getShippingArea = async () => {

    const res = await GetShippingAreaList()
    const { total, limit } = res?.data.metadata
    const pages = countPage( total, limit )
    
    const promises = []
    for ( let i = 0; i < pages; i++ ) {
      promises.push(GetShippingAreaList( i + 1 ));
    }

    const areas = await Promise.all( promises )
    console.log(areas)
    setAreas(areas)
    
  }

  // -- [POST] Check Coupon Validity
  const couponValidity = async ( code ) => {

    const orders = cart.map( (x) => {
      let Price = 0
      if(deliveryInfo.deliveryMethod === 'delivery'){
        Price = (x.qty * x.prices.delivery)
      }else if(deliveryInfo.deliveryMethod === 'pickup'){
        Price = (x.qty * x.prices.regular)
      }else if(deliveryInfo.deliveryMethod === 'ktvs'){
        Price = (x.qty * x.prices.ktv)
      }else if(deliveryInfo.deliveryMethod === 'dinein'){
        Price = (x.qty * x.prices.dineIn)
      }else{
        Price = (x.qty * x.prices.delivery)
      }

      return {
        ProductId: x.id,
        Price: Price,
        CategoryId: x.categoryId
      }
    })
    console.log("Code: ", code)
    console.log("cart: ", cart)
    console.log("Orders: ", orders)
    console.log("total: ", total)
    const res = await CouponDiscount( code, orders, total )

    if ( !res.data ){
      setCouponIsValid( false )
      setCouponAmount( 0 )
    }       
    else {
      
      const couponDiscount = res.data.Payment
      setCouponIsValid( true )

      // Setting the coupon amount as its own entity and not adding it inside setDiscount itself; because when coupon 'check' is spammed then it will recusively apply the coupon which will lower the supposed grand total
      setCouponAmount( couponDiscount )

    }

    if ( !cart ) setCouponIsValid( null )

  }

  // -- Provide Areas Suggestions Auto Populate 
  // const autoPopulateSuggestions = async ( value ) => {

  //   if ( _.isEmpty( value ) ) IshippeeGet( [] )
  //   else {
      
  //     const res = await IshippeeGet( value )
  //     setAreaSuggestions( res.predictions )

  //     // Closest Possible Suggestion
  //     if ( areaSuggestions[0] ) setDeliveryInfo({
        
  //       ...deliveryInfo,
  //       address: {
  //         name: deliveryInfo.typeAddress,
  //         info: res.predictions[0]
  //       } 
  //     })
      
  //     // None
  //     else setDeliveryInfo( { 
  //       ...deliveryInfo,
  //       address: {
  //         name: deliveryInfo.typeAddress,
  //         info: null
  //       }

  //     }) 
  //   } 
  // }
  const loadOptions = async(searchValue, callback) => { 
    const response = await IshippeeGet( searchValue ) 
    let predictions = response.predictions;
    callback(predictions.map(i => ({ label: i.description, value: i.place_id})))
  }

  const getArea = (label, value) => {
    let data = {
      "label": label, 
      "value": value
    }
    // console.log(data)
    setAreaSuggestions(value) 
  }
 
  const handleChange = async (selectedOption) => { 
    Swal.fire({
      title: "In progress!",
      text: "Please wait while fetching Shipping Fee",
      allowOutsideClick: false,
      footer: "&#169; An Nam 2021",
      didOpen: () => {
        Swal.showLoading();
      },
    });
    console.log("deliveryInfo: ", deliveryInfo)
//  preferredDelDate.date
    let time = preferredDelDate.deliveryTime
    let datetime = `${preferredDelDate.deliveryDate} ${time}`; 
    console.log("X preferredDelDate.deliveryDate: ", preferredDelDate.deliveryDate)
    console.log("X preferredDelDate.time: ", preferredDelDate.deliveryTime)
    console.log("-- datetime -- : ", new Date(datetime))
    console.log("-- PreferredDeliveryDate -- : ", deliveryInfo.PreferredDeliveryDate)

    let PrefDeliveryDate = new Date(datetime);
    const branchId = localStorage.getItem( 'branchId' );
    const branch = await GetBranchDetails( branchId )
    console.log("branch: ", branch)
    let InputData = {
      "BRANCHID": branchId,
      "COURIER": "LALAMOVE",
      "pickup_address":{ 
        "address":"174 Yakal", 
        "place_id":"ChIJk6scXQrJlzMRhOaBazQzwzc",
        "place":"Makati, Metro Manila, Philippines"
      },
      "destination_address":{
          "phone": deliveryInfo.phone,
          "address": selectedOption.label,
          "place": selectedOption.label,
          "place_id": selectedOption.value
      }, 
      "PREFERREDDELIVERYDATE" : PrefDeliveryDate
    }

    GetCMSLalamoveShippingAddressFee(InputData)
        .then(res => {
          console.log("OutputData: ", res.data)

          let LalamoveDetails = {
            QuotationId: res.data.LalamoveResult.quotationId,
            Stops: res.data.LalamoveResult.stops
          }

          let ShippingFee = res.data.ShippingFee ? res.data.ShippingFee : 0
          setDeliveryInfo( {
            ...deliveryInfo,
            address: {
              name: selectedOption.label,
              info: selectedOption.value,
            },
            typeAddress: selectedOption.label,
            ShippingFee: ShippingFee,
            LalamoveDetails: LalamoveDetails
          })

          const infotemp = {
            address: {
              name: selectedOption.label,
              info: selectedOption.value,
            },
            typeAddress: selectedOption.label,
            ShippingFee: ShippingFee,
            LalamoveDetails: LalamoveDetails
          }

          console.log("infotemp: ", infotemp)
          getShipingFee(ShippingFee)
          Swal.close();
        }).catch(
          err => {  
            console.log("err: ", err)
            if(err.response.data.data.length > 0){
              if(err.response.data.data[0].id === "ERR_INVALID_FIELD"){
                Swal.fire("Date cannot be a past date or more than 30 days in advance.", '', 'error')
              }else if(err.response.data.data[0].id === "ERR_OUT_OF_SERVICE_AREA"){
                Swal.fire("Given latitude/longitude of address is out of service area.", '', 'error')
              }else{
                Swal.fire(err.response.data.data[0].message, '', 'error')
              } 
            }else{
              Swal.fire(err.response.data.mess, '', 'error')
            } 
            setDeliveryInfo( {
              ...deliveryInfo,
              address: {
                name: "",
                info: "",
              },
              typeAddress: "",
              ShippingFee: 0,
              LalamoveDetails: {
                QuotationId: "",
                Stops: []
              }
            })
            getShipingFee(0)
          }
        )

    
  }

  // -- Add To Cart
  const addToCart = (event, id, names, price, stock, discount, image = null, prices, categoryId) => {
    
    event.preventDefault()
    const item = {
      id,
      names,
      price,
      stock,
      discount,
      image,
      prices,
      categoryId
    }

    const exist = rawCart.find(x => x.id === id)
    if ( exist ) setRawCart( rawCart.map( x => x.id === id ? {...exist, qty: exist.qty + 1 } : x ) )
    else setRawCart( [...rawCart, { ...item, qty: 1 }] )
    
  }

  // -- Subtract Qty / Remove from Cart
  const removeFromCart = ( event, id ) => {
    
    event.preventDefault()
    const item = rawCart.find( x => x.id === id ? x : null )
    if ( item ) { 
      if ( item.qty !== 1 ) setRawCart( rawCart.map( x => x.id === id ? { ...item, qty: item.qty - 1 } : x ) )
      else setRawCart( rawCart.filter( x => x.id !== id ) ) 
    } 
  }

  // -- To Render Product List
  const toRenderProductList = (multiplier = 0, vNext = null) => {
    let CartIcon = !vNext ? false : vNext
    console.log("CartIcon: ", CartIcon)
    setRenderProductList( 
      // ...renderProductList, 
      _.map(rawProductList, (value, indx) => {
        
        if (value.isActive){
          return(
            <section key={indx} id={value.productId} className="list-product-item-container">
              {/* image */}
              <div className="list-product-item list-product-item-name size-lg"> 
                <img src={value.image} alt="No Image" /> 
                <div>
                  <p>{value.names.EN}</p>
                  <p>{value.names.VN}</p>
                  <p>{value.names.CN}</p>
                  <p>{value.names.TH}</p>
                </div> 
              </div>

              {/* sku & barcode */}
              <div className="list-product-item size-md">
                { value.sku ?
                  <p>sku: {value.sku}</p> : <></>}
                { value.barcode ?
                  <p>barcode: {value.barcode}</p> : <></>}
                { !value.barcode && !value.sku ?
                  <p className='negative'> No data </p> : <></>}
              </div>

              {/* stocks */}
              <div className="list-product-item size-sm">
                { value.stocks > 0 ?
                    value.stocks > 999 ? 
                    <p>
                      999+ <br></br>
                      <label className='positive'>In stock</label>
                    </p> :
                    <p>
                      {value.stocks} <br></br>
                      <label className='positive'>In stock</label>
                    </p> : <p className='negative'>out of stock</p>
                }
              </div>

              {/* price */}
              <div className="list-product-item size-md">
                <div>Delivery: <strong>₱</strong>{value.prices.delivery + _.round((multiplier * value.prices.delivery), 2)}</div>
                <div>Dine In: <strong>₱</strong>{value.prices.dineIn + _.round((multiplier * value.prices.dineIn), 2)}</div>
                <div>KTV: <strong>₱</strong>{value.prices.ktv + _.round((multiplier * value.prices.ktv), 2)}</div>
                <div>Regular: <strong>₱</strong>{value.prices.regular + _.round((multiplier * value.prices.regular), 2)}</div>
              </div>

              {/* Shopping Cart */}
              { !CartIcon ?
                value.stocks ? 
                <div className="list-product-item size-sm"> 
                  <ShoppingCart
                    sx={{ color: '#50311b' }}
                    className='cart'
                    onClick={e => addToCart(
                      e,
                      value.productId,
                      value.names,
                      value.price,
                      value.stocks,
                      value.discount,
                      value.image,
                      value.prices,
                      value.categoryId
                    )} 
                  /> 
                </div> :
                <div className="list-product-item size-sm"></div>
                : <div className="list-product-item size-sm"></div>
              }
            </section>
          )
        }       
      })
      // rawProductList.map( product => {
      
      //   if (product.isActive) return <>
      //     <section id={product.productId} className="list-product-item-container">
            
      //       {/* image */}
            // <div
            //   className="list-product-item list-product-item-name size-lg">
        
            //   <img src={product.image} alt="No Image" /> 
            //   <div>
            //     <p>{product.names.EN}</p>
            //     <p>{product.names.VN}</p>
            //     <p>{product.names.CN}</p>
            //     <p>{product.names.TH}</p>
            //   </div>

            // </div>
            
      //       {/* sku & barcode */}
      //       <div className="list-product-item size-md">
      //         { product.sku ?
      //           <p>sku: {product.sku}</p> : <></>}
      //         { product.barcode ?
      //           <p>barcode: {product.barcode}</p> : <></>}
      //         { !product.barcode && !product.sku ?
      //           <p className='negative'> No data </p> : <></>}
      //       </div>

      //       {/* stocks */}
      //       <div className="list-product-item size-sm">
      //         { product.stocks > 0 ?
      //             product.stocks > 999 ? 
      //             <p>
      //               999+
      //               <label className='positive'>In stock</label>
      //             </p> :
      //             <p>
      //               {product.stocks}
      //               <label className='positive'>In stock</label>
      //             </p> : <p className='negative'>out of stock</p>
      //         }
      //       </div>

      //       {/* price */}
      //       <div className="list-product-item size-sm">
      //         <strong>₱</strong>{product.price + _.round((multiplier * product.price), 2)}
      //       </div>

      //       {/* Shopping Cart */}
      //       { product.stocks ? 
      //         <div className="list-product-item size-sm">
      //           <ShoppingCart
      //             sx={{ color: '#50311b' }}
      //             className='cart'
      //             onClick={e => addToCart(
      //               e,
      //               product.productId,
      //               product.names,
      //               product.price,
      //               product.stocks,
      //               product.discount,
      //               product.image
      //             )} 
      //           />
      //         </div> :
      //         <div className="list-product-item size-sm"></div>
      //       }
      //     </section>
      //   </>
      // })
    )

  }

  // -- Get Subtotal
  const getSubtotal = (dm = null) => {
    console.log("selected delivery:", dm)
    console.log("cart_cart:", cart)
    
    let total = 0
    
    cart.forEach( x => {
      
      let price = x.price
      if(dm == 'delivery'){
      //   console.log("getSubtotal_cart_delivery_price:", x.prices.delivery)
        price = x.prices.delivery
      }else if(dm == 'dinein'){
      //   console.log("getSubtotal_cart_dineIn_price:", x.prices.dineIn)
        price = x.prices.dineIn
      }else if(dm == 'ktvs'){
      //   console.log("getSubtotal_cart_ktv_price:", x.prices.ktv)
        price = x.prices.ktv
      }else if(dm == 'pickup'){
      //   console.log("getSubtotal_cart_regular_price:", x.prices.regular)
        price = x.prices.regular
      }else{
        price = 0
      }
      console.log("NewPrice: ", price)
      total = total + (price * x.qty)
      console.log("NewTotal: ", total)
    })

    console.log("Subtotal: ", subtotal)

    // console.log("NewTotal: ", total)

    setSubtotal( _.round( total, 2 ) )
    setTotal((_.round( total, 2 ) + shippingFee) - discount)

  }

  // -- Check for Discount
  const getDiscount = () => {

    let discount = 0
    cart.forEach( x => {
      
      if ( x.discount.from && x.discount.to ) {
        
        const isBetween = moment().tz( 'Asia/Manila' ).isBetween( x.discount.from, x.discount.to )
        if ( isBetween ) discount += x.discount.price

      }
      
    })
    
    setDiscount( discount )
    setTotal( ( subtotal + shippingFee ) - discount )
    
  }

  // -- Check for Shipping Fee
  const getShipingFee = (ShippingFee) => {
    console.log("ShippingFeeArea: ", ShippingFee)
    // const area = deliveryInfo.area ? JSON.parse( deliveryInfo.area ) : undefined
    // if ( area ) {
    //   setShippingFee( area.ShippingFee )
    //   setTotal((subtotal + area.ShippingFee) - discount)
    // }
    // else setShippingFee( 0 )

    const shippingfees = (ShippingFee > 0) ? ShippingFee : 0
    if ( shippingfees > 0 ) {
      setShippingFee( shippingfees )
      setTotal((subtotal + shippingfees) - discount)
    }
    else{
      setShippingFee( 0 )
      setTotal((subtotal + shippingfees) - discount)
    }
    
  } 

  // ====================== useEffects ======================
  useEffect(() => {
    createTimePicker()
    // getProductPlatformList()
    getShippingArea()
    setMinOrderDate(getMinimumDate());
  }, [] )
  
  // -- Run useEffect once there is any changes in pagination
  useEffect(() => {
    
    if ( searchItem ) getProductPlatformList( searchItem, pagination )
    else getProductPlatformList( undefined, pagination)
  
  }, [pagination])
  
  // -- Run useEffect once any changes in rawProductList and cart
  useEffect(() => {
    
    if ( searchPlatform ) toRenderProductList( searchPlatform )
    else toRenderProductList()
    
  }, [rawProductList, rawCart] )
  
  // -- Run useEffect once any changes in the rawCart
  useEffect(() => {
    
    setCart( rawCart.map( x => {
      // return {...x, price: x.price + _.round((searchPlatform * x.price), 2)} 
      return {
        ...x, 
        price: x.price + _.round((searchPlatform * x.price), 2),
        prices: {
          delivery: x.prices.delivery + _.round((searchPlatform * x.prices.delivery), 2),
          dineIn: x.prices.dineIn + _.round((searchPlatform * x.prices.dineIn), 2),
          ktv: x.prices.ktv + _.round((searchPlatform * x.prices.ktv), 2),
          regular: x.prices.regular + _.round((searchPlatform * x.prices.regular), 2)            
        }
      } 
    }))
    
  }, [rawCart])
  
  // -- Run useEffect once any changes in cart section and cart delivery info area
  useEffect(() => {
    
    // console.log("cart_cart: ", cart)
    // getSubtotal()
    getDiscount()
    // getShipingFee() 
  }, [cart, subtotal, deliveryInfo.area] )

  useEffect(() => { 
    getArea(deliveryInfo.address.name, deliveryInfo.address.info) 
  }, [deliveryInfo] )

  useEffect(() => {
    // console.log("deliveryDatexxx: ",preferredDelDate.deliveryDate)
    // console.log("deliveryTimexxx: ",preferredDelDate.deliveryTime) 
    // setPreferredDelDate({ ...preferredDelDate, date: setDate})
    // setPreferredDelDate({ ...preferredDelDate, time: timePicker[0]})
    setDeliveryInfo({ 
      ...deliveryInfo, 
      PreferredDeliveryDate: moment(preferredDelDate.deliveryDate + " " + timePicker[0]).tz("Asia/Manila").format().toString()
    })
  }, [setDeliveryInfo,preferredDelDate,timePicker] )

  const handleDeliveryInputChange = (e) => {
    const { value, name } = e.target
    setPreferredDelDate({
      ...preferredDelDate,
      [name]: value
    })
    console.log("Handle Name: ",name)
    if (name === "deliveryDate") {
      console.log("Shipping Date Convert: ", value + " -- " + preferredDelDate.deliveryTime)
      setDeliveryInfo({ 
        ...deliveryInfo, 
        PreferredDeliveryDate: moment(value + " " + preferredDelDate.deliveryTime).tz("Asia/Manila").format().toString()
      })
    }
    else if (name === "deliveryTime") {
      console.log("Shipping Time Convert: ", preferredDelDate.deliveryDate + " " + value)
      setDeliveryInfo({ 
        ...deliveryInfo, 
        PreferredDeliveryDate: moment(preferredDelDate.deliveryDate + " " + value).tz("Asia/Manila").format().toString()
      })
    }

    else if (name === "deliveryTime") {

    }
  }

  const handleDeliveryRadioChange = (name, value) => {
    console.log(name, ": ", value)
    setDeliveryInfo({ ...deliveryInfo, deliveryMethod: value})
    getSubtotal(value)
  }

  // ====================== Validate and Submit ======================
  let validationInitValue = {
    deliveryDate: (preferredDelDate && preferredDelDate.deliveryDate) || '',
    deliveryTime: (preferredDelDate && preferredDelDate.deliveryTime) || '',
    firstName: (deliveryInfo && deliveryInfo.firstName) || '',
    lastName: (deliveryInfo && deliveryInfo.lastName) || '',
    email: (deliveryInfo && deliveryInfo.email) || '',
    phone: (deliveryInfo && deliveryInfo.phone) || '',
    deliveryMethod: (deliveryInfo && deliveryInfo.deliveryMethod) || '',
    area: (deliveryInfo && deliveryInfo.area) || '', 
    address: {
      name: (deliveryInfo && deliveryInfo.address.name) || '',
      info: (deliveryInfo && deliveryInfo.address.info) || '',
    }, 
    typeAddress: (deliveryInfo && deliveryInfo.typeAddress) || '',
    notes: (deliveryInfo && deliveryInfo.notes) || '',
    paymentMethod: (deliveryInfo && deliveryInfo.paymentMethod) || '',
  }

  // console.log("typeAddress: ", (deliveryInfo.typeAddress.length))  
  let validationRule = {  
    // date: Yup.string().required("Date is required"),
    // time: Yup.string().required("Time is required"),
    firstName: Yup.string().required("Fist name is required"),
    // lastName: Yup.string().required("Last name is required"),
    email: Yup.string().email("Invalid email address format").required("Email is required"),
    phone: Yup.string().phone('Phone number is not valid').required("Phone number is required"), 
    deliveryMethod: Yup.string().required("Selecting the delivery options is required"),
    area: (deliveryInfo.deliveryMethod.length != 0) ? (deliveryInfo.deliveryMethod == "delivery") ? Yup.string().required("Area options is required") : "" : "",  
    typeAddress: (deliveryInfo.deliveryMethod.length != 0) ? (deliveryInfo.deliveryMethod == "delivery") ? Yup.string().required("Address is required")  : "" : "", 
    paymentMethod: Yup.string().required("Selecting the Payment Method options is required"),
  }

  const SendOrder = async values => {
    // swalSuccess(values);
    const data = await SubmitNewOrder(values) 
    console.log("Status: ", data.status)
    console.log("datas: ", data)
    // if (data.status == "fail") {
    //   swalError(data);
    //   // setTimeout(() => {
    //   //   setIsLoading(false);
    //   // }, 300);
    // } else {
    if (data.status == "success") {
      swalSuccess(data);
      setDeliveryInfo({ 
        ...deliveryInfo, 
        // date: "",
        // time: "",
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        deliveryMethod: "",
        area: "",
        address: {
          name: "",
          info: ""
        },
        notes: "",
        paymentMethod: "",
        typeAddress: "",
        ShippingFee: 0,
        LalamoveDetails: {
          QuotationId: "",
          Stops: []
        },
        PreferredDeliveryDate: ""
      }) 
      setOtherErrorMessage("")
      setIsNext( false )
      getSubtotal()
      setShippingFee( 0 )
      setCart([])
      toRenderProductList( searchPlatform, !isNext )
    }else{
      swalError(data.data);
      setOtherErrorMessage(validatorMessage(data.data))
    }
};

const validatorMessage = data => { 
  let message = "" 
  let dataval = data.data; 
  var dataBullet = Object.entries(dataval).map(([key, value]) => (
    <li key={key}>{key}: {value}</li>
  ));
  message = <ul>{dataBullet}</ul>  
  return message;
};

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: validationInitValue,
    validationSchema: Yup.object(validationRule),
    onSubmit: async (values) => {  
    const language = localStorage.getItem( "language" ) || "EN";
    const branchId = localStorage.getItem( 'branchId' )
    const adminToken = localStorage.getItem( 'cmsToken' )
    const newCart = cart.map( x => x.id )
    let orders = await CartProducts( newCart )
    console.log( cart )
    console.log("platformId: ",  platformId )
    orders = orders.data.map( x => {
      
      const item = cart.find(c => c.id === x.ProductId)

      return {
        ProductId: x.ProductId,
        ProductName: x.Name,
        ProdImage: x.Images.Delivery,
        SKU: x.SKU,
        Price: item.price,
        Quantity: item.qty,
        TotalPrice: item.price * item.qty,
        OrderProdType: x.Type.toUpperCase(),
        PreparationTime: x.PreparationTime
      }

    })
    
    const branch = await GetBranchDetails( branchId )
    
    let area = ""
    let DelDetails = {}
    let DeliveryMethod = ""
    if(values.deliveryMethod.toUpperCase() == "DELIVERY"){
      area = JSON.parse( values.area );
      DelDetails = {
        Area: area.Area,
        Address: values.address.name,
        PlaceId: values.address.info,
        Place: values.address.name
      };
      DeliveryMethod = values.deliveryMethod.toUpperCase()
    }else if(values.deliveryMethod.toUpperCase() == "KTVS"){
      DeliveryMethod = "KTV"
    }else{ 
      DeliveryMethod = values.deliveryMethod.toUpperCase()
      DelDetails = {
        Area: "",
        Address: "",
        PlaceId: null,
        Place: null
      };
    } 

     
    let time = preferredDelDate.deliveryTime
    let datetime = `${preferredDelDate.deliveryDate} ${time}`; 
    let PrefDeliveryDate = new Date(datetime);
      
    console.log("Y preferredDelDate.deliveryTime: ", time)
    console.log("Y datetime: ", datetime)
    console.log("Y-- PreferredDeliveryDate -- : ", PrefDeliveryDate)

    const data = { 
      BranchId: branchId,
      AgentCode: undefined,
      CuponCode: couponIsValid ? voucher : null,
      Courier: { 
        Code: "ANNAM",
        TrackingId: null,
        PickupAddress: {
          Name: "Annam",
          Phone: branch.data.ContactNum[0].Number,
          Address: "174 Yakal",
          Place: "Makati, Metro Manila, Philippines",
          PlaceId: "ChIJk6scXQrJlzMRhOaBazQzwzc"
        },
        LalamoveDetails: deliveryInfo.LalamoveDetails
      },
      CustomerId: null,
      Locale: language,
      OrderDetails: orders,
      OrderNote: values.notes || "",
      PaymentMode: values.paymentMethod.toUpperCase(),
      PreferredDeliveryDate: PrefDeliveryDate,
      SendOption: DeliveryMethod,
      ContactDetails: {
        FullName: `${values.firstName} ${values.lastName}`,
        Email: `${values.email}`,
        MobileNum: `+63${values.phone}`
      },
      DeliveryDetails: DelDetails,
      Status: "0",
      StatusNote: [],
      ShippingAmount: shippingFee,
      Discount: discount + couponAmount,
      OrderAmount: subtotal,
      TotalAmount: total - couponAmount,
      PlatformId: platformId

    }
      
      try {  
        console.log("sending: ", data)
          await SendOrder(data) 
          validation.resetForm(); 
      } catch (error) { 
        return setOtherErrorMessage(error.message || error)             
      } 
    }
  });

  

  return (
    <main className="content-product">
      {/* Breadcrumbs */}
      <section className="content-product-breadcrumbs">
        product management / new order
      </section>

      {/* FILTER */}
      <Filter props={{
        searchItem,
        searchPlatform,
        pagination,
        setSearchItem,
        setSearchPlatform,
        setPlatformId,
        movePagination,
        // filter,
        toRenderProductList,
        getProductPlatformList,
        setCart,
        rawCart, 
        deliveryInfo,
        getSubtotal,
        setIsNext,
        isNext
      }} /> 

      {/* PRODUCT LIST & CART */}
      <section className="list">

        {/* ---------------- LIST - PRODUCT ------------------- */}
        <section className="list-product">
          {/* PRODUCT - HEADER */}
          <section className='list-product-header'>
            <span className="list-product-header-item size-lg">product</span>
            <span className="list-product-header-item size-md">sku & barcode</span>
            <span className="list-product-header-item size-sm">web stock</span>
            <span className="list-product-header-item size-sm">price</span>
            <span className="list-product-header-item size-sm">action</span>
          </section>      
          {/* PRODUCT - BODY */}
          <div className="list-product-container">
            {renderProductList}
          </div> 
        </section>
        
        {/* ----------------- LIST - CART ------------------- */}
        <div className="list-cart-container">
          {/* CART - HEADER */}
          <div className="list-cart-header">
            { isNext ?
            <span className="list-cart-header-item">cart details</span> : 
            <>
            <span className="list-cart-header-item size-md">cart</span>
            <span className="list-cart-header-item size-sm">price</span>
            <span className="list-cart-header-item size-sm">quantity</span>
            <span className="list-cart-header-item size-sm">total</span>
            <span className="list-cart-header-item size-sm">actions</span>
            </>}
          </div>

          {/* ------------ CART - BODY - FORM ----------------*/}
          <form onSubmit={(e) => { e.preventDefault(); validation.handleSubmit();return false;}}>
            <div className="list-cart-body">
              {otherErrorMessage ? (<p style={{color: "red"}}>{otherErrorMessage}</p>) : null}
              { isNext ?
                // DETAILS
                <div className="form">
                  {/* PREFERRED DELIVERY TIME */}
                  <h3>Delivery Time Info</h3>
                  <aside className='column'>
                    <div>
                      <label htmlFor="date">Date *</label>
                      <input
                        type="date"
                        name='deliveryDate' 
                        value={preferredDelDate.deliveryDate}
                        min={minOrderDate}
                        // invalid={validation.errors.date && validation.touched.date ? 1 : 0} 
                        onChange={e => {
                          handleDeliveryInputChange(e)
                        }} 
                      />
                      {/* {validation.errors.date ? (<FormFeedback type="invalid" className="feederror">{validation.errors.date}</FormFeedback>) : null} */}
                    </div>
                    <div>
                      <label htmlFor="time">Time *</label>
                      {/* <input
                        type="time"
                        name="time"
                        value={validation.values.time || ""}
                        invalid={validation.errors.time && validation.touched.time ? 1 : 0}
                        className={validation.errors.time ? "error" : ""}
                        onChange={e => {
                          setDeliveryInfo({ ...deliveryInfo, time: e.target.value})
                        }}  
                      /> */}
                      <select name="deliveryTime" 
                        onChange={e => { 
                          handleDeliveryInputChange(e)
                          setDeliveryInfo({ 
                            ...deliveryInfo, 
                            PreferredDeliveryDate: moment(preferredDelDate.deliveryDate + " " + e.target.value).tz("Asia/Manila").format().toString()
                          })
                        }} 
                        invalid={validation.errors.time && validation.touched.time ? 1 : 0} 
                        >
                        {
                          _.map(timePicker, (value, index) => {
                            return <option key={index} value={value}>{value}</option>
                          })
                        }
                      </select>
                      {/* {validation.errors.time ? (<FormFeedback type="invalid" className="feederror">{validation.errors.time}</FormFeedback>) : null} */}
                    </div>
                  </aside>
                  
                  {/* CONTACT INFO */}
                  <h3>Contact Info</h3>
                  <aside className='column'>
                    <div>
                      <label htmlFor="fname">First Name *</label>
                      <input
                        type="text"
                        name='fname'  
                        value={validation.values.firstName || ""}
                        invalid={validation.errors.firstName && validation.touched.firstName ? 1 : 0}
                        className={validation.errors.firstName ? "error" : ""}
                        onChange={e => {
                          setDeliveryInfo({ ...deliveryInfo, firstName: e.target.value})
                        }} 
                      /> 
                      {validation.errors.firstName ? (<FormFeedback type="invalid" className="feederror">{validation.errors.firstName}</FormFeedback>) : null}
                    </div>
                    <div>
                      <label htmlFor="lname">Last Name</label>
                      <input
                        type="text"
                        name='lname' 
                        value={validation.values.lastName || ""}
                        // invalid={validation.errors.lastName && validation.touched.lastName ? 1 : 0}
                        // className={validation.errors.lastName ? "error" : ""}
                        onChange={e => {
                          setDeliveryInfo({ ...deliveryInfo, lastName: e.target.value})
                        }}
                      />
                      {/* {validation.errors.lastName ? (<FormFeedback type="invalid" className="feederror">{validation.errors.lastName}</FormFeedback>) : null} */}
                    </div>
                  </aside>

                  <aside>
                    <label htmlFor="email">Email Address</label>
                    <input
                      type="email"
                      name='email'
                      value={validation.values.email || ""}
                      invalid={validation.errors.email && validation.touched.email ? 1 : 0}
                      className={validation.errors.email ? "error" : ""}
                      onChange={e => {
                        setDeliveryInfo({ ...deliveryInfo, email: e.target.value})
                      }}
                      placeholder="ex: sample@gmail.com"
                      />
                      {validation.errors.email ? (<FormFeedback type="invalid" className="feederror">{validation.errors.email}</FormFeedback>) : null}
                  </aside>

                  <aside>
                  <label htmlFor="contact" >Contact *:</label>
                    <div className="formrow">
                      <div className="formcolumn1">
                        <span className="mobile-number">+63</span>
                      </div>
                      <div className="formcolumn2">
                        <input
                          type="number"
                          name='contact'
                          maxLength="10"
                          value={validation.values.phone || ""}
                          invalid={validation.errors.phone && validation.touched.phone ? 1 : 0}
                          className={validation.errors.phone ? "error" : ""} 
                          onChange={e => {
                            setDeliveryInfo({ ...deliveryInfo, phone: e.target.value})
                          }}
                          placeholder="9151234567"
                        />
                      </div>
                    </div>
                    {/* <div></div><label htmlFor="contact" >Contact *:</label>
                    <div className="form-colum"> 
                      <span className="mobile-number">+63</span>
                    </div>
                    <div className="form-colum"> 
                      <input
                        type="text"
                        name='contact'
                        maxLength="10"
                        value={validation.values.phone || ""}
                        invalid={validation.errors.phone && validation.touched.phone ? 1 : 0}
                        className={validation.errors.phone ? "error" : "orderinput"} 
                        onChange={e => {
                          setDeliveryInfo({ ...deliveryInfo, phone: e.target.value})
                        }}
                        placeholder="ex: 9151234567"
                      />
                    </div>  
                    {validation.errors.phone ? (<FormFeedback type="invalid" className="feederror">{validation.errors.phone}</FormFeedback>) : null} */}
                  </aside>

                  {/* DELIVERY INFO */}
                  <h3>Delivery Info</h3>
                  <aside>
                    <input type="radio"
                      value="delivery"
                      name='deliveryInfo'
                      invalid={validation.errors.deliveryMethod && validation.touched.deliveryMethod ? 1 : 0}
                      className={validation.errors.deliveryMethod ? "error" : ""} 
                      onChange={e => {
                        // setDeliveryInfo({ ...deliveryInfo, deliveryMethod: e.target.value})
                        handleDeliveryRadioChange(e.target.name, e.target.value)
                      }}
                    />
                    <label htmlFor="deliveryInfo">Delivery</label>
                    <input
                      type="radio"
                      value="pickup"
                      name='deliveryInfo'
                      invalid={validation.errors.deliveryMethod && validation.touched.deliveryMethod ? 1 : 0}
                      className={validation.errors.deliveryMethod ? "error" : ""} 
                      onChange={e => {
                        // setDeliveryInfo({ ...deliveryInfo, deliveryMethod: e.target.value})
                        handleDeliveryRadioChange(e.target.name, e.target.value)
                      }}
                    />
                    <label htmlFor="deliveryInfo">Pickup</label>
                    <input
                      type="radio"
                      value="ktvs"
                      name='deliveryInfo'
                      invalid={validation.errors.deliveryMethod && validation.touched.deliveryMethod ? 1 : 0}
                      className={validation.errors.deliveryMethod ? "error" : ""} 
                      onChange={e => {
                        // setDeliveryInfo({ ...deliveryInfo, deliveryMethod: e.target.value})
                        handleDeliveryRadioChange(e.target.name, e.target.value)
                      }}
                    />
                    <label htmlFor="deliveryInfo">KTV</label>
                    <input
                      type="radio"
                      value="dinein"
                      name='deliveryInfo'
                      invalid={validation.errors.deliveryMethod && validation.touched.deliveryMethod ? 1 : 0}
                      className={validation.errors.deliveryMethod ? "error" : ""} 
                      onChange={e => {
                        // setDeliveryInfo({ ...deliveryInfo, deliveryMethod: e.target.value})
                        handleDeliveryRadioChange(e.target.name, e.target.value)
                      }}
                    />
                    <label htmlFor="deliveryInfo">Dine In</label>
                    {validation.errors.deliveryMethod ? (<FormFeedback type="invalid" className="feederror">{validation.errors.deliveryMethod}</FormFeedback>) : null}
                  </aside>

                  { deliveryInfo.deliveryMethod === 'delivery' ? 
                    <>
                      <aside>
                        <select
                          name="area" 
                          value={validation.values.area || ""}
                          invalid={validation.errors.area && validation.touched.area ? 1 : 0}
                          className={validation.errors.area ? "error" : ""} 
                          onChange={e => {
                            setDeliveryInfo({ ...deliveryInfo, area: e.target.value})
                          }}
                        >
                          <option disabled value="">-- Select Area --</option>
                          {areas.map( a => {
                            return _.map(a.data.paginatedResults, (area, indx) => {
                              return(<option key={indx} id={area.Area} value={JSON.stringify(area)}>{area.Area}</option>)
                            }).sort((a, b) => a.props.id.localeCompare(b.props.id))
                          })}
                        </select>
                        {validation.errors.area ? (<FormFeedback type="invalid" className="feederror">{validation.errors.area}</FormFeedback>) : null}
                      </aside> 
                      <aside>
                        <label htmlFor="address">Address *</label>
                        {/* <input
                        type="text"
                        name='address'    
                        autoComplete='none'
                        list='address'
                        value={validation.values.typeAddress}
                        invalid={validation.errors.typeAddress && validation.touched.typeAddress ? 1 : 0}
                        className={validation.errors.typeAddress ? "error" : ""}
                        onChange={e => {  
                          setTimeout(() => {
                            autoPopulateSuggestions(e.target.value);
                          }, 1000); 
                          setDeliveryInfo( {
                            ...deliveryInfo,
                            address: {
                              name: e.target.value,
                              ...deliveryInfo.address,
                            }, 
                            typeAddress: e.target.value
                          })
                        }} 
                      /> 
                       <datalist id='address'>
                          { areaSuggestions.map( a => {
                            return <option
                              data-value={JSON.stringify(a)}
                              value={a.description}
                            >{a.description}</option>
                          })}
                        </datalist> */}
                      {/* {validation.errors.typeAddress ? (<FormFeedback type="invalid" className="feederror">{validation.errors.typeAddress}</FormFeedback>) : null} */}
                      <AsyncSelect 
                        loadOptions={loadOptions} 
                        onChange={handleChange}
                        invalid={validation.errors.typeAddress && validation.touched.typeAddress ? 1 : 0}
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            // borderColor: state.isFocused ? 'grey' : 'red',
                            border: '1px solid #50311b',
                            height: '30px',
                            width: '100%',
                            padding: '0 10px',
                            fontSize: '12pt'
                          }),
                        }}
                      />
                      {validation.errors.typeAddress ? (<FormFeedback type="invalid" className="feederror">{validation.errors.typeAddress}</FormFeedback>) : null}
                      </aside> 
                    </> :
                    <></>
                  }
                  <aside>
                    <label htmlFor="note">Notes</label>
                    <input 
                      type="text" 
                      name='note' 
                      value={deliveryInfo.notes}
                      onChange={e => {
                        setDeliveryInfo({ ...deliveryInfo, notes: e.target.value})
                      }}
                    />
                  </aside>

                  {/* PAYMENT METHOD */}
                  <h3>Payment Method</h3>
                  <aside>
                    <input 
                      type="radio" 
                      value="cod" 
                      name='paymentMethod'
                      invalid={validation.errors.paymentMethod && validation.touched.paymentMethod ? 1 : 0}
                      className={validation.errors.paymentMethod ? "error" : ""} 
                      onChange={e => {
                        setDeliveryInfo({ ...deliveryInfo, paymentMethod: e.target.value})
                      }}
                    />
                    <label htmlFor="paymentMethod">Cash on Delivery</label>
                    <input 
                      type="radio" 
                      value="bankTransfer" 
                      name='paymentMethod'
                      invalid={validation.errors.paymentMethod && validation.touched.paymentMethod ? 1 : 0}
                      className={validation.errors.paymentMethod ? "error" : ""} 
                      onChange={e => {
                        setDeliveryInfo({ ...deliveryInfo, paymentMethod: e.target.value})
                      }}
                    />
                    <label htmlFor="paymentMethod">Bank Transfer</label>
                    {validation.errors.paymentMethod ? (<FormFeedback type="invalid" className="feederror">{validation.errors.paymentMethod}</FormFeedback>) : null}
                  </aside>

                </div> : 

                // CART
                
                _.map(cart, (x, indx) => {
                  return(
                    <div key={indx} id={x.id} className='list-cart-body-item'>
                      {/* NAMES */}
                      <div className="size-md">
                        <img src={x.image} alt="No Image" />
                        <div className='names'>
                          <p>{x.names.EN ? x.names.EN : ""}</p>
                          <p>{x.names.VN ? x.names.VN : ""}</p>
                          <p>{x.names.CN ? x.names.CN : ""}</p>
                          <p>{x.names.TH ? x.names.CN : ""}</p>
                        </div>
                      </div>

                      {/* PRICE */}
                      <div className="size-sm">
                        {/* <p>{console.log(x.prices)}</p> */}
                        <p><b>Delivery:</b> &nbsp;&nbsp;₱{x.prices.delivery}<br></br>
                           <b>Dine In:</b> &nbsp;&nbsp;₱{x.prices.dineIn}<br></br>
                           <b>KTV:</b> &nbsp;&nbsp;₱{x.prices.ktv}<br></br>
                           <b>Regular:</b> &nbsp;&nbsp;₱{x.prices.regular}</p>
                      </div>

                      {/* QUANTITY */}
                      <div className="size-sm">
                        <p>{x.qty}</p>
                      </div>

                      {/* TOTAL */}
                      <div className="size-sm">
                        {/* <p>{_.round((x.price * x.qty), 2)}</p> */}
                        <p><b>Delivery:</b> &nbsp;&nbsp;₱{_.round((x.prices.delivery * x.qty), 2)}<br></br>
                           <b>Dine In:</b> &nbsp;&nbsp;₱{_.round((x.prices.dineIn * x.qty), 2)}<br></br>
                           <b>KTV:</b> &nbsp;&nbsp;₱{_.round((x.prices.ktv * x.qty), 2)}<br></br>
                           <b>Regular:</b> &nbsp;&nbsp;₱{_.round((x.prices.regular * x.qty), 2)}</p>
                      </div>

                      {/* ACTIONS */}
                      <div className="size-sm">
                        
                        <button onClick={e => addToCart(
                          e,
                          x.id,
                          x.names,
                          x.price,
                          x.stock,
                          x.discount,
                          x.image,
                          x.prices
                        )}> + </button>

                        <button onClick={e => removeFromCart(
                          e,
                          x.id
                        )}> - </button>

                      </div>
                    </div>
                  )
                })



                // cart.map( x => {
                //   return <div id={x.id} className='list-cart-body-item'>

                //     {/* NAMES */}
                //     <div className="size-md">
                //       <img src={x.image} alt="No Image" />
                //       <div className='names'>
                //         <p>{x.names.EN ? x.names.EN : ""}</p>
                //         <p>{x.names.VN ? x.names.VN : ""}</p>
                //         <p>{x.names.CN ? x.names.CN : ""}</p>
                //         <p>{x.names.TH ? x.names.CN : ""}</p>
                //       </div>
                //     </div>

                //     {/* PRICE */}
                //     <div className="size-sm">
                //       <p>{x.price}</p>
                //     </div>

                //     {/* QUANTITY */}
                //     <div className="size-sm">
                //       <p>{x.qty}</p>
                //     </div>

                //     {/* TOTAL */}
                //     <div className="size-sm">
                //       <p>{_.round((x.price * x.qty), 2)}</p>
                //     </div>

                //     {/* ACTIONS */}
                //     <div className="size-sm">
                      
                //       <button onClick={e => addToCart(
                //         e,
                //         x.id,
                //         x.names,
                //         x.price,
                //         x.stock,
                //         x.discount,
                //         x.image
                //       )}> + </button>

                //       <button onClick={e => removeFromCart(
                //         e,
                //         x.id
                //       )}> - </button>

                //     </div>
                //   </div>
                // } )       
              }  
            </div> {/* End of Details Forms - list-cart-body */}

            {/* CART - FOOTER */}
            <div className="list-cart-footer">

              {/* INFO TEXT */}
              <div className="list-cart-footer-text-container"> 
                {/* Subtotal */}
                <p className="list-cart-footer-text">
                  subtotal:
                  <span className='list-cart-footer-text-data'>
                    <strong>₱</strong>{subtotal}
                  </span>
                </p>

                {/* Discount */}
                <p className="list-cart-footer-text">
                  discount:
                  <span className='list-cart-footer-text-data'>
                    <strong>₱</strong>{discount + couponAmount}
                  </span>
                </p>

                {/* Shipping */}
                <p className="list-cart-footer-text">
                  shipping:
                  <span className='list-cart-footer-text-data'>
                    <strong>₱</strong>{shippingFee}
                  </span>
                </p>

                {/* Grand Total */}
                <p className="list-cart-footer-text">
                  grand total:
                  <span className='list-cart-footer-text-data'>
                    <strong>₱</strong>{total - couponAmount}
                  </span>
                </p> 
              </div> {/* End of INFO TEXT - list-cart-footer-text-container */}

              <div className="list-cart-footer-text-container"> 
                
                <div>
                  <label htmlFor="coupon">coupon</label>

                  { couponIsValid === null ? <></>
                    : couponIsValid ?
                    <span className='positive'>success</span> :
                    <span className='negative'>invalid</span>
                  }

                </div>

                <input
                  type="text"
                  name='coupon'
                  onChange={e => setVoucher( e.target.value )} 
                  value={voucher}
                />

                <button 
                  type="button"
                  onClick={e => {                  
                    couponValidity( voucher )
                  }}> Check </button> 
              </div> {/* End list-cart-footer-text-container */}

              {/* NEXT/BACK */}
              <div className="list-cart-footer-button-container">
                { cart.length ?
                  <button
                    type="button"
                    className='list-cart-footer-button'
                    onClick={e => {
                      setIsNext( !isNext )
                      toRenderProductList( searchPlatform, !isNext )
                      getSubtotal()
                    }}
                  >
                    {isNext ?
                      <>
                        <ArrowBackIosIcon className='arrow' />
                        <span>BACK</span>
                      </> :
                      <>
                        <ArrowForwardIosIcon className='arrow' />
                        <span>NEXT</span>
                      </>
                    }
                  </button> :
                  <></>
                }
                { isNext ?
                  <button
                    type="submit"
                    className='list-cart-footer-button'
                  >
                    {/* <CreditScoreIcon className='arrow' /> */}
                    <span>Proceed</span>
                  </button>  :
                  <></>
                }
              </div> {/* End of NEXT/BACK - list-cart-footer-button-container */}

            </div> {/* End of CART - FOOTER - list-cart-footer */}
          </form> 
        </div> 
      </section>  
    </main>
  );  

};

export default NewOrder