import { Pagination } from "@mui/material"
import _ from "lodash"
import moment from "moment-timezone"
import {useEffect, useRef, useState} from "react"
import { useTitle } from "react-use"
import Swal from "sweetalert2"
import { countPage } from "../../ProductManager/productHelper"
import { GetProductsReport, GetProductsReportByCategory } from "../analyticsHelper"
import ExportButton from "../ExportButton"
import ExportModalSales from "../Exports/ExportModalSales"
import AnalyticsTableCategories from "./AnalyticsTableCategories"
import AnalyticsTableProducts from "./AnalyticsTableProducts"
import ProductsExportContent from './ProductsExportContent'
import FilterBar from "./component/Filterbar";

const ProductReport = () => {
  useTitle("An Nam | Products Report")
  const [overView, setOverView] = useState({
    from: "",
    to: ""
  })
  const [exportDates, setExportDates] = useState({
    DateFrom: moment(new Date()).tz("Asia/Manila").format("YYYY-MM-DD"),
    DateTo: moment(new Date()).tz("Asia/Manila").format("YYYY-MM-DD"),
    sortBy: "total",
    sendOptions: []
  })
  const [productsModal, setProductsModal] = useState(false)
  const [productPage, setProductPage] = useState(1)
  const [categoriesPage, setCategoriesPage] = useState(1)

  const [productPageCount, setProductPageCount] = useState(1)
  const [productReport, setProductReport] = useState([])
  // const [productChartControl, setProductChartControl] = useState([])

  const [categoriesPageCount, setCategoriesPageCount] = useState(1)
  const [categoriesReport, setCategoriesReport] = useState([])
  // const [categoriesChartControl, setCategoriesChartControl] = useState([])

  const ITEM_PER_PAGE = 10

  useEffect(() => {

    getProductsReport(1, exportDates)
    getCategoriesReport(1, exportDates)
    return () => {

    }
    //eslint-disable-next-line
  }, [])
  const handlePageProduct = (e, p) => {
    e.preventDefault()
    setProductPage(p)
    getProductsReport(p, exportDates)
  }
  const handlePageCategories = (e, p) => {
    e.preventDefault()
    setCategoriesPage(p)
    getCategoriesReport(p, exportDates)
  }
  const getProductsReport = (page, searchValue) => {
    requestPending()
    setProductReport([])
    setProductPageCount(0)
    GetProductsReport(page, {...searchValue, limit: ITEM_PER_PAGE})
      .then(res => {
        Swal.close()
        const { metadata, paginatedResults } = res?.data
        const { total, limit } = metadata;
        setProductPageCount(countPage(total, limit))
        setProductReport(paginatedResults)
        // setProductChartControl(paginatedResults)
      })
      .catch(
        () => {
          requestError()
          setProductReport([])
        }
      )
  }

  const getCategoriesReport = (page, searchValue) => {
    requestPending()
    setCategoriesReport([])
    setCategoriesPageCount(0)

    GetProductsReportByCategory(page, {...searchValue, limit: ITEM_PER_PAGE})
      .then(res => {
        Swal.close()
        const { metadata, paginatedResults } = res?.data
        const { total, limit } = metadata;
        setCategoriesPageCount(countPage(total, limit))
        setCategoriesReport(paginatedResults)
        // setCategoriesChartControl(paginatedResults)
      })
      .catch(
        () => {
          requestError()
          setCategoriesReport([])
        }
      )
  }

  const requestPending = () => {
    Swal.fire({
      title: "In progress!",
      text: "Please wait while fetching data",
      allowOutsideClick: false,
      footer: "&#169; An Nam 2021",
      didOpen: () => {
        Swal.showLoading();
      }
    });
  }
  const requestError = () => {
    Swal.close()
  }
  const productFilter = (searchValue) => {
    const { DateFrom, DateTo } = searchValue

    if (_.isEmpty(DateFrom) || _.isEmpty(DateTo)) {
      Swal.fire({
        icon: "error",
        title: "Failed",
        text: "Please fill up all required inputs",
        footer: "&#169; An Nam 2021",
        didOpen: () => {
          Swal.hideLoading();
        }
      })
    } else {
      if (moment(DateFrom).tz("Asia/Manila").isAfter(DateTo)) {
        Swal.fire({
          icon: "error",
          title: "Failed",
          text: "Date From field must be earlier than Date to field",
          footer: "&#169; An Nam 2021",
          didOpen: () => {
            Swal.hideLoading();
          }
        })
      } else {
        getProductsReport(1, searchValue)
        getCategoriesReport(1, searchValue)
        setProductPage(1)
        setCategoriesPage(1)

          const filterDate = {
              from: moment(DateFrom || (new Date())).tz("Asia/Manila").format('YYYY-MM-DD'),
              to: moment(DateTo || (new Date())).tz("Asia/Manila").format('YYYY-MM-DD')
          }

        setOverView(filterDate)
        setExportDates({...searchValue})
      }
    }
  }
  const clearFilter = (newValue) => {
    getProductsReport(1, {})
    getCategoriesReport(1, {})
    setExportDates({...newValue})
  }

  const filterRef = useRef(null)

  return (
    <main className="content-product">
      <section className="content-product-breadcrumbs">
        analytics / products report
      </section>
      <section className="content-product-subtitle ">
        OVERVIEW : {_.isEmpty(overView.from) || _.isEmpty(overView.to) ? moment().tz("Asia/Manila").format("MMM DD, YYYY (dddd)") : `${overView.from} ~ ${overView.to}`}
      </section>
      <FilterBar onSearch={productFilter} onClear={clearFilter} ref={filterRef} />
      <div className="flex-column-start gap-30 width-100">

        {
          _.isEmpty(productReport) ? <></> :
            <>
              {/*<AnalyticsBarProducts chartData={productChartControl} />*/}
              <AnalyticsTableProducts tableContents={productReport} />
              {
                productPageCount <= 1 ? <></> :
                  <Pagination count={productPageCount} onChange={handlePageProduct} page={productPage} shape="rounded" />
              }
            </>
        }
        {
          _.isEmpty(categoriesReport) ? <></> :
            <>
              {/*<AnalyticsBarCategories chartData={categoriesChartControl} />*/}
              <AnalyticsTableCategories tableContents={categoriesReport} />
              {
                categoriesPageCount <= 1 ? <></> :
                  <Pagination count={categoriesPageCount} onChange={handlePageCategories} page={categoriesPage} shape="rounded" />
              }
            </>
        }

        <ExportModalSales key="ExportProducts" exportModalEdit={productsModal} closeExportModalEdit={() => {
          setProductsModal(false)
        }}
          modalContent={<ProductsExportContent dateFrom={exportDates.DateFrom} dateTo={exportDates.DateTo} sortBy={exportDates.sortBy} sendOptions={exportDates.sendOptions} />}
        />
        <ExportButton openExportModal={() => {
          setProductsModal(true)
        }} tooltip="Export Products Report" />
      </div>
    </main>
  )
}
export default ProductReport