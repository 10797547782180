import {useEffect, useState} from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const SubMenu = () => {
  const {tab: tabState, order: orderState} = useSelector((state) => state);
  const { currentTab } = tabState?.tab;
  const closeSubmenu = () => {
    document.getElementsByClassName("cms-submenu")[0].style.animation =
      "submenuAnimationClose .5s ease forwards";
  };
  let subMenu = {
    0: {
      title: "Dashboard",
      description: "Dashboard description",
      list: [],
    },
    1: {
      title: "Products Manager",
      description:
        "It allows you to control the products and other attributes that are related to it.",
      list: [
        ["product list", "product-list"],
        // ["product barcode", "product-barcode"],
        ["product stocks", "product-stock"],
        ["product group categories", "product-group-categories"],
        ["product categories", "product-categories"],
        ["product subcategories", "product-subcategories"],
      ],
    },
    2: {
      title: "E-Commerce Manager",
      description:
        "It allows you to view and control the orders, shipping areas, payment gateways, top-up, exchange rates, etc.",
      list: [
        ["orders", "order-list"],
        ["Order Dine in & KTV", "/order/dine-in"],
        // ["freebies (Web)", ""],
        ["shipping areas", "shipping-area"],
        ["shipping options", "shipping-option"],
        ["payment method", "payment-method"],
        ["platform", "platform"],
        ["shipping transaction", "shipping-transaction"]
        // ["top-up banks", ""],
        // ["top-up requests", ""],
        // ["exchange rate", ""],
        // ["agent code", ""],
        // ["reasons", ""],
      ],
    },
    3: {
      title: "Branch Manager",
      description:
        "It allows you to view and control the physical stores' transactions and details.",
      list: [
        ["branches", "branches"],
      ],
    },
    4: {
      title: "Discounts Manager",
      description:
        "It allows you to view and control the details of coupons and employees discount.",
      list: [
        ["web coupons", "web-coupons"],
        // ["POS coupons", ""],
        // ["employees discount", ""],
      ],
    },
    5: {
      title: "Customers Manager",
      description:
        "It allows you to view list of customers and check each customer's information and balance.",
      list: [
        ["customer list", "customer-list"],
        // ["customer balance", ""],
      ],
    },
    6: {
      title: "Users Management",
      description:
        "It allows you to view list of users and check each user's information (username, roles and access).",
      list: [["users list", "users-manager"]],
    },
    7: {
      title: "Analytics",
      description:
        "It allows you to view the reports based on An Nam Ecommerce",
      list: [
        ["sales report", "sales-report"],
        ["products report", "product-report"],
        ["platform report", "platform-report"],
        ["lalamove sales report", "lalamove-sales-report"],
        ["employee meal report", "employee-meal-report"],
      ],
    },
    8: {
      title: "Currency Conversion Management",
      description:
        "It allows you to manage the of currencies conversion and rates.",
      list: [
        ["rates", "rate-list"],
        // ["app push notification", ""],
        // ["app maintenance", ""],
      ],
    },
    9: {
      title: "Public Manager",
      description:
        "It allows you to manage the interface of public pages displayed to customers.",
      list: [
        ["banners", "banner-list"],
        // ["app push notification", ""],
        // ["app maintenance", ""],
      ],
    },
    10: {
      title: "Setting site",
      description:
          "Set some configuration on the site.",
      list: [
        ["Pusher notification", "setting-site/pusher-setting"],
        // ["app push notification", ""],
        // ["app maintenance", ""],
      ],
    },
  };
  const [content, setContent] = useState({});
  const [list, setList] = useState([]);

  useEffect(() => {
    setContent({ ...subMenu[currentTab] });
    setList(subMenu[currentTab].list);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabState.tab]);

  const totalPending = orderState?.totalPending ?? 0
  return (
    <div className="cms-submenu">
      <h2 className="cms-submenu-title">{content.title}</h2>
      <span className="cms-submenu-description">{content.description}</span>
      <div className="routes">
        {list.map((value, index) => {
          return (
            <Link
              to={value[1]}
              key={index}
              className="route-list"
              onClick={closeSubmenu}
            >
              {value[0]}
              {
                value[0] === "orders" ? (<>
                  {
                    Number(totalPending) <= 0 ? (
                      <></>
                    ) : <span className="route-list-count">{Number(totalPending) > 99 ? "99+" : Number(totalPending)}</span>
                  }
                </>) : ""
              }
            </Link>
          );
        })}
      </div>
      <button onClick={closeSubmenu} className="cms-submenu-close">
        close
      </button>
    </div>
  );
};

export default SubMenu;
