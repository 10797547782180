import { Backdrop, Fade, Modal, Skeleton } from "@mui/material";
import { Box } from "@mui/system";
import moment from "moment";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import _ from "lodash";
import {
  GetCouponById,
  UpdateCouponExpiration,
  UpdateCoupon,
  GetAllLimitationsByType
} from "../discountHelper";
import { expirationDate, autoCoupon, errorAutoCoupon, rateFormula  } from "../discountUtils";
import { GetAllCategoryList } from "../../ProductManager/productHelper";
import AddAmountFormula from "./AddAmountFormula";

const UpdateExpModal = ({
  modalLimitation,
  closeModalLimitation,
  refreshTable,
  id,
}) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "white",
    boxShadow: "0px 3px 10px #2a0e4e21",
    borderRadius: "6px",
    pt: 2,
    px: 4,
    pb: 3,
  };
  useEffect(() => {
    getCouponById(id);
    getLimitations();
    setIsLoading(false);
    return () => {};
    //eslint-disable-next-line
  }, [id]);
  const [fetching, setIsFetching] = useState(false);
  const [coupon, setCoupon] = useState(autoCoupon);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(errorAutoCoupon)
  const [errorList, setErrorList] = useState({})
  const [category, setCategory] = useState([]);
  const [steps, setSteps] = useState(1);
  const [page, setPage] = useState(3);
  const [rateDetails, setRateDetails] = useState(rateFormula);
  const [limitations, setLimitations] = useState([]);
  const branchId = localStorage.getItem("branchId");

  const proceedTo = (e) => {
    console.log("page", page)
    console.log("steps", steps)
    e.preventDefault();
    setIsLoading(true);
    validateFields();
    const {Prefix, CouponName, Limitation, MinPayout, MaxPayout, MinOrderReq, Category} = coupon
    if (page === 2) {  
        if (steps === 1 && coupon.IsTagCategory) {
          GetAllCategoryList(1, 100)
            .then((res) => {
              const { paginatedResults } = res?.data;
              setCategory(paginatedResults);
              // checkCategory(coupon.Category, paginatedResults)
              setSteps(2);
              setIsLoading(false);
            })
            .catch((err) => {
              Swal.fire({
                icon: "error",
                title: "Oops!",
                text: "Error occured while doing your request!",
                footer: "&#169; An Nam 2021",
                didOpen: () => {
                  Swal.hideLoading();
                },
              }).then(() => {
                setSteps(2);
                setIsFetching(false);
                setIsLoading(false);
              });
            });
        } else {
          if(Prefix !== '' && CouponName.EN !== '' && Limitation !== '' && MinPayout !== "0" && MaxPayout !== "0" && MinOrderReq !== "0" && Category.length > 0){
            setPage(3)
            setSteps(3)
          }
          setIsLoading(false);
          console.log("Category Details: ", coupon.Category)
          console.log("Into Amount Formula after category")
        } 
       
    }else if (page === 3 && steps !== 3) { //For Category List Checkbox Options
      if(Prefix !== '' && CouponName.EN !== '' && Limitation !== '' && MinPayout !== "0" && MaxPayout !== "0" && MinOrderReq !== "0"){
        setSteps(3)
      }
      setIsLoading(false); 
      console.log("Into Amount Formula")
    }else if (page === 3 && steps === 3) {
      console.log("Submit Success") 
      submitCoupon(e);
    } else{
      setIsLoading(false);
    }
  };

  const submitCoupon = (e) => {
    e.preventDefault();
    let newValues = coupon;
    newValues.BranchId.push(branchId);
    newValues.IsAllowedFreebie = convertToBool(newValues.IsAllowedFreebie);
    newValues.CouponStatus = convertToBool(newValues.CouponStatus);
    newValues.IsTagCategory = convertToBool(newValues.IsTagCategory);
    newValues.ExpirationDate.Start = moment(newValues.ExpirationDate.Start).tz("Asia/Manila").format().toString(); 
    newValues.ExpirationDate.End = moment(newValues.ExpirationDate.End).tz("Asia/Manila").format().toString();
    let Rates = []
    _.forEach(rateDetails.Rate, (val) => {
      Rates.push({
        Condition: val.Condition,
        Amount: Number(val.Amount),
        Rate: Number(val.Rate)
      })
    })
    newValues.Discount.Rate = Rates;
    console.log("UPDATE AUTO COUPON", newValues);
    // setIsLoading(true);
    setErrorList({});
    UpdateCoupon(id, coupon)
      .then((res) => {
        setIsLoading(false);
        closeModalLimitation();
      })
      .then(() => {
        refreshTable(e);
      })
      .catch((err) => {
        let str = validatorMessage(err?.response.data)
        Swal.fire({
          icon: "error",
          html: '<pre>' + str + '</pre>',
          customClass: {
            popup: 'format-pre'
          },
          footer: "&#169; An Nam 2021",
          didOpen: () => {
            Swal.hideLoading();
          },
        }).then(() => {
          // closeModalLimitation();
          setIsFetching(false);
        });
        setIsLoading(false); 
      });
  };

  const validatorMessage = data => { 
    let message = ""
    let dataval = (_.isEmpty(data.data) || data.data === null) ? ({Message: data.mess}) : data.data;  
    message += "Something's wrong in updating coupon\n\n";
    Object.entries(dataval).map(([key, value]) => ( 
      message += key + ": " + value + "\n"
    )); 
    return message;
  };

  const getCouponById = (id) => {
    setIsFetching(true);
    GetCouponById(id)
      .then((res) => {
        setCoupon(res?.data);
      })
      .then(() => {
        setIsFetching(false);
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Oops!",
          text: "Error occured while doing your request!",
          footer: "&#169; An Nam 2021",
          didOpen: () => {
            Swal.hideLoading();
          },
        }).then(() => {
          closeModalLimitation();
          setIsFetching(false);
        });
      });
  };

  const handleChangeExp = (e) => {
    const { name, value } = e.target;
    setCoupon((oldValue) => {
      return {
        ...oldValue,
        ExpirationDate: {
          ...oldValue.ExpirationDate,
          [name]: value,
        },
      };
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCoupon((oldValue) => {
      return {
        ...oldValue,
        [name]: value,
      };
    });

    if(name === "IsTagCategory"){ 
      if(value == 'true'){
        console.log("handleChange SetPage aa: ", 2)
        console.log("handleChange IsTagCategory aa: ", value)
        setPage(2);
      }else{
        console.log("handleChange SetPage bb: ", 3)
        console.log("handleChange IsTagCategory bb: ", value)
        setPage(3);
      } 
    }
    validateFields();
  };

  const handleChangeName = (e) => {
    const { name, value } = e.target;
    setCoupon((oldValue) => {
      return {
        ...oldValue,
        CouponName: {
          ...oldValue.CouponName,
          [name]: value,
        },
      };
    });
    validateFields();
  };

  const handleChangeDiscount = (e) => {
    const { name, value } = e.target;
    setCoupon((oldValue) => {
      return {
        ...oldValue,
        Discount: {
          ...oldValue.Discount,
          [name]: value,
        },
      };
    });
  };

  const handleCheckbox = (e) => {
    // setIsChecked(!isChecked);
    const { value } = e.target;

    if (e.target.checked) {
      setCoupon((oldValue) => {
        return {
          ...oldValue,
          Category: [...oldValue.Category, value],
        };
      });
    } else {
      let arrayHolder = coupon.Category;
      arrayHolder = arrayHolder.filter((e) => e !== value);
      console.log(arrayHolder);
      setCoupon({
        ...coupon,
        Category: arrayHolder,
      });
    }
  };

  console.log("Auto coupon: ", coupon)
  const {
    Prefix,
    ExpirationDate,
    CouponCode,
    CouponName,
    Discount,
    LoginType,
    Limitation,
    MinPayout,
    MaxPayout,
    MinOrderReq,
    CouponStatus,
    IsAllowedFreebie,
    IsTagCategory,
    GeneratedCoupons
  } = coupon;

  const { Start, End } = ExpirationDate ?? { Start: "", End: "" };


  const getLimitations = () => {
    GetAllLimitationsByType(1)
      .then((res) => {
        if (res.status === "success") {
          const { paginatedResults } = res?.data;

          setLimitations(paginatedResults);
        }
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Oops!",
          text: "No Limitation(s) found!",
          footer: "&#169; An Nam 2021",
          didOpen: () => {
            Swal.hideLoading();
          },
        });
        console.log(err.response);
      });
  };

   
  const convertToBool = (values) => {
    if (values === "true" || values === true) return true;
    if (values === "false" || values === false) return false;
  };

  let categoryList = category.map((cat, index) => {
    let ischecked = false
    _.forEach(coupon.Category, (val) => {
      if (cat._id === val) {
        ischecked = true
      } 
    })
    return (
      <div className="card-column-category" key={index}>
        <label htmlFor={cat._id}>
          <input
            onChange={(e) => handleCheckbox(e)}
            type="checkbox"
            name={`Category-${cat._id}`}
            id={cat._id}
            value={cat._id}
            checked={ischecked}
          />
          <div className="fix-column">
            <span className="name-en">
              {!_.isEmpty(cat.Name.EN) ? cat.Name.EN : "--"}
            </span>
            <span className="name-vn">
              {!_.isEmpty(cat.Name.VN) ? cat.Name.VN : "--"}
            </span>
          </div>
        </label>
      </div>
    );
  });

  // ********* Dynamic Add and Delete Rows ********************
  const addNewRows = (e) => {
    // const { name, value } = e.target;
    e.preventDefault();
    setRateDetails((oldValue) => {
      return {
        ...oldValue,
        Rate: [
          ...oldValue.Rate, 
          {
            Index: Math.random(),
            Condition: "<",
            Amount: 0,
            Rate: 0
          }],
      };
    });
    console.log("Coupon:", coupon)
  };

  const deleteRows = (e, record) => { 
    let RateArray = []
    _.map(rateDetails.Rate, (value) => {
      if(value._id !== record){
        RateArray.push({
          "_id": value._id,
          "Condition": value.Condition,
          "Amount": value.Amount,
          "Rate": value.Rate
        })
      }
    }) 
    setRateDetails({
      ...rateDetails,
      Rate: RateArray
    })
  };

  const validateFields = () => { 
    // console.log("coupon: ", coupon)
    Object.keys(coupon).map((fieldName, i) => {  
      if(fieldName === "Prefix"){ 
        setErrorMessage((oldValue) => {
          return {
            ...oldValue,
            Prefix: _.isEmpty(coupon.Prefix) ? true : false
          };
        });
      }else if(fieldName === "CouponName"){ 
        setErrorMessage((oldValue) => {
          return {
            ...oldValue,
            CouponName: _.isEmpty(coupon.CouponName.EN) ? true : false
          };
        });
      }else if(fieldName === "Limitation"){ 
        setErrorMessage((oldValue) => {
          return {
            ...oldValue,
            Limitation: (coupon.Limitation == "") ? true : false
          };
        });
      }else if(fieldName === "MinPayout"){ 
        setErrorMessage((oldValue) => {
          return {
            ...oldValue,
            MinPayout: (coupon.MinPayout > 0) ? false : true
          };
        });
      }else if(fieldName === "MaxPayout"){ 
        setErrorMessage((oldValue) => {
          return {
            ...oldValue,
            MaxPayout: (coupon.MaxPayout > 0) ? false : true
          };
        });
      }else if(fieldName === "MinOrderReq"){ 
        setErrorMessage((oldValue) => {
          return {
            ...oldValue,
            MinOrderReq: (coupon.MinOrderReq > 0) ? false : true
          };
        });
      } 
    }) 
  }

  return (
    <Modal
      open={modalLimitation}
      onClose={closeModalLimitation}
      closeAfterTransition
      BackdropComponent={Backdrop}
      style={{
        zIndex: 998,
      }}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={modalLimitation}>
        <Box sx={{ ...style, width: 600 }}>
          {fetching ? (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box sx={{ margin: 1 }} width={"100%"}>
                <h3>Update Coupon</h3>
                <Skeleton animation="wave" height={70} width={"100%"} />
                <Skeleton animation="wave" height={70} width={"100%"} />
                <Skeleton animation="wave" height={70} width={"100%"} />
                <Skeleton animation="wave" height={70} width={"100%"} />
                <Skeleton animation="wave" height={70} width={"100%"} />
                <Skeleton animation="wave" height={70} width={"100%"} />
                <Skeleton animation="wave" height={70} width={"100%"} />
                <Skeleton animation="wave" height={70} width={"100%"} />
                <Skeleton animation="wave" height={70} width={"100%"} />
                <Skeleton animation="wave" height={70} width={"100%"} />
                <Skeleton animation="wave" height={70} width={"100%"} />
              </Box>
            </Box>
          ) : steps === 1 ? (
            <div className="form-modal-container">
              <h3>Update Auto Generated Coupons</h3> 
              <hr />
              <div className="input">
                <label htmlFor="Prefix">Prefix Code</label>
                <input
                  onChange={(e) => handleChange(e)}
                  type="text"
                  id="Prefix"
                  name="Prefix"
                  value={Prefix}
                  placeholder="Enter Prefix code"
                  className={errorMessage.Prefix ? "error-input" : ""}
                  disabled={true}
                />
              </div>
              <div className="input">
                <label htmlFor="CouponName">Coupon Name</label>
                <input
                  onChange={(e) => handleChangeName(e)}
                  type="text"
                  id="CouponName"
                  name="EN"
                  value={CouponName.EN}
                  placeholder="Enter coupon name"
                  className={errorMessage.CouponName ? "error-input" : ""}
                />
              </div>
              {!_.isEmpty(errorList?.CouponName) ? (
                <small className="error-message">{errorList?.CouponName}</small>
              ) : (
                <></>
              )}
              <div className="input">
                <label htmlFor="ExpirationDateFrom">Expiration Date</label>
                <input
                  onChange={(e) => handleChangeExp(e)}
                  type="datetime-local"
                  id="ExpirationDate"
                  name="Start"
                  value={moment(ExpirationDate.Start).format(
                    "YYYY-MM-DDTHH:mm"
                  )}
                />  
                <input
                    onChange={(e) => handleChangeExp(e)}
                    type="datetime-local"
                    id="ExpirationDate"
                    name="End"
                    value={moment(ExpirationDate.End).format("YYYY-MM-DDTHH:mm")}
                  />
              </div>
              <div className="input">
                <label htmlFor="LoginType">Customer Login Required / Non Login</label>
                <select
                  onChange={(e) => handleChange(e)}
                  name="LoginType"
                  id="LoginType"
                  value={LoginType}
                >
                  <option value="SHOWTOALL">SHOW TO ALL</option>
                  <option value="LOGINONLY">LOGIN ONLY</option>
                </select>
              </div>
              <div className="input">
                <label htmlFor="Limitation">Limitation</label>
                <select
                  onChange={(e) => handleChange(e)}
                  name="Limitation"
                  id="Limitation"
                  value={Limitation}
                  className={errorMessage.Limitation ? "error-input" : ""}
                >
                  <option value="">Select Limitation</option>
                  {limitations?.map((el) => {
                    if (el.LoginType === LoginType) {
                      return (
                        <option key={el._id} value={el._id}>
                          {el.Description.EN}
                        </option>
                      );
                    }
                  })}
                </select>
              </div>
              <div className="input">
                <label htmlFor="MinPayout">Min. Payout</label>
                <input
                  onChange={(e) => handleChange(e)}
                  type="number"
                  id="MinPayout"
                  name="MinPayout"
                  value={MinPayout} 
                  className={errorMessage.MinPayout ? "error-input" : ""}
                />
              </div>
              <div className="input">
                <label htmlFor="MaxPayout">Max Payout</label>
                <input
                  onChange={(e) => handleChange(e)}
                  type="number"
                  id="MaxPayout"
                  name="MaxPayout"
                  value={MaxPayout} 
                  className={errorMessage.MaxPayout ? "error-input" : ""}
                />
              </div>
              <div className="input">
                <label htmlFor="MinOrderReq">
                  Min. Order/Category Amount Req.
                </label>
                <input
                  onChange={(e) => handleChange(e)}
                  type="number"
                  id="MinOrderReq"
                  name="MinOrderReq"
                  value={MinOrderReq}
                  className={errorMessage.MinOrderReq ? "error-input" : ""}
                />
              </div>
              <div className="input">
                <label htmlFor="CouponStatus">Status</label>
                <select
                  onChange={(e) => handleChange(e)}
                  name="CouponStatus"
                  id="CouponStatus"
                  value={CouponStatus}
                >
                  <option value={true}>Active</option>
                  <option value={false}>Inactive</option>
                </select>
              </div>
              <div>
                <label htmlFor="IsTagCategory">
                  Only apply on selected category?{" "}
                </label>
                <select
                  onChange={(e) => handleChange(e)}
                  name="IsTagCategory"
                  id="IsTagCategory"
                  value={IsTagCategory}
                >
                  <option value={true}>Yes</option>
                  <option value={false}>No</option>
                </select>
              </div>
              <div>
                <label>
                  Generated Coupon Count(s): {GeneratedCoupons.length}
                  {!_.isEmpty(GeneratedCoupons) ? (
                  Object.keys(GeneratedCoupons).map((key, index) => {
                    const {
                      _id,
                      Gcode
                    } = GeneratedCoupons[key];
                    return (
                      <div className="bulleted" key={index}>
                        {Gcode}
                      </div>
                    )
                  })
                  ) : (<>
                  
                  </>)}
                </label>
              </div>


            </div> //End of Forms
          ) : steps == 2 ? (
            <div className="container-row-wrap-start">
              <h2>Select Category: </h2>
              {categoryList}
            </div>
          ) : (<>
            <div className="form-modal-container">{/* Step 2: Category Options */}
              <h2>Amount Formula</h2> 
              <div className="input">
                <label htmlFor="Discount">Discount Type</label>
                <select
                  onChange={(e) => handleChangeDiscount(e)}
                  name="DiscountType"
                  id="Discount"
                  value={Discount.DiscountType}
                >
                  <option value="By Fix Amount">By Fix Amount</option>
                  <option value="By Percentage">By Percentage</option>
                </select>
              </div>
              <AddAmountFormula 
                {...{ 
                  setRateDetails,
                  rateDetails,
                  addNewRows,
                  deleteRows,
                  coupon
                }}     
              /> 
            </div>
          </>)
          }
          <div className="flex-row-end gap-10">
            <button
              className="btn-cancel"
              onClick={() => {
                if (steps === 3) {
                  if(coupon.IsTagCategory) { setSteps(2) } else{ setSteps(1) } 
                } else if (steps === 2) { 
                    setSteps(1);  
                    setPage(2)
                } else {
                  closeModalLimitation();
                }
              }}
            >
              {steps == 1 ? "Cancel" : "Back"}
            </button>
            <button
              className="btn-submit"
              disabled={isLoading || fetching}
              onClick={(e) => proceedTo(e)}
            >
              {isLoading ? <div className="loader"></div> : steps == 3 ? "Save" : "Next"}
            </button>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
};
UpdateExpModal.defaultProps = {
  modalLimitation: false,
  closeModalLimitation: () => {},
  refreshTable: () => {},
  id: "",
};
export default UpdateExpModal;
