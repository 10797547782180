import ClearIcon from '@mui/icons-material/Clear';
import _ from 'lodash';
import React, { useState } from 'react';
import Swal from 'sweetalert2';
import { HideItem } from '../../ProductManager/productHelper';
import { SubmitPlatform } from '../EcommerceHelper';

const AddingPlatform = ({ refreshTable }) => {
  
  let selectedBranch = localStorage.getItem("branchId");
  const [platForm, setPlatForm] = useState({
    PlatformName: "", 
    BranchId: selectedBranch,
    PricePercentage: 0
  })
  const [platFormError, setPlatFormError] = useState({
    Code: "",
    Name: ""
  })
  const refreshAll = () => {
    setPlatForm({
      PlatformName: "", 
      BranchId: selectedBranch,
      PricePercentage: 0
    })
    setPlatFormError({
      PlatformName: "",
      PricePercentage: ""
    })
  }
  const submitPlatForm = (e) => {
    e.preventDefault()
    Swal.fire({
      title: "In progress!",
      text: "Please wait while doing your request",
      allowOutsideClick: false,
      footer: "&#169; An Nam 2021",
      didOpen: () => {
        Swal.showLoading();
      }
    });
    SubmitPlatform(platForm)
      .then(res => {
        refreshAll()
        refreshTable(e)
        Swal.close()
        HideItem("#addPlatform")
      })
      .catch(
        err => {
          console.log(err.response);
          Swal.close()
          if (err.response) {
            if (_.isEmpty(err.response.data.data)) {
              Swal.fire({
                icon: 'error',
                title: err.response.data.mess,
                text: "Status : " + err.response.status,
                footer: "&#169; An Nam 2021",
                didOpen: () => {
                  Swal.hideLoading();
                }
              })
            } else {
              setPlatFormError({ ...err.response.data.data })
            }
          }
        }
      )
  }
  const handleChange = (e) => {
    const { name, value } = e.target
    setPlatForm({
      ...platForm,
      [name]: value
    })
  }
  const { PlatformName, PricePercentage } = platForm
  return (
    <div className="add-shipping-area" id='addPlatform'>
      <div className="add-shipping-area-container">
        <div className="add-shipping-area-container-header">
          <h3 className="add-shipping-area-container-header-title">
            {' '}
            Payment Method{' '}
          </h3>
          <button className="close-shipping-modal" onClick={(e) => {
            e.preventDefault()
            HideItem("#addPlatform")
          }}>
            <ClearIcon />
          </button>
        </div>
        <form onSubmit={(e) => { submitPlatForm(e) }}>
          <div className="form">
            <div className="form-container column">
              <label htmlFor="PlatformName">Platform Name</label>
              <input onChange={(e) => { handleChange(e) }} type="text" name="PlatformName" value={PlatformName} id="PlatformName" />
              <small className='error-message'>{platFormError.PlatformName}</small>
            </div>
            <div className="form-container column">
              <label htmlFor="PricePercentage">Price Percentage</label>
              <input onChange={(e) => { handleChange(e) }} type="text" name="PricePercentage" value={PricePercentage} id="PricePercentage" />
              <small className='error-message'>{platFormError.PricePercentage}</small>
            </div>

            <div className="form-container row-right">
              <button className="btn-submit" type="submit">
                CREATE
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddingPlatform;