import React, { useEffect, useRef, useState } from "react";
import { useTitle } from 'react-use';
import Swal from 'sweetalert2';
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import { Pagination } from '@mui/material';
import { countPage, HideItem, IdSelector, ShowItemFlex } from '../../ProductManager/productHelper'; 
import { GetRateList } from "../CurrencyHelper";
import Filter from './Filter';
import RateTable from './RateTable';
import RateUpdate from './RateUpdate';
import RateAdd from './RateAdd';
import '../currency.scss'

const RateList = () => {
  useTitle("An Nam | Currency Rates")
  const node = useRef(null);
  const node1 = useRef(null);
  const [rateList, setRateList] = useState([])
  const [page, setPage] = useState(1)
  const [pageCount, setPageCount] = useState(1)
  
  useEffect(() => {
    getRateList(1, {}) 
    const handleClick = (e) => {
      if (node.current.contains(e.target)) {
        return;
      }
      // if (node1.current.contains(e.target)) {
      //   return;
      // }
      // HideItem("#addShippingOption")
      HideItem("#updateRate")
    };
    // HideItem("#addShippingOption")
    HideItem("#updateRate")
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };

  }, [page])

  const handlePage = (e, p) => {
      e.preventDefault()
      setPage(p)
      // getRateList(p, searchValues)
  }
    

  const getRateList = (page, searchValue) => {
      
      Swal.fire({
          title: "In progress!",
          text: "Please wait while fetching data",
          allowOutsideClick: false,
          footer: "&#169; An Nam 2022",
          didOpen: () => {
            Swal.showLoading();
          }
      });

      setRateList([])
      setPageCount(0)

      GetRateList(page, searchValue)
      .then(res => {
          Swal.close()
          const { paginatedResults, metadata } = res?.data
          const { total, limit } = metadata;
          setPageCount(countPage(total, limit))
          setRateList(paginatedResults)
      })
      .catch( err => { 
            Swal.fire({
              icon: "error",
              title: "Oops!",
              text: "No Transaction(s) found!",
              footer: "&#169; An Nam 2021",
              didOpen: () => {
                Swal.hideLoading();
              }
            });
            setPageCount(0)
          }
      )      
  }

  const rateFilter = (e, searchValue) => {
      e.preventDefault() 
      getRateList(1, searchValue) 
      // setSearchValues(searchValue)
  } 

  const tableScrollLeft = () => {
    if (scrollHandle === 0) {
      scrollHandle = setInterval(function () {
        IdSelector("rateTable").style.scrollBehavior = "smooth";
        IdSelector("rateTable").scrollLeft -= 75;
      }, 100);

    }
  };
  let scrollHandle = 0;
  const tableScrollRight = () => {
    if (scrollHandle === 0) {
      scrollHandle = setInterval(function () {
        IdSelector("rateTable").style.scrollBehavior = "smooth";

        IdSelector("rateTable").scrollLeft += 75;
      }, 100);
    }
  };
  const stopScrolling = () => {
    clearInterval(scrollHandle);
    scrollHandle = 0;
  };

  const [rates, setRates] = useState({
    _id: "",
    rate: "",
    status: Boolean(true)
  })

  const showRateUpdate = (key, id) => {
    ShowItemFlex("#updateRate")
    setRates(rateList[key])
  }    

    return (
        <main className="content-product">
            <section className="content-product-breadcrumbs">
                currency conversion / rate
            </section>
            <Filter {...{ rateFilter }}/>
            <RateTable {...{ rateList, showRateUpdate }} />
            {
                pageCount === 0 ? <></> :
                <Pagination count={pageCount} onChange={handlePage} page={page} shape="rounded" />
            }
            <div className="table-slider-left" onMouseEnter={tableScrollLeft} onMouseLeave={stopScrolling}>
              <div className="table-slider-left-button">
                <ChevronLeftIcon />
              </div>
            </div>
            <div
              className="table-slider-right"
              onMouseEnter={tableScrollRight}
              onMouseLeave={stopScrolling}
            >
              <div className="table-slider-right-button">
                <ChevronRightIcon />
              </div>
            </div>
            <RateAdd {...{ node1, getRateList }} />
            <RateUpdate {...{ node, getRateList, rates }} />
            <div className='btn-open-modal'>
              <button onClick={(e) => {
                ShowItemFlex("#addRate")
              }}><CurrencyExchangeIcon sx={{ fontSize: "20px" }} /></button>
            </div>
        </main>
    )
}

export default RateList