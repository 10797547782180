import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Pagination } from "@mui/material";
import React, {useEffect, useState} from "react";
import { useTitle } from "react-use";
import Swal from "sweetalert2";
import { countPage, IdSelector } from "../../ProductManager/productHelper";
import { GetOrderList } from "../EcommerceHelper";
import ExportOrderButton from "./ExportOrderButton";
import ExportModal from "./Exports/ExportModal";
import SearchFilterOrder from "./Filter/SearchFilterOrder";
import OrderTable from "./OrderTable";
import {useSelector} from "react-redux";
import Pusher from "pusher-js";
const OrderManager = () => {

  useTitle("An Nam | Orders")

  const [orderList, setOrderList] = useState([])
  const [page, setPage] = useState(1)
  const [pageCount, setPageCount] = useState(1)
  const [orderSearch, setProductSearch] = useState("")
  const [searchValues, setSearchValues] = useState({})

  const cmsConfigPusher = useSelector((state) => state.cmsConfigPusher.configs);

  useEffect(() => {
    getOrderList(1, "", {})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const pusherEventRegister = []

    cmsConfigPusher.forEach(event => {
      if (event?.appKey) {
        const eventNames = event.events
        eventNames.forEach(eventName => {
          if (eventName === "CMS_PUSHER_NEW_ORDER") {
            const pusher = new Pusher(event?.appKey, {
              cluster: event?.cluster,
              encrypted: true
            });
            const channel = pusher.subscribe(event?.channel);

            channel.bind(eventName, () => {
              getOrderList(page, orderSearch, searchValues)
            });

            pusherEventRegister.push(pusher)
          }
        })
      }
    })

    return () => {
      pusherEventRegister.forEach(pusher => {
        if (pusher instanceof Pusher) {
          pusher.unbind_all()
          pusher.disconnect()
        }
      })
    }
    // eslint-disable-next-line
  }, [cmsConfigPusher])

  const handlePage = (e, p) => {
    e.preventDefault()
    setPage(p)
    getOrderList(p, orderSearch, searchValues)
  }
  const getOrderList = (page, search, searchValue) => {
    Swal.fire({
      title: "In progress!",
      text: "Please wait while fetching data",
      allowOutsideClick: false,
      footer: "&#169; An Nam 2022",
      didOpen: () => {
        Swal.showLoading();
      }
    });
    setOrderList([])
    setPageCount(0)
    GetOrderList(page, search, searchValue)
      .then(res => {
        Swal.close()
        const { paginatedResults, metadata } = res?.data
        const { total, limit } = metadata;
        setPageCount(countPage(total, limit))
        setOrderList(paginatedResults)
      })
      .catch(() => {
          Swal.fire({
            icon: "error",
            title: "Oops!",
            text: "No Order(s) found!",
            footer: "&#169; An Nam 2021",
            didOpen: () => {
              Swal.hideLoading();
            }
          });
          setPageCount(0)
        }
      )
  }
  const tableScrollLeft = () => {
    if (scrollHandle === 0) {
      scrollHandle = setInterval(function () {
        IdSelector("orderTable").style.scrollBehavior = "smooth";
        IdSelector("orderTable").scrollLeft -= 75;
      }, 100);

    }
  };
  let scrollHandle = 0;
  const tableScrollRight = () => {
    if (scrollHandle === 0) {
      scrollHandle = setInterval(function () {
        IdSelector("orderTable").style.scrollBehavior = "smooth";

        IdSelector("orderTable").scrollLeft += 75;
      }, 100);
    }
  };
  const stopScrolling = () => {
    clearInterval(scrollHandle);
    scrollHandle = 0;
  };

  const [exportModalEdit, setExportModalEdit] = useState(false)
  const closeExportModalEdit = () => {
    setExportModalEdit(false)
  }
  const openExportModal = (e) => {
    e.preventDefault()
    setExportModalEdit(true)
  }
  const clearFilter = (e) => {
    e.preventDefault()
    getOrderList(1, "", {})
    setPage(1)
  }
  const productFilter = (e, searchValue) => {
    e.preventDefault()
    const { search } = searchValue
    getOrderList(1, search, searchValue)
    setProductSearch(search)
    setSearchValues(searchValue)
  }
  return (
    <main className="content-product">
      <section className="content-product-breadcrumbs">
        e-commerce / orders
      </section>
      <SearchFilterOrder {...{ clearFilter, productFilter }} />
      <OrderTable {...{ orderList }} />
      {
        pageCount === 0 ? <></> :
          <Pagination count={pageCount} onChange={handlePage} page={page} shape="rounded" />
      }
      <ExportOrderButton {...{ openExportModal }} />
      <ExportModal {...{
        exportModalEdit,
        closeExportModalEdit
      }} />
      <div className="table-slider-left" onMouseEnter={tableScrollLeft} onMouseLeave={stopScrolling}>
        <div className="table-slider-left-button">
          <ChevronLeftIcon />
        </div>
      </div>
      <div
        className="table-slider-right"
        onMouseEnter={tableScrollRight}
        onMouseLeave={stopScrolling}
      >
        <div className="table-slider-right-button">
          <ChevronRightIcon />
        </div>
      </div>


    </main>
  );
};

export default OrderManager;
