import React, { useEffect, useState } from "react";
import { GetBranchDetails } from "./productHelper";
const ProductPerBranch = ({ index, branchId, typeOfProduct, submitBranchProduct, editBranchProduct }) => {
  const [branch, setBranch] = useState();


  const [branchProduct, setBranchProduct] = useState({
    BranchId: branchId,
    Stocks: 0,
    IsAvailable: true,
    SaleDateFrom: "",
    SaleDateTo: "",
    SalePrice: 0,
    OrderCount: 0,
    IfFeatured: false,
    Price: {
      Regular: 0,
      DineIn: 0,
      KTV: 0,
      Delivery: 0
    }
  });
  useEffect(() => {
    setBranchProduct({
      BranchId: branchId,
      Stocks: 0,
      IsAvailable: true,
      SaleDateFrom: "",
      SaleDateTo: "",
      SalePrice: 0,
      OrderCount: 0,
      IfFeatured: false,
      Price: {
        Regular: 0,
        DineIn: 0,
        KTV: 0,
        Delivery: 0
      }
    })
    GetBranchDetails(branchId)
      .then(res => setBranch(res.data))
      .catch(error => console.log(error.response))
    return () => {

    };
  }, [branchId, typeOfProduct]);
  const { IsAvailable, Stocks, SaleDateFrom, SaleDateTo, SalePrice, Price, IfFeatured } = branchProduct
  const { Regular, DineIn, KTV, Delivery } = Price
  const branchProductChange = (e) => {
    const { name, value } = e.target
    setBranchProduct({ ...branchProduct, [name]: value })

  }
  const branchPrizeChange = (e) => {
    const { name, value } = e.target
    if (name === "Regular") {
      setBranchProduct({
        ...branchProduct, Price: {
          ...branchProduct.Price,
          [name]: value,
          DineIn: value,
          Delivery: value,
          KTV: value
        }
      })
    } else {
      setBranchProduct({ ...branchProduct, Price: { ...branchProduct.Price, [name]: value } })
    }
  }
  const submitBranch = (e) => {
    e.preventDefault()

    let button = "submitBranch" + branchId
    let textButton = document.querySelector(`#${button}`).innerHTML
    if (textButton === "Set Branch") {
      submitBranchProduct(e, branchProduct)
      document.querySelector(`#${button}`).innerHTML = "Edit Branch";
      document.querySelector(`#${button}`).style.backgroundColor = "#f08d19"
    } else {
      editBranchProduct(e, branchId)
      document.querySelector(`#${button}`).innerHTML = "Set Branch";
      document.querySelector(`#${button}`).style.backgroundColor = "#50311b"
    }

  }
  return typeOfProduct === 1 ? (
    <div className="item-container" key={index}>
      <span> {branch ? branch.Name : ""}</span>
      <div className="item-row">
        <label htmlFor={"isactive" + branchId}>Status</label>
        <select onChange={(e) => { branchProductChange(e) }} name="IsAvailable" value={IsAvailable} id={"isactive" + branchId}>
          <option value={Boolean(true)}>Active</option>
          <option value={Boolean(false)}>Inactive</option>
        </select>
      </div>
      <div className="item-row">
        <label htmlFor={"stock" + branchId}>Stock</label>
        <input onChange={(e) => { branchProductChange(e) }} value={Stocks} type="number" name="Stocks" id={"stock" + branchId} />
      </div>
      <div className="item-row">
        <label htmlFor={"saleFrom" + branchId}>Sale from</label>
        <input onChange={(e) => { branchProductChange(e) }} type="date" name="SaleDateFrom" value={SaleDateFrom} id={"saleFrom" + branchId} />
      </div>
      <div className="item-row">
        <label htmlFor={"saleto" + branchId}>Sale to</label>
        <input onChange={(e) => { branchProductChange(e) }} name="SaleDateTo" value={SaleDateTo} type="date" id={"saleto" + branchId} />
      </div>
      <div className="item-row">
        <label htmlFor={"saleprice" + branchId}>Sale price</label>
        <input onChange={(e) => { branchProductChange(e) }} value={SalePrice} type="number" name="SalePrice" id={"saleprice" + branchId} />
      </div>
      <div className="item-row">
        <label htmlFor={"priceregular" + branchId}>Regular price</label>
        <input onChange={(e) => { branchPrizeChange(e) }} value={Regular} name="Regular" type="number" id={"priceregular" + branchId} />
      </div>
      <div className="item-row">
        <label htmlFor={"pricedinein" + branchId}>Dine in price</label>
        <input onChange={(e) => { branchPrizeChange(e) }} value={DineIn} name="DineIn" type="number" id={"pricedinein" + branchId} />
      </div>
      <div className="item-row">
        <label htmlFor={"ktvprice" + branchId}>KTV price</label>
        <input onChange={(e) => { branchPrizeChange(e) }} value={KTV} name="KTV" type="number" id={"ktvprice" + branchId} />
      </div>
      <div className="item-row">
        <label htmlFor={"deliveryprice" + branchId}>Delivery price</label>
        <input onChange={(e) => { branchPrizeChange(e) }} value={Delivery} name="Delivery" type="number" id={"deliveryprice" + branchId} />
      </div>
      <div className="item-row">
        <label htmlFor={"isfeatured" + branchId}>Featured</label>
        <select onChange={(e) => { branchProductChange(e) }} value={IfFeatured} name="IfFeatured" id={"isfeatured" + branchId}>
          <option value={Boolean(true)}>Yes</option>
          <option value={Boolean(false)}>No</option>
        </select>
      </div>
      <div className="item-row-right">
        <button id={"submitBranch" + branchId} onClick={(e) => { submitBranch(e) }}>Set Branch</button>
      </div>
    </div>
  ) : typeOfProduct === 2 ? (
    <div className="item-container" key={index}>
      <span> {branch ? branch.Name : ""}</span>
      <div className="item-row">
        <label htmlFor={"isactive" + branchId}>Status</label>
        <select onChange={(e) => { branchProductChange(e) }} name="IsAvailable" value={IsAvailable} id={"isactive" + branchId}>
          <option value={Boolean(true)}>Active</option>
          <option value={Boolean(false)}>Inactive</option>
        </select>
      </div>

      <div className="item-row">
        <label htmlFor={"isfeatured" + branchId}>Featured</label>
        <select onChange={(e) => { branchProductChange(e) }} value={IfFeatured} name="IfFeatured" id={"isfeatured" + branchId}>
          <option value={Boolean(true)}>Yes</option>
          <option value={Boolean(false)}>No</option>
        </select>
      </div>
      <div className="item-row-right">
        <button id={"submitBranch" + branchId} onClick={(e) => { submitBranch(e) }}>Set Branch</button>
      </div>
    </div>
  ) : (
    <div className="item-container" key={index}>
      <span> No Type Selected</span>
    </div>
  )
};

export default ProductPerBranch;
