import i18n from "i18next";
import { Suspense } from "react";
import { initReactI18next } from "react-i18next";
import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import CheckOut from "../components/public/CheckOut/CheckOut";
import OrderSummary from "../components/public/CheckOut/OrderSummary/OrderSummary";
import ForgotPass from "../components/public/ForgotPass";
import MyAccount from "../components/public/MyAccount";
import OrderHistoryTable from "../components/public/MyAccount/OrderHistoryTable";
import PendingTable from "../components/public/MyAccount/PendingTable";
import ShippingAddress from "../components/public/MyAccount/ShippingAddress";
import ShippingTable from "../components/public/MyAccount/ShippingTable";
import UserAccount from "../components/public/MyAccount/UserAccount";
import Public from "../components/public/Public";
import ResetPassword from "../components/public/ResetPassword";
import ProductReport from "../pages/Analytics/ProductReport/ProductReport";
import SalesReport from "../pages/Analytics/SalesReport/SalesReport";
import Branches from "../pages/BranchManager/Branches/Branches";
import CustomerList from "../pages/CustomerManager/CustomerList/CustomerList";
import CustomerDetails from "../pages/CustomerManager/CustomerList/CustomerDetails/CustomerDetails";
import Limitations from "../pages/DiscountManager/WebCoupons/Limitations/Limitations";
import WebCoupons from "../pages/DiscountManager/WebCoupons/WebCoupons";
import NewOrder from "../pages/Ecommerce/NewOrder/NewOrder";
import EditOrder from "../pages/Ecommerce/Order/EditOrder";
import OrderList from "../pages/Ecommerce/Order/OrdeList";
import EditPaymentBankList from "../pages/Ecommerce/PaymentMethod/EditPaymentBankList";
import PaymentMethodList from "../pages/Ecommerce/PaymentMethod/PaymentMethodList";
import ShippingAreaList from "../pages/Ecommerce/ShippingAreas/ShippingAreaList"; 
import ShippingOptionList from "../pages/Ecommerce/ShippingOption/ShippingOptionList";
import KTVMenu from "../pages/KTVmenu";
import AddNewProduct from "../pages/ProductManager/AddNewProduct";
import EditProduct from "../pages/ProductManager/EditProduct";
import ProductBarcode from "../pages/ProductManager/ProductBarcode";
import ProductCategories from "../pages/ProductManager/ProductCategories";
import ProductCategoryByGroup from "../pages/ProductManager/ProductCategoryByGroup";
import ProductGroupCategories from "../pages/ProductManager/ProductGroupCategories";
import ProductLists from "../pages/ProductManager/ProductLists";
import ProductStock from "../pages/ProductManager/ProductStock";
import ProductSubCategories from "../pages/ProductManager/ProductSubCategories";
import ProductSubCategoriesByCategory from "../pages/ProductManager/ProductSubCategoriesByCategory";
import Banner from "../pages/PublicManager/Banners/Banner";
import UserManager from "../pages/UserManager/UserManager";
import CMS from "./../components/cms/CMS";
import Admin from "./../pages/Admin";
import NotFound from "./../pages/NotFound";
import translation from "./../translation/translation.json";
import LoadingSpinner from "./LoadingSpinner";
import PrivatePublicRoute from "./PrivatePublicRoute";
import PrivateRoute from "./PrivateRoute";
import OrderDineIn from "../pages/Ecommerce/OrderDineIn";
import PusherNotification from "../pages/SettingSite/PusherNotification/index";
import Platform from "../pages/Ecommerce/Platform/Platform";
import PlatformReport from "../pages/Analytics/PlatformReport/PlatformReport";
import ShippingTransactionList from "../pages/Ecommerce/ShippingTransaction/ShippingTransactionList";
import LalamoveSalesReport from "../pages/Analytics/LalamoveSalesReport/LalamoveSalesReport";
import MealReport from "../pages/Analytics/EmployeeMealReport/MealReport";
import RateList from "../pages/Currency/Rate/RateList";

const MainRoute = () => {
  const tokens = useSelector((state) => state.token);
  const { lang } = tokens.token;
  i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      // the translations
      // (tip move them in a JSON file and import them,
      // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
      resources: translation,
      lng: lang, // if you're using a language detector, do not define the lng option
      fallbackLng: "EN",

      interpolation: {
        escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
      }
    });
  return (
    <Suspense fallback={
      <LoadingSpinner loaderOpen={true} />
    }>
      <Routes>
        <Route path="/">
          {/* <Route path="" element={

            <Public />

          } />
          <Route path="/ktv-menu" element={

            <KTVMenu />

          } />
          <Route path="/checkout" element={

            <CheckOut />

          } />
          <Route path="/ordersummary/:orderref" element={

            <OrderSummary />
          } />
          <Route path="/forgot-password" element={
            <ForgotPass />
          } ></Route>
          <Route path="/reset" element={
            <ResetPassword />
          } ></Route> */}
          {/* <Route path="/myaccount" element={
            <PrivatePublicRoute>
              <MyAccount />
            </PrivatePublicRoute>
          } >
            <Route path="" element={<PendingTable />} />
            <Route path="pending" element={<PendingTable />} />
            <Route path="toship" element={<ShippingTable />} />
            <Route path="orderhistory" element={<OrderHistoryTable />} />
            <Route path="shippingaddresses" element={<ShippingAddress />} />
            <Route path="accountsetting" element={<UserAccount />} />
          </Route> */}

          <Route path="admin/" element={<Admin />}></Route>
          <Route
            path="admin/cms"
            element={
              <PrivateRoute>
                <CMS />
              </PrivateRoute>
            }
          >
            <Route path="users-manager" element={<UserManager />} />

            {/* Product Management */}
            <Route path="product-list" element={<ProductLists />} />
            <Route path="product-list/new" element={<AddNewProduct />} />
            <Route path="product-list/details/:productId" element={<EditProduct />} />
            <Route path="product-barcode" element={<ProductBarcode />} />
            <Route path="product-categories" element={<ProductCategories />} />
            <Route path=":groupCategoryId/product-categories" element={<ProductCategoryByGroup />} />
            <Route
              path="product-group-categories"
              element={<ProductGroupCategories />}
            />
            <Route path="product-stock" element={<ProductStock />} />
            <Route
              path="product-subcategories"
              element={<ProductSubCategories />}
            />
            <Route
              path=":categoryId/product-subcategories"
              element={<ProductSubCategoriesByCategory />}
            />

            {/* Order Management */}
            <Route path="payment-method" element={<PaymentMethodList />} />
            <Route path="payment-method/:id" element={<EditPaymentBankList />} />
            <Route path="order-list" element={<OrderList />} />
            <Route path="order-list/new" element={<NewOrder />} />
            <Route path="order-list/:orderid" element={<EditOrder />} />
            <Route path="shipping-area" element={<ShippingAreaList />} />
            <Route path="shipping-option" element={<ShippingOptionList />} />
            <Route path="platform" element={<Platform/>}/>
            <Route path="shipping-transaction" element={<ShippingTransactionList/>}/>

            {/* Analytics */}
            <Route path="sales-report" element={<SalesReport />} />
            <Route path="product-report" element={<ProductReport />} />
            <Route path="platform-report" element={<PlatformReport />} />
            <Route path="lalamove-sales-report" element={<LalamoveSalesReport />} />
            <Route path="employee-meal-report" element={<MealReport />} />

            {/* branche manager */}
            <Route path="branches" element={<Branches />} />

            {/* discount manager */}
            <Route path="web-coupons" element={<WebCoupons />} />
            <Route path="web-coupons/limitations" element={<Limitations />} />

            {/* Customer management */}
            <Route path="customer-list" element={<CustomerList />} />
            <Route path="customer-list/:customerid" element={<CustomerDetails />} />

            {/* Banner Management */}
            <Route path="banner-list" element={<Banner />} />
            
            {/* Currency Conversion Management */}
            <Route path="rate-list" element={<RateList />} />

            {/*{Setting site}*/}
            <Route path="setting-site">
              <Route path="pusher-setting" element={<PusherNotification />}/>
            </Route>
          </Route>
          <Route
              path="order/dine-in"
              element={
                <PrivateRoute>
                  <OrderDineIn />
                </PrivateRoute>
              }
          >

          </Route>
          <Route path="/pageNotFound" element={<NotFound />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </Suspense>
  );
};

export default MainRoute;
