
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { Tooltip } from '@mui/material';
import Compressor from 'compressorjs';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from "react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useNavigate, useParams } from "react-router";
import { useTitle } from 'react-use';
import Swal from "sweetalert2";
import { ClassSelector, GetBase64, GetBranchDetails, GetCategoryListWithSubcategory, GetGroupCategoryWithCategory, GetProductDetails, HideItem, ShowItem, ShowItemFlex, UpdateProductAndAssign } from "./productHelper";
import Categorization from "./ProductLists/Categorization";
import EditorToolbar, { formats, modules } from "./ProductLists/EditorToolbar";
import ShowBranchList from "./ProductLists/ShowBranchList";
import TypeOfProduct from "./ProductLists/TypeOfProduct";
import ProductPerBranchEdit from './ProductPerBranchEdit';

const EditProduct = () => {
  useTitle("An Nam | Edit product")
  const jwt = require("jsonwebtoken");
  let token = localStorage.getItem("cmsToken");
  const user = jwt.decode(token);
  const [typeOfProduct, setTypeOfProduct] = useState(1);
  let selectedBranch = localStorage.getItem("branchId");
  const [perBranchProd, setPerBranchProd] = useState([

  ])
  const [language, setLanguage] = useState({ Language: "VN", LangDesc: "Vietnam" });
  const [tempIngredient, setTempIngredient] = useState([])
  const [product, setProduct] = useState(
    {
      Name: {
        EN: "",
        VN: "",
        CH: "",
        TH: ""
      },
      Description: {
        EN: "",
        VN: "",
        CN: "",
        TH: ""
      },
      SKU: "",
      Tags: null,
      Images: {
        DineIn: "",
        Delivery: ""
      },
      GroupCategories: [],
      Categories: [],
      SubCategories: [],
      Ingredients: [],
      Type: "Single",
      PreparationTime: "",
      Barcode: "",
      CountAble: true,
      UOM: "PC",
      Weight: 0,
      VAT: 0,
      Branches: [],
      _id: ""
    })
  // const [productE, setProductE] = useState({
  //   "Images.Delivery": "",
  //   "Images.DineIn": "",
  //   "Name": "",
  //   "PreparationTime": "",
  //   "SKU": "",
  // })
  const [groupCategoryListPermanent, setGroupCategoryListPermanent] = useState([])
  const [categoryListPermanent, setCategoryListPermanent] = useState([])
  const [subcategoryListPermanent, setsubcategoryListPermanent] = useState([])
  const { Name } = product
  const { Language, LangDesc } = language;
  const history = useNavigate();
  const node = useRef(null);
  const [groupcategory, setGroupCategory] = useState()
  const [category, setCategory] = useState()
  let count = 0
  let { productId } = useParams();
  useEffect(() => {
    getProduct(productId)
    GetGroupCategoryWithCategory().then((res) => {
      const { paginatedResults } = res.data
      setGroupCategory(paginatedResults)
    }).catch(error => {
      console.log(error.response)
    })
    GetCategoryListWithSubcategory()
      .then(res => {
        const { paginatedResults } = res.data
        let cat = []
        Object.keys(paginatedResults).forEach(key => {
          const { SubCategories } = paginatedResults[key]
          if (SubCategories.length) {
            Object.keys(SubCategories).forEach(key => {
              cat = [...cat, SubCategories[key]]
            })
          }

        })
        setCategory(cat)
      })
      .catch(err => console.log(err))
    GetBranchDetails(selectedBranch)
      .then(res => {
        if (!res.data.IsMainBranch) {
          Swal.fire({
            icon: "error",
            title: "Oops!",
            text: "The product create function is only allowed on MAIN branch. Please use the assign product function instead.",
            footer: "&#169; An Nam 2021",
          });
          history("/admin/cms/product-list");
        }
      })
      .catch(error => {
        console.log(error.response)
      })
    if (typeOfProduct === "") {
      ShowItemFlex(".modal-type");
    } else {
      HideItem(".modal-type");
    }
    const handleClick = (e) => {
      if (node.current.contains(e.target)) {
        return;
      }
      ClassSelector("modal-add-group").style.display = "none";
    };
    ClassSelector("modal-add-group").style.display = "none";
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);

    };
    //eslint-disable-next-line
  }, [typeOfProduct, selectedBranch, history, productId]);

  const getProduct = (id) => {
    GetProductDetails(id).then(
      res => {
        const { Name, Description, SKU, Tags, GroupCategory, Categories, SubCategories, Ingredients, Type, PreparationTime, Barcode, CountAble, UOM, Weight, BranchProducts, _id } = res?.data[0]
        console.log(res.data[0])
        setTypeOfProduct(Type === "Single" ? 1 : 2)
        setEn({
          en: Description?.EN ?? "",
          vn: Description?.VN ?? "",
          cn: Description?.CN ?? "",
          th: Description?.TH ?? "",
        })
        setTempIngredient(Ingredients)
        console.log(res?.data[0]);
        let Cat = _.map(Categories, (value) => {
          //console.log(value._id);
          return value._id
        })
        console.log(Cat);
        let SubCat = _.map(SubCategories, (value) => {
          return value._id
        })
        console.log(SubCat);
        setGroupCategoryListPermanent(GroupCategory)
        setCategoryListPermanent(Cat)
        setsubcategoryListPermanent(SubCat)
        setGroupCategoryListTemp(GroupCategory)
        setCategoryListTemp(Cat)
        setSubCategoryListTemp(SubCat)
        let branchProducts = _.map(BranchProducts, (BranchProducts) => {
          return {
            branchId: BranchProducts?.Branch ?? "",
            BranchId: BranchProducts?.Branch ?? "",
            Stocks: BranchProducts?.Stocks ?? 0,
            IsAvailable: BranchProducts?.IsAvailable ?? true,
            SaleDateFrom: BranchProducts?.SaleDateFrom ?? "",
            SaleDateTo: BranchProducts?.SaleDateTo ?? "",
            SalePrice: BranchProducts?.SalePrice ?? 0,
            OrderCount: BranchProducts?.OrderCount ?? 0,
            IfFeatured: BranchProducts?.IfFeatured ?? 0,
            Price: {
              Regular: BranchProducts.Price?.Regular ?? 0,
              DineIn: BranchProducts.Price?.DineIn ?? 0,
              KTV: BranchProducts.Price?.KTV ?? 0,
              Delivery: BranchProducts.Price?.Delivery ?? 0
            },
          }
        })
        setPerBranchProd(branchProducts)
        setProduct(
          {
            Name,
            Description,
            SKU,
            Tags,
            GroupCategories: GroupCategory,
            Categories: Cat,
            SubCategories: SubCat,
            Ingredients,
            Type,
            PreparationTime,
            Barcode,
            CountAble,
            UOM,
            Weight,
            VAT: 0,
            Branches: branchProducts,
            _id
          }
        )
      }
    ).catch(
      err => {
        console.log(err);
      }
    )
  }

  const selectType = (typeSelected, e) => {
    e.preventDefault();
    setTypeOfProduct(typeSelected);
    if (typeSelected === 1) {
      setProduct({ ...product, Type: "Single" })
    } else if (typeSelected === 1) {
      setProduct({ ...product, Type: "Group" })
    } else {
      setProduct({ ...product, Type: "" })
    }
  }

  const changeActive = (active, e) => {
    let buttons = document.querySelectorAll(".button-select");
    for (let i = 0; i < buttons.length; i++) {
      buttons[i].classList.remove("active")
    }
    e.target.classList.add('active')
    if (active === 1) {
      HideItem("#perbranch");
      ShowItemFlex("#general");
    } else if (active === 2) {
      ShowItemFlex("#perbranch");
      HideItem("#general");
    }
  }
  const showBranchList = () => {
    ShowItemFlex(".modal-add-group")
  }
  const selectAssignBranch = (selectedAssignBranch, e) => {
    e.preventDefault()
    let isOkay = false;
    if (selectedAssignBranch === "") {
      Swal.fire({
        icon: "error",
        title: "Oops!",
        text: "Please Select a branch to assign!",
        footer: "&#169; An Nam 2021",
      });
    } else {
      for (var i = 0; i < perBranchProd.length; i++) {
        if (perBranchProd[i].branchId === selectedAssignBranch) {
          isOkay = false;
          break;
        } else {
          isOkay = true;
        }
      }
      if (isOkay) {
        let listOfSelected = [];
        listOfSelected.push({ branchId: selectedAssignBranch })
        setPerBranchProd([...perBranchProd, ...listOfSelected])
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops!",
          text: "Branch was already selected!",
          footer: "&#169; An Nam 2021",
        });
      }
      HideItem(".modal-add-group")
    }

  }
  const changeLang = (e) => {
    const { name, value } = e.target;
    if (value === "EN") {
      setLanguage({ ...language, [name]: value, LangDesc: "English" })
      ShowItem("#reactEnglish")
      HideItem("#reactVietnam")
      HideItem("#reactChinese")
      HideItem("#reactThailand")
    } else if (value === "CN") {
      setLanguage({ ...language, [name]: value, LangDesc: "Chinese" })
      ShowItem("#reactChinese")
      HideItem("#reactEnglish")
      HideItem("#reactVietnam")
      HideItem("#reactThailand")
    } else if (value === "VN") {
      setLanguage({ ...language, [name]: value, LangDesc: "Vietnam" })
      ShowItem("#reactVietnam")
      HideItem("#reactEnglish")
      HideItem("#reactChinese")
      HideItem("#reactThailand")
    } else {
      setLanguage({ ...language, [name]: value, LangDesc: "Thailand" })
      ShowItem("#reactThailand")
      HideItem("#reactEnglish")
      HideItem("#reactVietnam")
      HideItem("#reactChinese")
    }


    let buttons = document.querySelectorAll(".lang");
    for (let i = 0; i < buttons.length; i++) {
      buttons[i].classList.remove("lang-active")
    }
    e.target.classList.add('lang-active')


  }
  const changeProductName = (e) => {
    const { name, value } = e.target;
    setProduct({ ...product, Name: { ...Name, [name]: value } })
  }
  const [userLanguage, setEn] = useState({
    en: "",
    vn: "",
    cn: "",
    th: ""
  });
  const [groupCategoryLists, setGroupCategoryList] = useState({
    groupCategoryList: [{
      icon: "",
      name: "",
      id: "",
      en: "",
      vn: "",
      categories: [],
      isCheck: false
    }]
  })
  const [groupCategoryListsTemp, setGroupCategoryListTemp] = useState(groupCategoryListPermanent)
  const [categoryListTemp, setCategoryListTemp] = useState(categoryListPermanent)
  const [subCategoryListTemp, setSubCategoryListTemp] = useState(subcategoryListPermanent)
  const onEnglish = (value) => {
    setEn({ ...userLanguage, en: value });
    setProduct({ ...product, Description: { ...product.Description, EN: value } })
  }
  const onVietnam = (value) => {
    setEn({ ...userLanguage, vn: value });
    setProduct({ ...product, Description: { ...product.Description, VN: value } })
  }
  const onChinese = (value) => {
    setEn({ ...userLanguage, cn: value });
    setProduct({ ...product, Description: { ...product.Description, CN: value } })
  }
  const onThailand = (value) => {
    setEn({ ...userLanguage, th: value });
    setProduct({ ...product, Description: { ...product.Description, TH: value } })
  }


  const groupCategoryChoice = (groupList, e) => {
    e.preventDefault();
    let toPass = groupList;
    setGroupCategoryListTemp(toPass)
  }

  const categoryChoice = (categoryList, e) => {
    e.preventDefault();
    let toPass = categoryList;
    setCategoryListTemp(toPass)
  }


  const subcategoryChoice = (subcategorylist, e) => {
    e.preventDefault();
    let toPass = subcategorylist;
    setSubCategoryListTemp(toPass)
    setGroupCategoryListPermanent(groupCategoryListsTemp)
    setCategoryListPermanent(categoryListTemp)
    setsubcategoryListPermanent(subcategorylist)
    setProduct({ ...product, GroupCategories: groupCategoryListsTemp, Categories: categoryListTemp, SubCategories: subcategorylist })
    //make a litte dent here  
    //groupCategoryListsTemp,categoryListTemp, subCategoryListTemp
  }

  const showCategorization = () => {

    GetGroupCategoryWithCategory().then((res) => {
      const { paginatedResults } = res.data
      let toPush = []
      let check = false;
      Object.keys(paginatedResults).forEach(key => {
        const { _id, Name, Icon, Categories } = paginatedResults[key]
        let arrayIndex = Object.keys(groupCategoryListsTemp).filter((key, index) => {
          return groupCategoryListsTemp[key] === _id;
        });
        let categoryPush = [];
        //categoryListTemp
        let categoryCheck = false;
        Object.keys(Categories).forEach(key => {
          const { Name, _id } = Categories[key]
          let arrayIndex = Object.keys(categoryListTemp).filter((key, index) => {
            return categoryListTemp[key] === _id;
          });
          try {
            if (categoryListTemp[arrayIndex] === _id) {
              categoryCheck = true;
            } else {
              categoryCheck = false;
            }
          } catch (error) {
            categoryCheck = false;
          }
          categoryPush.push({
            EN: Name.EN,
            VN: Name.VN,
            _id: _id,
            isCheck: categoryCheck
          })
        })
        try {
          if (groupCategoryListsTemp[arrayIndex] === _id) {
            check = true;
          } else {
            check = false;
          }
        } catch (error) {
          check = false;
        }
        toPush.push({
          icon: Icon,
          id: _id,
          en: Name.EN,
          vn: Name.VN,
          categories: categoryPush,
          isCheck: check
        })

      })
      setGroupCategoryList({ groupCategoryList: toPush })
      ShowItemFlex("#categoryModal")
      ShowItemFlex("#groupcategorycardlist");
      HideItem("#categorycardlist");
      HideItem("#subcategorycardlist");
    }).catch(error => {
      console.log(error.response)
    })



  }
  const closeForm = (e) => {
    e.preventDefault();
    setGroupCategoryListTemp(groupCategoryListPermanent)
    setCategoryListTemp(categoryListPermanent)
    setSubCategoryListTemp(subcategoryListPermanent)
    HideItem("#categoryModal")
    HideItem("#categorycardlist");
    HideItem("#subcategorycardlist");
  }

  const udpateProductAndAssign = (e, data) => {
    e.preventDefault()
    UpdateProductAndAssign(data).then(
      res => {
        Swal.fire({
          icon: 'success',
          title: "Success!",
          text: "Product has successfully updated",
          footer: "&#169; An Nam 2021"
        })
      }
    ).catch(
      err => {
        const { data } = err?.response?.data
        console.log(err?.response?.data)
        let message = ""
        //setProductE(data)
        _.forEach(data, (value, key) => {
          if (key === "SKU") {
            message += `<div><span>SKU:</span> ${data[key]}</div>`
          } else if (key === "Images.DineIn") {
            message += `<div><span>Dine-In Image:</span> ${data["Images.DineIn"]}</div>`
          } else if (key === "Images.Delivery") {
            message += `<div><span>Delivery Image:</span> ${data["Images.Delivery"]}</div>`
          } else if (key === "PreparationTime") {
            message += `<div><span>Preparation Time:</span> ${data["PreparationTime"]}</div>`
          } else if (key === "Name") {
            message += `<div><span>Name:</span> ${data["Name"]}</div>`
          } else if (key === "Branches") {
            message += `<div><span>Branches:</span> ${data["Branches"]}</div>`
          }
        })

        let errorMessage = `<div class="errorMessage">${message}</div>`
        console.log(errorMessage);
        errorAlert(e, errorMessage);
      }
    )
  }
  const errorAlert = (e, message) => {
    e.preventDefault();
    Swal.fire({
      icon: "warning",
      title: "Failed",
      html: message,
      footer: "&#169; An Nam 2021",
    });
  };
  const changeIconImageDineIn = (e) => {
    const { name } = e.target
    let file = document.querySelector('#dinein').files ?? "";
    if (!_.isEmpty(file)) {
      new Compressor(file[0], {
        quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
        success: (compressedResult) => {
          // compressedResult has the compressed file.
          // Use the compressed file to upload the images to your server.        
          GetBase64(compressedResult).then(
            (data) => {
              let imageArray = data.split(",");
              setProduct({ ...product, Images: { ...product.Images, [name]: imageArray[1] } })
            }
          )
        },
      });
    }

  }
  const changeIconImageDelivery = (e) => {
    const { name } = e.target
    let file = document.querySelector('#delivery').files ?? "";
    if (!_.isEmpty(file)) {
      new Compressor(file[0], {
        quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
        success: (compressedResult) => {
          // compressedResult has the compressed file.
          // Use the compressed file to upload the images to your server.        
          GetBase64(compressedResult).then(
            (data) => {
              let imageArray = data.split(",");
              setProduct({ ...product, Images: { ...product.Images, [name]: imageArray[1] } })
            }
          )
        },
      });

    }

  }
  // const errorAlert = (e, message) => {
  //   e.preventDefault();
  //   Swal.fire({
  //     icon: "warning",
  //     title: "Failed",
  //     html: message,
  //     footer: "&#169; An Nam 2021",
  //   });
  // };

  //const { Categories, SubCategories, Type, PreparationTime, Barcode, CountAble, UOM, Weight } = product
  const productChange = (e) => {
    const { name, value } = e.target
    setProduct({ ...product, [name]: value })
  }

  const submitBranchProduct = (e, productBraches) => {
    let isAvailable = productBraches.IsAvailable === "true" || productBraches.IsAvailable === true ? true : false
    let isFeatured = productBraches.IfFeatured === "true" || productBraches.IfFeatured === true ? true : false
    let toTranfer = {
      BranchId: productBraches.BranchId,
      Stocks: productBraches.Stocks,
      IsAvailable: isAvailable,
      SaleDateFrom: productBraches.SaleDateFrom,
      SaleDateTo: productBraches.SaleDateTo,
      SalePrice: productBraches.SalePrice,
      OrderCount: productBraches.OrderCount,
      IfFeatured: isFeatured,
      Price: productBraches.Price
    }
    setProduct({ ...product, Branches: [...product.Branches, toTranfer] })
  }
  const editBranchProduct = (e, id) => {
    const { Branches } = product
    const branchesResult = Branches.filter(Branches => Branches.BranchId !== id);
    setProduct({ ...product, Branches: branchesResult })
  }
  const categorizationBoard = (e, id) => {
    e.preventDefault();
    //button-group-category
    //box-container-group-category
    let buttons = document.querySelectorAll(".button-group-category");
    let container = document.querySelectorAll(".box-container-group-category");
    for (let i = 0; i < buttons.length; i++) {
      buttons[i].classList.remove("active")
    }
    for (let i = 0; i < buttons.length; i++) {
      container[i].classList.remove("active")
    }
    e.target.classList.add('active')
    document.querySelector(`#box${id}`).classList.add("active")
  }

  //exclusive for ingredient part only

  const [inputIngredient, setInputIngredient] = useState("")
  const ingredientTextChange = (e) => {
    const { value } = e.target;
    setInputIngredient(value)
  }
  const addIngredient = (e) => {
    e.preventDefault()
    if (!_.isEmpty(inputIngredient)) {
      let ingredientArr = tempIngredient
      ingredientArr.push(inputIngredient)
      setTempIngredient(ingredientArr)
      setInputIngredient("")
      setProduct({ ...product, Ingredients: tempIngredient })
    }
  }
  let newIngredients = []
  const removeIngredient = (e, index) => {
    e.preventDefault()
    newIngredients = tempIngredient;
    if (index > -1) {
      newIngredients.splice(index, 1); // 2nd parameter means remove one item only
    }
    setTempIngredient([...newIngredients])
    setProduct({ ...product, Ingredients: tempIngredient })
    setInputIngredient("")
  }

  const { UOM, SKU, Weight, PreparationTime, Barcode } = product
  return (
    <main className="content-product">
      <section className="content-main-breadcrumbs">
        product manager / list / edit product
      </section>
      <section className="content-product-add-product">
        <div className="content-product-add-product-row">
          <label htmlFor="productname">Product Name</label>
          <input type="text" id="productname" name={Language}
            value={
              Language === "EN" ? Name.EN
                : Language === "CN" ? Name.CN
                  : Language === "TH" ? Name.TH
                    : Name.VN
            }
            onChange={changeProductName} />
          <span>({LangDesc})</span>
        </div>
        <div className="content-product-change-language">
          <label htmlFor="language">FIELDS FOR</label>
          <button onClick={changeLang} className="lang lang-active" name="Language" value="VN">VN</button>
          <button onClick={changeLang} className="lang " name="Language" value="EN">EN</button>
          <button onClick={changeLang} className="lang " name="Language" value="CN">CN</button>
          <button onClick={changeLang} className="lang " name="Language" value="TH">TH</button>
        </div>
        <div className="content-product-add-product-big-row">
          <div className="left">
            <div className="button-container">
              <button className="button-select active" onClick={(e) => changeActive(1, e)}>General</button>
              <button className="button-select" onClick={(e) => changeActive(2, e)}>Per Branch</button>
            </div>
            <div id="general" className="content-product-add-product-big-row-item">
              <div className="item-row">
                <label htmlFor="dinein">Product image: Dine in </label>
                <input type="file"
                  id="dinein" name="DineIn"
                  accept="image/png, image/jpeg, image/gif"
                  onChange={(e) => changeIconImageDineIn(e)}
                />

              </div>
              <div className="item-row">
                <label htmlFor="delivery">Product image: Take out </label>
                <input type="file"
                  id="delivery" name="Delivery"
                  accept="image/png, image/jpeg, image/gif"
                  onChange={(e) => changeIconImageDelivery(e)}
                />
              </div>
              <div className="item-column">
                <label htmlFor="description">Description ({LangDesc})</label>
                <div id="reactEnglish" className="form-group col-md-12 editor">
                  <EditorToolbar toolbarId={'t1'} />
                  <ReactQuill
                    theme="snow"
                    value={userLanguage.en}
                    onChange={onEnglish}
                    modules={modules('t1')}
                    formats={formats}
                  />
                </div>
                <div id="reactVietnam" className="form-group col-md-12 editor">
                  <EditorToolbar toolbarId={'t2'} />
                  <ReactQuill
                    theme="snow"
                    value={userLanguage.vn}
                    onChange={onVietnam}
                    modules={modules('t2')}
                    formats={formats}
                  />
                </div>
                <div id="reactChinese" className="form-group col-md-12 editor">
                  <EditorToolbar toolbarId={'t3'} />
                  <ReactQuill
                    theme="snow"
                    value={userLanguage.cn}
                    onChange={onChinese}
                    modules={modules('t3')}
                    formats={formats}
                  />
                </div>
                <div id="reactThailand" className="form-group col-md-12 editor">
                  <EditorToolbar toolbarId={'t4'} />
                  <ReactQuill
                    theme="snow"
                    value={userLanguage.th}
                    onChange={onThailand}
                    modules={modules('t4')}
                    formats={formats}
                  />
                </div>
              </div>
            </div>
            <div id="perbranch" className="content-product-add-product-big-row-item">
              <div className="item-row-right">
                <button onClick={
                  showBranchList

                }>+ Branch Data</button>
              </div>
              {
                !_.isEmpty(perBranchProd) ? (
                  _.map(perBranchProd, (value, key) => {
                    const { branchId } = value

                    return (
                      <ProductPerBranchEdit key={key} {...{ key, branchId, typeOfProduct, submitBranchProduct, editBranchProduct, value, productId }} />
                    )
                  })

                ) : (<></>)
              }

            </div>

          </div>

          <div className="right-container">
            <div className="right">
              {/* this is where the general 2 started */}
              <div className="content-product-add-product-big-row-item">
                <div className="item-row">
                  <label htmlFor="type">Type : </label>
                  <span>{typeOfProduct === 1 ? "Single" : typeOfProduct === 2 ? "Group" : ""}</span>

                </div>
                <div className="item-row">
                  <label htmlFor="barcode" >Barcode</label>
                  <span>{Barcode ?? "N/A"}</span>
                </div>
                <div className="item-row">
                  <label htmlFor="uom" >UOM</label>
                  <select onChange={(e) => productChange(e)} value={UOM} name="UOM" id="uom">
                    <option value="PC">PC</option>
                    <option value="PACK">PACK</option>
                    <option value="BOX">BOX</option>
                  </select>
                </div>
                <div className="item-row">
                  <label htmlFor="sku" >SKU</label>
                  <input onChange={(e) => productChange(e)} type="text" value={SKU} name="SKU" id="sku" />

                </div>
                <div className="item-row">
                  <label htmlFor="weight" >Weight</label>
                  <input onChange={(e) => productChange(e)} type="text" value={Weight} name="Weight" id="weight" />
                  <span>KG</span>
                </div>
                <div className="item-row">
                  <label htmlFor="preparationtime" >Preparation Time</label>
                  <input type="number" onChange={(e) => productChange(e)} value={PreparationTime} name="PreparationTime" id="preparationtime" />
                  <span>MIN</span>
                </div>
                <div className="item-row">
                  <label htmlFor="ingredients" >Ingredients</label>
                  <input type="text" onChange={(e) => { ingredientTextChange(e) }} name="inputIngredient" value={inputIngredient} id="ingredients" />
                  <Tooltip title="Add Ingredients to product" placement="bottom-start" arrow><button className='add-ingredients' onClick={(e) => addIngredient(e)}><AddIcon /></button></Tooltip>
                </div>

                {
                  !_.isEmpty(tempIngredient) ?
                    (
                      <div className="item-row-ingredient">
                        {
                          _.map(tempIngredient, (value, key) => {
                            return (
                              <span key={key}>{value}
                                <button onClick={(e) => { removeIngredient(e, key) }}><CloseIcon sx={{ fontSize: "16px", color: "#7e4e2b", "&:hover": { color: "red" } }} />
                                </button>
                              </span>
                            )
                          })

                        }
                      </div>
                    )
                    :
                    (
                      <div className="item-row-ingredient">
                        No Ingredient Added
                      </div>
                    )
                }


                <div className="item-column">
                  <label htmlFor="categories" >Categorization* {_.isEmpty(groupCategoryListPermanent) ? (<></>) : (<button className="edit-category" onClick={showCategorization}>Edit</button>)}</label>
                  {
                    !_.isEmpty(groupCategoryListPermanent) ?
                      (
                        <div className="box-container">
                          <div className="box-container-button">
                            {Object.keys(groupCategoryListPermanent).map((key, index) => {
                              return index === 0 ? (
                                <button className="button-group-category active" onClick={(e) => { categorizationBoard(e, groupCategoryListPermanent[key]) }} key={index}>#{parseInt(key) + 1}</button>
                              ) : (
                                <button className="button-group-category" onClick={(e) => { categorizationBoard(e, groupCategoryListPermanent[key]) }} key={index}>#{parseInt(key) + 1}</button>

                              )

                            })}
                          </div>
                          <div>
                            {
                              groupcategory ? (
                                Object.keys(groupcategory).map((key, index) => {
                                  const { _id, Name, Categories } = groupcategory[key]
                                  let arrayIndex = Object.keys(groupCategoryListsTemp).filter((key, index) => {
                                    return groupCategoryListPermanent[key] === _id;
                                  });
                                  try {
                                    if (groupCategoryListPermanent[arrayIndex] === _id) {
                                      count += 1
                                      return (
                                        <div id={"box" + _id} key={index} className={count === 1 ? "box-container-group-category active" : "box-container-group-category"}>
                                          <div className="box-container-group-category-name">
                                            <span className="EN">
                                              {
                                                Name.EN
                                              }
                                            </span>
                                            <span className="VN">
                                              {
                                                Name.VN
                                              }
                                            </span>
                                            <hr />
                                          </div>
                                          {
                                            Categories.length ? (
                                              Object.keys(Categories).map((key, index) => {
                                                const { _id, Name } = Categories[key]
                                                let catIndex = Object.keys(categoryListPermanent).filter((keys) => {
                                                  return categoryListPermanent[keys] === _id;
                                                });

                                                try {
                                                  if (categoryListPermanent[catIndex] === _id) {

                                                    return (
                                                      <div key={index}>
                                                        <div className="item-row-block">
                                                          <div className="item-basis-left">Category : </div>
                                                          <div className="item-basis-right box-container-group-category-name">
                                                            <span className="EN">
                                                              {
                                                                Name.EN
                                                              }
                                                            </span>
                                                            <span className="VN">
                                                              {
                                                                Name.VN
                                                              }
                                                            </span>
                                                          </div>
                                                        </div>


                                                        {
                                                          category.length ? (
                                                            Object.keys(category).map((key, ind) => {
                                                              const { _id, Name, CategoryId } = category[key]
                                                              if (categoryListPermanent[catIndex] === CategoryId) {
                                                                let subcatIndex = Object.keys(subcategoryListPermanent).filter((x) => {
                                                                  return subcategoryListPermanent[x] === _id;
                                                                });
                                                                try {
                                                                  if (subcategoryListPermanent[subcatIndex] === category[key]._id) {
                                                                    return (
                                                                      <div key={ind}>
                                                                        <div className="item-row-block">
                                                                          <div className="item-basis-left">Subcategory : </div>
                                                                          <div className="item-basis-right box-container-group-category-name">
                                                                            <span className="EN">
                                                                              {
                                                                                Name.EN
                                                                              }
                                                                            </span>
                                                                            <span className="VN">
                                                                              {
                                                                                Name.VN
                                                                              }
                                                                            </span>
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                    )
                                                                  }
                                                                } catch (error) {

                                                                }

                                                              }

                                                              return null
                                                            })
                                                          ) : ""
                                                        }
                                                      </div>
                                                    )
                                                  }

                                                } catch (error) {

                                                }
                                                return null
                                              })
                                            ) : ""

                                          }

                                        </div>
                                      )

                                    }

                                  } catch (error) {

                                  }

                                  return null
                                })
                              ) : ""
                            }
                          </div>
                        </div>
                      ) :
                      (
                        <div onClick={showCategorization} className="blue-box-container" id="categories">
                          + set categories here
                        </div>
                      )
                  }

                </div>
              </div>
            </div>
            <div className="button-create-container">
              <button className="create-button" onClick={(e) => { udpateProductAndAssign(e, product) }}>UPDATE</button>
            </div>
          </div>
        </div>

      </section>
      <TypeOfProduct {...{ selectType }} />
      <ShowBranchList {...{ node, user, selectAssignBranch }} />
      <Categorization {...{ groupCategoryChoice, groupCategoryLists, closeForm, categoryChoice, subcategoryChoice, subCategoryListTemp }} />
    </main >
  );
};


export default EditProduct;
