import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import CloseIcon from '@mui/icons-material/Close';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { Tooltip } from "@mui/material";
import Collapse from '@mui/material/Collapse';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from "react";
import { statuses } from '../../../../components/public/CheckOut/OrderSummary/SummaryUtils';
import { newTabURL } from '../../../../components/public/PublicHelper';
import { GetPaymentMethodList } from '../../EcommerceHelper';
const SearchFilterOrder = ({ productFilter, clearFilter }) => {
  const [searchItem, setSearchItem] = useState({
    search: "",
    Status: "",
    PaymentMethod: "",
    SendOption: "",
    Paid: "",
    Locale: "",
    HasOrderPriority: "",
    DateOrderFrom: "",
    DateOrderTo: "",
    DateCompletedFrom: "",
    DateCompletedTo: ""
  })
  useEffect(() => {
    setDropDowns({
      paymentMethod: [],
    })
    getPaymentMethod()
    return () => {
      setDropDowns({
        paymentMethod: [],
      })
    }
    // eslint-disable-next-line
  }, [])
  const [filters, setFilters] = useState({})
  const [showContainer, setShowContainer] = useState(false)
  const [dropdowns, setDropDowns] = useState({
    paymentMethod: []
  })
  const getPaymentMethod = () => {
    GetPaymentMethodList(1, "", {}).then(res => {
      const { paginatedResults } = res?.data
      console.log(paginatedResults);
      setDropDowns(dropdown => {
        return {
          ...dropdown,
          paymentMethod: [...dropdown.paymentMethod, ...paginatedResults]
        }
      })

    }).catch(err => {
    })

  }


  const fillFilter = (searchItem) => {
    setFilters({})
    _.forEach(searchItem, (value, key) => {
      //const { search, GroupCategory, Category, Subcategory, type, stocks, sort, sortValue, IfFeatured, IsAvailable } = value
      if (!_.isEmpty(value)) {
        setFilters(filter => {
          return {
            ...filter,
            [key]: value
          }
        })
      }
    })
  }
  const removeFilter = (e, keyPass) => {
    e.preventDefault()
    let newItem = {
      ...searchItem,
      [keyPass]: ""
    }
    fillFilter(newItem)
    setSearchItem(newItem)
    productFilter(e, newItem)

  }
  const handleChange = useCallback((e) => {
    const { name, value } = e.target
    setSearchItem(oldValue => {
      return {
        ...oldValue,
        [name]: value
      }
    })
  }, [])
  const clearItem = (e) => {
    let newItem = {
      search: "",
      Status: "",
      PaymentMethod: "",
      SendOption: "",
      Paid: "",
      Locale: "",
      HasOrderPriority: "",
      DateOrderFrom: "",
      DateOrderTo: "",
      DateCompletedFrom: "",
      DateCompletedTo: ""
    }
    setSearchItem(newItem)
    fillFilter(newItem)
    productFilter(e, newItem)
  }
  const { paymentMethod } = dropdowns
  return (
    <section className="content-main-search">
      <div className="flex-column-start width-100 gap-5 content-main-search-filter">
        <div className="flex-row-start gap-10">
          <form onSubmit={(e) => {
            fillFilter(searchItem)
            productFilter(e, searchItem)
          }} className="content-main-search-item">
            <label htmlFor="search">Keyword / Barcode / SKU</label>
            <input autoComplete='off' type="text" name="search" value={searchItem.search} onChange={(e) => { handleChange(e) }} />
          </form>

          <button className="flex-row-center-center gap-5 " onClick={() => {
            setShowContainer(!showContainer)
          }}>
            <FilterAltIcon /> Filters
          </button>
          <Tooltip title="Add Order " placement="bottom-start" arrow>
            <button className=" flex-row-center-center gap-5"
              onClick={() => {
                newTabURL("/admin/cms/order-list/new")
              }}
            >
              <AddShoppingCartIcon />Add Order
            </button>
          </Tooltip>

        </div>
        <Collapse sx={{ width: "100%" }} in={showContainer}>
          <div className="filter-container border-top-dashed padding-top-10 " >
            <div className='flex-row-start-start flex-wrap width-100 gap-10'>
              {/* this is where you put your filtersss */}
              <div className="content-main-search-item">
                <label htmlFor="Status">Status</label>
                <select name="Status" id="Status" value={searchItem.Status} onChange={(e) => { handleChange(e) }}>
                  <option value="">All</option>
                  {
                    _.map(statuses, (value, key) => {
                      return <option key={key} value={key}>{value}</option>
                    })
                  }
                </select>
              </div>

              <div className="content-main-search-item">
                <label htmlFor="PaymentMethod">Payment Method</label>
                <select name="PaymentMethod" id="PaymentMethod" value={searchItem.PaymentMethod} onChange={(e) => { handleChange(e) }}>
                  <option value="">All</option>
                  {
                    _.map(paymentMethod, (value, key) => {
                      const { Code, Name } = value
                      return <option key={key} value={Code}>{Name}</option>

                    })
                  }
                </select>
              </div>


              <div className="content-main-search-item">
                <label htmlFor="SendOption">Send Option</label>
                <select name="SendOption" id="SendOption" value={searchItem.SendOption} onChange={(e) => { handleChange(e) }}>
                  <option value="">All</option>
                  <option value="DINEIN">Dine In</option>
                  <option value="DELIVERY">Delivery</option>
                  <option value="PICKUP">Pick up</option>
                  <option value="KTV">KTV</option>
                </select>
              </div>

              <div className="content-main-search-item">
                <label htmlFor="Paid">Payment Status</label>
                <select name="Paid" id="Paid" value={searchItem.Paid} onChange={(e) => { handleChange(e) }}>
                  <option value="">All</option>
                  <option value={true}>Paid</option>
                  <option value={false}>Unpaid</option>
                </select>
              </div>


              <div className="content-main-search-item">
                <label htmlFor="Locale">Locale</label>
                <select name="Locale" id="Locale" value={searchItem.Locale} onChange={(e) => { handleChange(e) }}>
                  <option value="">All</option>
                  <option value="EN">EN</option>
                  <option value="VN">VN</option>
                  <option value="CN">CN</option>
                  <option value="TH">TH</option>
                </select>
              </div>

              <div className="content-main-search-item">
                <label htmlFor="HasOrderPriority">Priority Status</label>
                <select name="HasOrderPriority" id="HasOrderPriority" value={searchItem.HasOrderPriority} onChange={(e) => { handleChange(e) }}>
                  <option value="">All</option>
                  <option value={true}>Show priority order only</option>
                  <option value={false}>Show normal order only</option>
                </select>
              </div>


              <div className="content-main-search-item">
                <label htmlFor="DateOrderFrom">Date Ordered From</label>
                <input type="date" name="DateOrderFrom" id="DateOrderFrom" value={searchItem.DateOrderFrom} onChange={(e) => { handleChange(e) }} />
              </div>
              <div className="content-main-search-item">
                <label htmlFor="DateOrderTo">Date Ordered To</label>
                <input type="date" name="DateOrderTo" id="DateOrderTo" value={searchItem.DateOrderTo} onChange={(e) => { handleChange(e) }} />
              </div>
              <div className="content-main-search-item">
                <label htmlFor="DateCompletedFrom">Date Completed From</label>
                <input type="date" name="DateCompletedFrom" id="DateCompletedFrom" value={searchItem.DateCompletedFrom} onChange={(e) => { handleChange(e) }} />
              </div>
              <div className="content-main-search-item">
                <label htmlFor="DateCompletedTo">Date Completed From</label>
                <input type="date" name="DateCompletedTo" id="DateCompletedTo" value={searchItem.DateCompletedTo} onChange={(e) => { handleChange(e) }} />
              </div>
              {/*  */}


            </div>
            <div className="flex-row-end gap-10 margin-top-20">
              <Tooltip title="Hide or Cancel filter" placement="bottom-start" arrow>
                <button className="flex-row-center-center gap-5 skeleton-button"
                  onClick={(e) => {
                    setShowContainer(false)
                  }}
                >
                  Hide / Cancel
                </button>
              </Tooltip>
              <Tooltip title="Apply Filter" placement="bottom-start" arrow>
                <button className="flex-row-center-center gap-5"
                  onClick={(e) => {
                    fillFilter(searchItem)
                    productFilter(e, searchItem)
                  }}
                >
                  Apply Filters
                </button>
              </Tooltip>
            </div>

          </div>
        </Collapse>
        {
          _.isEmpty(filters) ? <></> : (
            <div className="filter-list">
              Active filters: {
                _.map(filters, (value, key) => {
                  return (
                    //<div key={key}></div>
                    <span key={key}>{key === "search" ? value : key}
                      <button onClick={(e) => { removeFilter(e, key) }}>
                        <CloseIcon sx={{ fontSize: "16px", color: "#7e4e2b", "&:hover": { color: "red" } }} />
                      </button>
                    </span>
                  )
                })
              }
              <button className="flex-row-center-center gap-5 skeleton-button"
                onClick={(e) => {
                  clearItem(e)
                }}
              >
                clear all
              </button>
            </div>
          )
        }

      </div >

    </section >
  )
};

export default SearchFilterOrder;
