import React, { useEffect, useRef } from "react";
import ScrollContainer from 'react-indiana-drag-scroll';
const BankTable = ({ bankList, deleteBank, handleChangeBankStatus }) => {
  const container = useRef(null)
  useEffect(() => {
    container.current.getElement().id = "paymentMethodTable"

    return () => {

    }
  }, [])

  return (
    <ScrollContainer className="scroll-container content-product-table" ref={container}>
      <div className="content-product-table-head">
        <div className="content-product-table-head-item center item-name">Bank Name</div>
        <div className="content-product-table-head-item center item-name">Bank Account</div>
        <div className="content-product-table-head-item normal-start item-name">Bank Number</div>
        <div className="content-product-table-head-item normal-start item-name">URL Login</div>
        <div className="content-product-table-head-item center item-name">Status</div>
      </div>
      {bankList.length ? (
        Object.keys(bankList).map((key, index) => {
          const { _id, BankName, Account, Number, UrlLogin, IsActive } = bankList[key]
          return (
            <div className="content-product-table-data" key={index}>
              <div className="content-product-table-data-item center item-name">
                {BankName}
              </div>
              <div className="content-product-table-data-item center item-name">
                {Account}
              </div>
              <div className="content-product-table-data-item normal-start item-name">

                {Number}
              </div>
              <div className="content-product-table-data-item normal-start item-name">

                {UrlLogin}
              </div>

              <div className="content-product-table-data-item center item-name">
                <div className="status">
                  <label className="switch">
                    <input
                      type="checkbox"
                      name="IsActive"
                      value={IsActive}
                      checked={IsActive}
                      onChange={() => handleChangeBankStatus(key, _id)}
                    />
                    <span className="slider round"></span>
                  </label>

                </div>
              </div>

            </div>
          )
        })
      ) : (
        <div className="content-product-table-data center-data">
          <div className="content-product-table-data-item center item-name">
          </div>
          <div className="content-product-table-data-item center item-name">

          </div>
          <div className="content-product-table-data-item normal-start item-name">
            No bank account found

          </div>
          <div className="content-product-table-data-item normal-start item-name">
          </div>

          <div className="content-product-table-data-item center item-name">
          </div>
        </div>
      )}
    </ScrollContainer >
  )
};

export default BankTable;
