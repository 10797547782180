import _ from 'lodash';
import moment from 'moment-timezone';
import {useEffect, useMemo, useState} from 'react';
import 'react-quill/dist/quill.snow.css';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useTitle } from 'react-use';
import Swal from 'sweetalert2';
import { GetSpecificPaymentMethod } from '../../../components/public/PublicHelper';
import { GetProductDetails } from '../../ProductManager/productHelper';
import {GetOrderDetail, GetOrdersByPlatformId, UpdateOrder} from '../EcommerceHelper';
import EditOrderModal from './EditOrder/EditOrderModal';
import LogsC from './EditOrder/LogsC';
import OrderCustomerDetail from './EditOrder/OrderCustomerDetail';
import OrderDateAndStatus from './EditOrder/OrderDateAndStatus';
import OrderDetailTable from './EditOrder/OrderDetailTable';
import OrderStatusContainer from './EditOrder/OrderStatusContainer';
import PaymentMethodAndNote from './EditOrder/PaymentMethodAndNote';
import PrefferedTime from './EditOrder/PrefferedTime';
import ShippingOptionContainer from './EditOrder/ShippingOptionContainer';
import SendOptionContainer from './EditOrder/SendOptionContainer';
import { orderDetails, statuses } from './OrderUtils';
import {
  calcDiscount,
  calcShippingFee,
  calcSubTotal,
  calcTotalAmount,
  computeAddedFee,
  getObjectKey
} from "./OderHelper";
import ModalConfirmKitchenPrintOrder from "./ExtraOrder/components/ModalConfirmKitchenPrintOrder";
import KitchenPrintOrder from "./ExtraOrder/components/KitchenPrintOrder";
import {EXTRA_ORDER_PRINT_TYPES, EXTRA_ORDER_STATUS} from "./ExtraOrder/constant";
import {post} from "../../../helpers/axiosHelper";
import ConfirmPaymentStatus from "./EditOrder/ConfirmPaymentStatus";
 
import Pusher from "pusher-js";

const EditOrder = () => {
  useTitle('An Nam | Edit order');

  const tokens = useSelector((state) => state.token);
  const { token } = tokens.token
  const jwt = require("jsonwebtoken");
  let user = jwt.decode(token)
  let { orderid } = useParams();
  const [order, setOrder] = useState(orderDetails)
  const [orderE, setOrderE] = useState(orderDetails)
  const [paymentMethod, setPaymentMethod] = useState("")
  const [subtotal, setSubtotal] = useState(0)
  const [statusNote, setStatusNote] = useState("")
  const [currentStatus, setCurrentStatus] = useState(0)
  const [currentPaymentStatus, setCurrentPaymentStatus] = useState(false) 
  const [currentPlaceOrderId, setCurrentPlaceOrderId] = useState({
    PlaceOrderID: ""
  })

  const cmsConfigPusher = useSelector((state) => state.cmsConfigPusher.configs);

  useEffect(() => {

    getOrderDetail()
    //eslint-disable-next-line
  }, []) 

  useEffect(() => {
    const pusherEventRegister = []

    cmsConfigPusher.forEach(event => {
      if (event?.appKey) {
        const eventNames = event.events
        
        eventNames.forEach(eventName => {
          // console.log("eventNames----: ", eventNames)
          if (eventName === "CMS_PUSHER_LALAMOVE") {
            const pusher = new Pusher(event?.appKey, {
              cluster: event?.cluster,
              encrypted: true
            });
            const channel = pusher.subscribe(event?.channel);

            channel.bind(eventName, () => {
              getOrderDetail()
            });
            // console.log("channel: ", channel)
            // console.log("pusher: ", pusher)

            pusherEventRegister.push(pusher)
          }
        })
      }
    })

    return () => {
      pusherEventRegister.forEach(pusher => {
        if (pusher instanceof Pusher) {
          pusher.unbind_all()
          pusher.disconnect()
        }
      })
    }
    // eslint-disable-next-line
  }, [cmsConfigPusher])

  const getOrderDetail = () => {
    Swal.fire({
      title: "In progress!",
      text: "Please wait while fetching data",
      allowOutsideClick: false,
      footer: "&#169; An Nam 2021",
      didOpen: () => {
        Swal.showLoading();
      },
    });

    GetOrderDetail(orderid)
      .then(res => {
        setOrder(res?.data)
        setCurrentStatus(res?.data?.Status)
        setCurrentPaymentStatus(res?.data?.Paid)
        const { OrderAmount, PaymentMode } = res.data
        let Subtotal = OrderAmount ?? 0
        getProducDetails(res.data)
        setSubtotal(Subtotal)
        getPaymentDetail(PaymentMode)
        setCurrentPlaceOrderId({
          ...currentPlaceOrderId,
          PlaceOrderID: res?.data?.Courier.LalamoveDetails.PlaceOrderId
        })
        
        Swal.close();
      }).catch(
        err => { 
          Swal.fire({
            icon: 'error',
            title: "Failed!",
            text: "An error occured, please try again later",
            footer: "&#169; An Nam 2021"
          })
          console.log(err.response);
        }
      )
  }
  // console.log("PO: ", currentPlaceOrderId.PlaceOrderID)
  const getPaymentDetail = (code) => {
    GetSpecificPaymentMethod(code).then(
      res => {
        setPaymentMethod(res.data.paginatedResults[0].Name);
      }
    ).catch(err => {
      console.log(err);
    })
  }
  const getData = (property) => {
    // return _.isEmpty(property) ? "N/A" : `${property}`
    return property ?? "N/A"
  }

  const getStatus = (status) => {
    return statuses[status]
  }
  const [newPrices, setNewPrices] = useState([])
  const getProducDetails = (dataOrder) => {
    let branchId = localStorage.getItem("branchId")
    const { OrderDetails } = dataOrder
    let toPush = []
    for (let i = 0; i < OrderDetails.length; i++) {
      const { ProductId } = OrderDetails[i]
      GetProductDetails(ProductId).then(
        res => {
          const { BranchProducts } = res.data[0]
          let Price = _.find(BranchProducts, (value) => {
            return value.Branch === branchId
          }).Price
          toPush.push({ ...OrderDetails[i], Price: Price })
        }
      ).catch(err => {
        console.log(err.response);
      })
    }
    setNewPrices(toPush)
  }
  const handleChange = (e) => {
    const { name, value } = e.target
    if (name === 'Status') {
      setOrder({
        ...order,
        [name]: Number(value)
      })
    } else if (name === "SendOption") {
      const { _id } = order
      const { UserName } = user

      let newOrderDetails = []
      if (value === "DELIVERY") {
        newOrderDetails = _.map(newPrices, (value) => {
          return {
            ...value,
            Price: value.Price.Delivery,
            TotalPrice: Number(value.Quantity) * Number(value.Price.Delivery)
          }
        })
      } else if (value === "DINEIN") {
        newOrderDetails = _.map(newPrices, (value) => {
          return {
            ...value,
            Price: value.Price.DineIn,
            TotalPrice: Number(value.Quantity) * Number(value.Price.DineIn)
          }
        })

      } else if (value === "PICKUP") {
        newOrderDetails = _.map(newPrices, (value) => {
          return {
            ...value,
            Price: value.Price.Regular,
            TotalPrice: Number(value.Quantity) * Number(value.Price.Regular)
          }
        })

      } else if (value === "KTV") {
        newOrderDetails = _.map(newPrices, (value) => {
          return {
            ...value,
            Price: value.Price.KTV,
            TotalPrice: Number(value.Quantity) * Number(value.Price.KTV)
          }
        })
      }

      const itemPrice = calcSubTotal(newOrderDetails)
      setSubtotal(itemPrice)

      //ProductId
      let status = {
        StatusNum: null,
        Note: `<strong>${UserName}</strong> updated send option to ${value}`,
        DateAdded: moment().tz("Asia/Manila").format(),
        StatusUpdateBy: UserName
      }

      let newOrder = {
        ...order,
        [name]: String(value),
        StatusNote: [...StatusNote, status],
        OrderDetails: newOrderDetails,
      }

      udpateOrder(_id, newOrder)
    }
  }
  const udpateOrder = (orderId, data = OrderDetails) => {
    setOrderE({})
    Swal.fire({
      title: "In progress!",
      text: "Please wait while fetching data",
      allowOutsideClick: false,
      footer: "&#169; An Nam 2021",
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const handleUpdate = (newOrder = data) => {
      const orderDetails = newOrder.OrderDetails ?? []
      const subTotal = calcSubTotal(orderDetails)
      const newDiscount = calcDiscount(newOrder, subTotal)
      const shippingFee = newOrder?.ShippingAmount ?? 0
      let { totalAddedFee } = computeAddedFee(data?.AddFee, subTotal)
      let newTotalAmount = calcTotalAmount(subTotal, newDiscount, totalAddedFee, shippingFee)
    
      newOrder["TotalAmount"] = Number(newTotalAmount)
      newOrder["OrderAmount"] = Number(subTotal)
      newOrder["Discount"] = Number(newDiscount)

      UpdateOrder(orderId, JSON.stringify(newOrder)).then(
          res => {
            setOrder(res.data)
            setCurrentStatus(res?.data?.Status)
            setCurrentPaymentStatus(res?.data?.Paid)
            getPaymentDetail(data.PaymentMode)
            getProducDetails(res.data)
            setStatusNote("")
            Swal.close();
            Swal.fire({
              toast: true,
              icon: 'success',
              title: 'Order updated',
              position: 'top-right',
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
              }
            })
            closeModalEdit()
            setSelectedAddProduct([])
          }
      ).catch(
          err => {
            Swal.fire({
              icon: 'error',
              title: "Failed!",
              text: "An error occured, please try again later",
              footer: "&#169; An Nam 2021"
            })
            const { data } = err?.response?.data
            setOrderE(data)
          }
      )
    }
    handleUpdate(data)
    // const SendOption = data?.SendOption ?? ""
    // if(SendOption.toUpperCase() === "DELIVERY") {
    //   calcShippingFee(data)
    //   .then(shippingFee => {
    //     if(shippingFee === false) {
    //       Swal.fire({
    //         title: 'Error',
    //         text: "The shipping cost could not be calculated, please contact the administrator",
    //         icon: 'error',
    //         showCancelButton: false,
    //         confirmButtonColor: '#3085d6',
    //         confirmButtonText: 'Yes',
    //       })

    //       return false
    //     }

    //     const newOder = {
    //       ...data,
    //       ShippingAmount: Number(shippingFee)
    //     }

    //     handleUpdate(newOder)
    //   })
    //   .catch(() => {
    //     Swal.fire({
    //       icon: 'error',
    //       title: "Failed!",
    //       text: "An error occured, please try again later",
    //       footer: "&#169; An Nam 2021"
    //     })
    //   })
    // }
    // else {
    //   handleUpdate(data)
    // }
  }
  const updateOrderStatus = (e) => {
    e.preventDefault()
    let today = new Date();
    const { Status, _id } = order
    const { UserName } = user
    let status = {
      StatusNum: Status,
      Note: statusNote,
      DateAdded: moment(today).tz("Asia/Manila").format(),
      StatusUpdateBy: UserName
    }
    udpateOrder(_id, {
      ...order,
      Status: Status,
      StatusNote: [status, ...StatusNote]
    })
  }

  const submitNewPreparationTime = (e, newPreparationTime) => {
    e.preventDefault()
    const { _id } = order
    const { UserName } = user
    // console.log("time", moment(newPreparationTime).tz("Asia/Manila").format("yyyy-MM-DD HH:mm"));
    let prepTime = !_.isEmpty(newPreparationTime) ? moment(newPreparationTime).tz("Asia/Manila").format("yyyy-MM-DD HH:mm") : moment().tz("Asia/Manila").format("yyyy-MM-DD HH:mm")
    // console.log("time", prepTime);
    let status = {
      StatusNum: null,
      Note: `<strong>${UserName}</strong> updated preparation time to ${moment(prepTime).tz("Asia/Manila").format("yyyy-MM-DD HH:mm:ss A")}`,
      DateAdded: moment().tz("Asia/Manila").format(),
      StatusUpdateBy: UserName
    }
    udpateOrder(_id, {
      ...order,
      PreferredDeliveryDate: moment(prepTime).tz("Asia/Manila").format().toString(),
      StatusNote: [...StatusNote, status]
    })

  }
  const submitNewItem = (e, item) => {
    e.preventDefault()
    const { OrderDetails } = order
    let newItemList = _.map(OrderDetails, value => {
      return value._id === item._id ? item : value
    });
    let oldItem = _.find(OrderDetails, OrderDetails => {
      return OrderDetails._id === item._id
    })

    const itemPrice = calcSubTotal(newItemList)
    setSubtotal(itemPrice)

    let today = new Date();
    const { Status, _id, StatusNote } = order
    const { UserName } = user

    let status = {
      StatusNum: null,
      Note: `<strong>${UserName}</strong> edited an item (${item?.ProductName?.EN || item?.ProductName?.VN || item?.ProductName?.CN || item?.ProductName?.TH}) Quantity: ${oldItem.Quantity}->${item.Quantity}, Price: ${oldItem.Price}->${item.Price}`,
      DateAdded: moment(today).tz("Asia/Manila").format(),
      StatusUpdateBy: UserName
    }

    let newOrder = {
      ...order,
      OrderDetails: newItemList,
      Status: Status,
      StatusNote: [...StatusNote, status]
    }

    udpateOrder(_id, newOrder)
  }
  const submitPaymentDetail = (e, payment) => {
    e.preventDefault()
    const { paymentMode, paymentbankId } = payment
    const { _id } = order
    const { UserName } = user
    let status = {
      StatusNum: null,
      Note: `<strong>${UserName}</strong> payment method: ${PaymentMode} -> ${paymentMode} `,
      DateAdded: moment().tz("Asia/Manila").format(),
      StatusUpdateBy: UserName
    }
    udpateOrder(_id, {
      ...order,
      StatusNote: [...StatusNote, status],
      PaymentMode: paymentMode,
      PaymentReceipt: {
        ...order?.PaymentReceipt,
        PaymentId: paymentMode === "COD" ? null : paymentbankId
      }
    })
  }
  const submitCustomerDetail = async (e, customerDetail) => {
    e.preventDefault()
    const {
      Address,
      Area,
      Email,
      FullName,
      MobileNum,
      Place,
      PlaceId } = customerDetail

    let newOrderDetail = {...order}

    const { _id } = order
    const { UserName } = user
    let status = {
      StatusNum: null,
      Note: `<strong>${UserName}</strong> Updated Customer Contact Details `,
      DateAdded: moment().tz("Asia/Manila").format(),
      StatusUpdateBy: UserName
    }
    newOrderDetail["ContactDetails"] = {
      Email: Email,
      FullName: FullName,
      MobileNum: MobileNum,
    }

    newOrderDetail["DeliveryDetails"] = {
      Address,
      Area,
      Place,
      PlaceId,
    }

    newOrderDetail["StatusNote"] = [...StatusNote, status]

    udpateOrder(_id, newOrderDetail)
  }

  const submitCustomerPayment = (e, amountPaid) => {
    e.preventDefault()
    const { Status, _id, StatusNote } = order
    const { UserName } = user

    let status = {
      StatusNum: null,
      Note: `<strong>${UserName} Updated payment status to ${Paid ? "Not Paid" : "Paid"}</strong> `,
      DateAdded: moment().tz("Asia/Manila").format(),
      StatusUpdateBy: UserName
    }
    udpateOrder(_id, {
      ...order,
      Status: Status,
      StatusNote: [...StatusNote, status],
      PaidAmount: Number(amountPaid),
      Paid: Boolean(true),
      DatePaid: (moment().tz("Asia/Manila").format("YYYY-MM-DD")).toString
    })

  }
  const submitAddProduct = (e, productToAdd) => {
    e.preventDefault()
    const { _id, Status, StatusNote } = order //
    const { UserName } = user

    const itemPrice = calcSubTotal(productToAdd)
    setSubtotal(itemPrice)

    let status = {
      StatusNum: null,
      Note: `<strong>${UserName} added product(s) to order</strong> `,
      DateAdded: moment().tz("Asia/Manila").format(),
      StatusUpdateBy: UserName
    }
    udpateOrder(_id, {
      ...order,
      Status: Status,
      StatusNote: [...StatusNote, status],
      OrderDetails: productToAdd,
    })
  }
  const submitFee = async (e, addedFee) => {
    e.preventDefault()
    const { Status, _id, StatusNote, OrderAmount: itemPrice } = order
    const { UserName } = user
    let { totalAddedFee } = computeAddedFee(addedFee, itemPrice)

    let status = {
      StatusNum: null,
      Note: `<strong>${UserName} Added Fee to order; Total fee: ${totalAddedFee}</strong> `,
      DateAdded: moment().tz("Asia/Manila").format(),
      StatusUpdateBy: UserName
    }

    let newOrder = {
      ...order,
      Status: Status,
      StatusNote: [...StatusNote, status],
      AddFee: addedFee,
    }
    udpateOrder(_id, newOrder)
  }

  const submitUpdateVoucher = (e, coupondetails) => {
    e.preventDefault()
    const { _id, Status, StatusNote } = order
    const { UserName } = user

    // const itemPrice = calcSubTotal(productToAdd)
    // setSubtotal(itemPrice)

    let status = {
      StatusNum: null,
      Note: `<strong>${UserName} updated discount to order</strong> `,
      DateAdded: moment().tz("Asia/Manila").format(),
      StatusUpdateBy: UserName
    }

    const checkvoucher = {
      _id, 
      order: {
        ...order,
        Status: Status,
        StatusNote: [...StatusNote, status],
        CouponDetails: coupondetails,
      }
    }
    console.log("checkvoucher: ", checkvoucher)
    udpateOrder(_id, {
      ...order,
      Status: Status,
      StatusNote: [...StatusNote, status],
      CouponDetails: coupondetails
    })
  }

  const removeVoucher = (e) => {
    e.preventDefault()
    const { _id, Status, StatusNote } = order
    const { UserName } = user

    let status = {
      StatusNum: null,
      Note: `<strong>${UserName} updated discount to order</strong> `,
      DateAdded: moment().tz("Asia/Manila").format(),
      StatusUpdateBy: UserName
    }

    Swal.fire({
      title: "Removing Voucher!",
      text: "Are you sure you want to remove the applied discount?",
      allowOutsideClick: false,
      footer: "&#169; An Nam 2021", 
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Yes, proceed',
      denyButtonText: `Don't remove`,
    }).then((result) => {
      if (result.isConfirmed) { 
        udpateOrder(_id, {
          ...order,
          Status: Status,
          StatusNote: [...StatusNote, status],
          CouponDetails: null
        })
      } else if (result.isDenied) {
        Swal.fire('Removing of voucher has been revoke', '', 'info')
      }
    })
  }

  const [itemDetail, setItemDetails] = useState({})
  const [modalItemDetail, setModalItemDetail] = useState(false)
  const [modalPreparationTime, setModalPreparationTime] = useState(false)
  const [modalShippingOption, setModalShippingOption] = useState(false)
  const [modalGenerateQRCode, setModelGenerateQRCode] = useState(false)
  const [modalShippingPlaceOrder, setModalShippingPlaceOrder] = useState(false)
  const [modalEdit, setmodalEdit] = useState(false)
  const [modalPayment, setModalPayment] = useState(false)
  const [modalCustomer, setModalCustomer] = useState(false)
  const [modalPaymentStatus, setModalPaymentStatus] = useState(false)
  const [modalAddProduct, setModalAddProduct] = useState(false)
  const [selectedAddProduct, setSelectedAddProduct] = useState([])
  const [modalAddFee, setModalAddFee] = useState(false)
  const [modalPrintPOS, setModalPrintPOS] = useState(false)
  const [modalPrintWOPrice, setModalPrintWOPrice] = useState(false)
  const [modalPrintSmallPOS, setModalPrintSmallPOS] = useState(false)
  const [modalPrintPAO, setModalPrintPAO] = useState(false)
  const [modalApplyVoucher, setModalApplyVoucher] = useState(false)

  const editItem = (e, productId) => {
    e.preventDefault()
    setItemDetails(_.find(OrderDetails, value => {
      return value._id === productId
    }));
    setModalItemDetail(true)
    setmodalEdit(true)
  }
  const openModalPreparationTime = () => {
    setModalPreparationTime(true)
    setmodalEdit(true)
  }
  const openModalPayment = () => {
    setModalPayment(true)
    setmodalEdit(true)
  }

  const openModalQRCode = () => { 
    setModelGenerateQRCode(true)
    
    setmodalEdit(true) 
  }

  const openModalShippingOption = () => {
    setModalShippingOption(true)
    setmodalEdit(true) 
  }

  const openModalShippingPlaceOrder = () => {
    setModalShippingPlaceOrder(true) 
    setmodalEdit(true) 
  }

  const openPaymentStatusModal = () => {
    const { Paid, PaymentReceipt, PaymentMode,  } = order
    const PaidConfirmImages = order?.PaidConfirmImages ?? []
    const hasImageActive = PaidConfirmImages.filter(image => {
      return image.deleted === 0 || image.deleted === "0"
    })
    const { Receipt } = PaymentReceipt
    if ((!Paid && ((Receipt === null || _.isEmpty(Receipt)) && hasImageActive.length === 0)) && (PaymentMode !== "COD")) {
      Swal.fire({
        title: 'Customer did not provide receipt',
        text: "Are you sure you want to continue?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Continue'
      }).then((result) => {
        if (result.isConfirmed) {
          setModalPaymentStatus(true)
          setmodalEdit(true)
        }
      })
    } else {
      if (Paid) {
        Swal.fire({
          title: 'STOP!',
          text: "Are you sure you want to remove customer payment?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Remove payment!'
        }).then((result) => {
          if (result.isConfirmed) {
            const { Status, _id, StatusNote } = order
            const { UserName } = user
            let status = {
              StatusNum: null,
              Note: `<strong>${UserName} Updated payment status to ${Paid ? "Not Paid" : "Paid"}</strong> `,
              DateAdded: moment().tz("Asia/Manila").format(),
              StatusUpdateBy: UserName
            }
            udpateOrder(_id, {
              ...order,
              Status: Status,
              StatusNote: [...StatusNote, status],
              PaidAmount: Number(0),
              Paid: Boolean(false),
              DatePaid: ""
            })
          }
        })
      }
      else {
        setModalPaymentStatus(true)
        setmodalEdit(true)
      }

    }
  }
  const openModalAddProduct = () => {
    setModalAddProduct(true)
    setmodalEdit(true)
  }
  const openEditCustomerDetail = (e) => {
    e.preventDefault()
    setModalCustomer(true)
    setmodalEdit(true)
  }
  const openAddFee = () => {
    setModalAddFee(true)
    setmodalEdit(true) 
  }
  const openApplyVoucher = () => {
    setModalApplyVoucher(true)
    setmodalEdit(true)
  }
 
  // const openPOS = () => {
  //   setModalPrintPOS(true)
  //   setmodalEdit(true)
  // }
  const openSmallPOS = () => {
    setModalPrintSmallPOS(true)
    setmodalEdit(true)
  }

  const openPaoKitchenPOS = () => {
    setModalPrintPAO(true)
    setmodalEdit(true)
  }
  // const openWOPrice = () => {
  //   setModalPrintWOPrice(true)
  //   setmodalEdit(true)
  // }
  const closeModalEdit = () => {
    setmodalEdit(false)
    setModalPreparationTime(false)
    setModalItemDetail(false)
    setModalPayment(false)
    setModalCustomer(false)
    setModalPaymentStatus(false)
    setModalAddProduct(false)
    setModalAddFee(false)
    setModalPrintPOS(false)
    setModalPrintWOPrice(false)
    setModalPrintSmallPOS(false)
    setModalPrintPAO(false)
    setModalApplyVoucher(false)
    setModalShippingOption(false)
    setModelGenerateQRCode(false)
    setModalShippingPlaceOrder(false)
  }
  const { ContactDetails, OrderRef, DeliveryDetails, OrderNote, CreatedAt, Status, OrderDetails, PreferredDeliveryDate, SendOption, StatusNote, PaymentMode, Paid, PaidAmount } = order

  /** Print order for kitchen **/
  const [isOpenPrintKitchenOrderModal,setIsOpenPrintKitchenOrderModal] = useState(false)
  const toggleIsOpenPrintKitchenOrderModal = () => {
    setIsOpenPrintKitchenOrderModal(!isOpenPrintKitchenOrderModal)
  }

  const [payloadPrintOrderForKitchen, setPayloadPrintOrderForKitchen] = useState({index: -1, type: getObjectKey(EXTRA_ORDER_PRINT_TYPES, EXTRA_ORDER_PRINT_TYPES.all)})

  const ordersPending = useMemo(() => {
    return order?.OrdersPending ?? []
  }, [order])

  const getNeedConfirm = (type, index) =>  {
    let output = false
    const typeIsAll = type === getObjectKey(EXTRA_ORDER_PRINT_TYPES, EXTRA_ORDER_PRINT_TYPES.all)
    const typeIsExtra = type === getObjectKey(EXTRA_ORDER_PRINT_TYPES, EXTRA_ORDER_PRINT_TYPES.onlyExtra)

    if (typeIsAll || (typeIsExtra && index === - 1)) {
      if(ordersPending.length === 0) {
        output = false
      }
      else {
        const listOrderPending = ordersPending.filter(order => order.status === EXTRA_ORDER_STATUS.pending)
        output = listOrderPending.length > 0
      }
    }

    if(typeIsExtra && index !== - 1) {
      if(ordersPending.length === 0) {
        output = false
      }
      else {
        output = ordersPending[index]?.status === EXTRA_ORDER_STATUS.pending
      }
    }

    return output
  }
  const showDialogError = () => {
    Swal.fire({
      icon: "error",
      title: "Ops!",
      text: "Something went wrong. Please try again!",
      showConfirmButton: true,
      showCancelButton: false,
      confirmButtonText: "Ok"
    })
  }

  const handleUpdateStatusToDoing = async (type, index) => {
    const createRequest = async (id) => {
      const doing = EXTRA_ORDER_STATUS.doing
      await post("/cms/order/order-extra/update/status", {id, status: doing})
    }
    const typeIsAll = type === getObjectKey(EXTRA_ORDER_PRINT_TYPES, EXTRA_ORDER_PRINT_TYPES.all)
    const typeIsExtra = type === getObjectKey(EXTRA_ORDER_PRINT_TYPES, EXTRA_ORDER_PRINT_TYPES.onlyExtra)

    const requests = []
    if (typeIsAll || (typeIsExtra && index === - 1)) {
      for (let i = 0; i < ordersPending.length; i++) {
        const orderPending = ordersPending[i]
        const id = orderPending?._id
        if(orderPending?.status === EXTRA_ORDER_STATUS.pending && id){
          requests.push(createRequest(id))
        }
      }
    }
    else if (type === EXTRA_ORDER_PRINT_TYPES.onlyExtra) {
      const record = ordersPending[index]
      if(record?._id){
        requests.push(createRequest(record?._id))
      }
    }

    if(requests) {
      try {
        Swal.showLoading()
        Promise
            .all(requests)
            .then(() => {
              Swal.fire({
                icon: "success",
                title: "Success",
                text: "Order has bean updated",
                showConfirmButton: true,
                showCancelButton: false,
                confirmButtonText: "Ok"
              }).then(() => {
                setPayloadPrintOrderForKitchen({
                  type, index
                })
                setIsOpenModalPrint(true)
              })
            })
            .catch(e => {
              Swal.close()
              console.log(e)
              showDialogError()
            })
      }
      catch (e) {
        Swal.close()
        console.log(e)
        showDialogError()
      }
    }
  }

  const onConfirmPrintKitchenOrder = ({type, index}) => {
    const _index = parseInt(index) ?? -1
    const needConfirm = getNeedConfirm(type, _index)
    if(needConfirm) {
      Swal.fire({
        icon: "question",
        title: "Are you sure?",
        text: "Order status will be updated to Doing.",
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel"
      })
      .then(result => {
        if(result.isConfirmed) {
          handleUpdateStatusToDoing(type, _index)
          .then(() => {})
        }
      })
    }
    else {
      setPayloadPrintOrderForKitchen({
        type, index: _index
      })
      setIsOpenModalPrint(true)
    }
  }

  const [isOpenModalPrint, setIsOpenModalPrint] = useState(false)
  const onPrintSuccess = () => {
    setIsOpenModalPrint(false)
  }

  const onCancelPrint = () => {
    setIsOpenModalPrint(false)
  }

  return (
    <main className="content-main">
      <section className="content-main-breadcrumbs">
        e-commerce / orders / edit
      </section>
      {/*<h1 className='order-title margin-bottom-20 flex-row-start-center gap-10'>Order #{getData(OrderRef)} <button className="btn-edit-skeleton" onClick={() => { openPOS() }}>Print</button> | <button className="btn-edit-skeleton" onClick={() => { openSmallPOS() }}>Print POS</button>*/}
      {/*  | <button className="btn-edit-skeleton" onClick={() => { openWOPrice() }}>Print Without Price</button>*/}
      <h1 className='order-title margin-bottom-20 flex-row-start-center gap-10'>Order #{getData(OrderRef)}
        <button className="btn-edit-skeleton btn-print-order" onClick={() => { openSmallPOS() }}>Print Annam Kitchen POS</button>
        | <button className="btn-edit-skeleton btn-print-order" onClick={() => { openPaoKitchenPOS() }}>Print Pao Kitchen POS</button>
        | <button className="btn-edit-skeleton btn-print-order" onClick={toggleIsOpenPrintKitchenOrderModal}>Print order for kitchen</button>
      </h1>
      <div className='order-container'>
        <div className='order-container-left'>
          <OrderCustomerDetail {...{ getData, ContactDetails, DeliveryDetails, openEditCustomerDetail, currentStatus }} />
          <PaymentMethodAndNote {... {getData, paymentMethod, OrderNote, openModalPayment, openPaymentStatusModal, Paid, PaidAmount, currentStatus, order, getOrderDetail}} />
          <OrderDateAndStatus {...{ getData, getStatus, CreatedAt, Status, }} />
          <OrderDetailTable {...{
            OrderDetails, getData, order, setOrder, setSubtotal, udpateOrder, user, editItem, openModalAddProduct,
            openAddFee,
            computeAddedFee,
            currentStatus,
            getOrderDetail,
            openApplyVoucher,
            removeVoucher
          }} />
          {/* end of left container */}
        </div>

        <div
            className={`order-container-right`}>
          <PrefferedTime {...{ getData, CreatedAt, PreferredDeliveryDate, openModalPreparationTime, currentStatus }} />
          <div className='width-100 order-container-right-container'>
            <div className='order-container-right-container-content'>
              <span className='time-label'>Send option </span>
            </div>
            <SendOptionContainer {...{ SendOption, handleChange, currentStatus }} />
          </div>
          <ShippingOptionContainer  {...{ getData, order, currentPlaceOrderId, currentStatus, openModalShippingOption, openModalShippingPlaceOrder }}/>
          <ConfirmPaymentStatus order={order} />
          <OrderStatusContainer
            {...{
              Status, updateOrderStatus,
              setStatusNote,
              statusNote, setOrder, order,
              currentStatus,
              currentPaymentStatus,
              openModalQRCode
            }}
          />
          <div className='width-100 order-container-right-container'>
            <div className='order-container-right-container-content margin-bottom-20'>
              <span className='time-label'>Logs

              </span>
            </div>
            <div className='fix-height'>
              {
                _.isEmpty(StatusNote) ? (<div className='order-container-right-container-content'> No status log</div>)
                  :
                  (
                    _.map(StatusNote, (value, key) => {
                      return (
                        <LogsC key={key} {...{ value, getData, getStatus }} />
                      )
                    })
                  )
              }
            </div>
          </div>
        </div>
      </div>
      <EditOrderModal
        {...{
          modalEdit,
          modalPreparationTime, // for preparation time conditional rendering
          closeModalEdit, // for closing modal
          PreferredDeliveryDate, // for preparation time edit
          submitNewPreparationTime, // preparation time function
          orderE, // for error handling 
          itemDetail, // for edit item detail in product order list
          modalItemDetail, // for opening the product modal
          submitNewItem, // submit new item detail
          modalPayment, // for payment option and bank detail modal
          PaymentMode, // type of payment the use has
          submitPaymentDetail, // submit function for payment detail
          modalCustomer, // for customer contact detail
          setOrder,
          order, // orderdetail
          submitCustomerDetail, // submit customer contact details edit
          modalPaymentStatus,
          submitCustomerPayment,
          modalAddProduct,
          selectedAddProduct,
          setSelectedAddProduct,
          submitAddProduct,
          modalAddFee,
          submitFee,
          modalPrintPOS,
          paymentMethod,
          subtotal,
          computeAddedFee,
          modalPrintWOPrice,
          modalPrintSmallPOS,
          modalPrintPAO,
          modalShippingOption,
          setCurrentPlaceOrderId,
          currentPlaceOrderId,
          modalShippingPlaceOrder,
          getOrderDetail,
          modalGenerateQRCode,
          modalApplyVoucher,
          submitUpdateVoucher
        }}
      />
      <ModalConfirmKitchenPrintOrder order={order} toggle={toggleIsOpenPrintKitchenOrderModal} isOpen={isOpenPrintKitchenOrderModal} onConfirm={onConfirmPrintKitchenOrder}/>
      {
        !isOpenModalPrint ? null :  <KitchenPrintOrder order={order} type={payloadPrintOrderForKitchen.type} index={payloadPrintOrderForKitchen.index} isOpen={isOpenModalPrint} onCancel={onCancelPrint} onSuccess={onPrintSuccess} />
      }
    </main>
  )
}

export default EditOrder