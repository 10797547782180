import AccessibilityIcon from '@mui/icons-material/Accessibility';
import CircleIcon from "@mui/icons-material/Circle";
import EditIcon from '@mui/icons-material/Edit';
import moment from "moment";
import { useEffect, useRef } from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';
const CategoryTable = ({ userList, showUpdateUserForm }) => {
  const container = useRef(null)
  useEffect(() => {
    container.current.getElement().id = "userTable"

    return () => {

    }
  }, [])
  return (
    <ScrollContainer className="scroll-container content-product-table" id="userTable" ref={container}>
      <div className="content-product-table-head">
        <div className="content-product-table-head-item item-name">Username</div>
        <div className="content-product-table-head-item item-name">Role</div>
        <div className="content-product-table-head-item item-name">Status</div>
        <div className="content-product-table-head-item item-name">Date Created</div>
        <div className="content-product-table-head-item center item-name">Actions</div>
      </div>
      {userList ? (
        Object.keys(userList).map((key, index) => {
          const { UserName, Role, IsActive, DateCreated, _id } =
            userList[key];
          let now = new Date(DateCreated);
          let dateCreated = moment(now).format("YYYY-MM-DD HH:MM:SS");
          return (
            <div className="content-product-table-data item-name" key={index}>
              <div className="content-product-table-data-item item-name">{UserName}</div>
              <div className="content-product-table-data-item item-name">{Role}</div>
              <div className="content-product-table-data-item item-name">
                {IsActive ? (
                  <span>
                    <CircleIcon sx={{ color: "green", fontSize: "12px" }} />{" "}
                    Active
                  </span>
                ) : (
                  <span>
                    <CircleIcon sx={{ color: "red", fontSize: "12px" }} />{" "}
                    Inactive
                  </span>
                )}
              </div>
              <div className="content-product-table-data-item item-name">
                {dateCreated}
              </div>
              <div className="content-product-table-data-item action center item-name">
                <button
                  onClick={() =>
                    showUpdateUserForm(_id, ".content-main-update-user")
                  }
                >
                  <EditIcon />
                </button>
                <button
                  onClick={() => {
                    showUpdateUserForm(_id, ".content-main-access-user");
                  }}
                >
                  <AccessibilityIcon />
                </button>
              </div>
            </div>
          );
        })
      ) : (
        <div className="content-main-table-data">
          <div className="content-main-table-data-item-result">
            No Data Found
          </div>
        </div>
      )}
    </ScrollContainer>
  )
};

export default CategoryTable;
