import FmdGoodIcon from "@mui/icons-material/FmdGood";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Backdrop, Fade, Modal } from '@mui/material';
import { Box } from '@mui/system';
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { setToken } from "../../../redux/actions/token";
import { ChangePassword } from "./userHelper";
import TaskNotification from "./TaskNotification";
const Navigation = () => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'white',
    boxShadow: "0px 3px 10px #2a0e4e21",
    borderRadius: "6px",
    pt: 2,
    px: 4,
    pb: 3,
  };
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const axios = require("axios");
  const history = useNavigate();
  const jwt = require("jsonwebtoken");
  const tokens = useSelector((state) => state.token);
  const [branch, setBranch] = useState({});
  const dispatch = useDispatch();

  let isMenuToggled = false;

  const { token, branchId } = tokens.token;
  const user = jwt.decode(token);
  const { Branches } = user;

  useEffect(() => {
    // console.log(user);
    if (branchId) {
      branchData();
      let getBranchData = {
        method: "get",
        url: `${baseUrl}/cms/branch/list?page=1&isActive=true&SelectedBranch=${branchId}`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Auth-Token": token,
        },
      };
      axios(getBranchData)
        .then(function (response) {
          //console.log(response);
        })
        .catch(function (error) {
          if (error?.response?.status === 401) {
            logout();
          } else if (error?.response?.status === 403) {
            logout();
          }
        });
    }

    // eslint-disable-next-line
  }, [tokens, axios, branchId, token]);
  const branchData = () => {
    const result = Branches.filter(
      (Branches) => Branches.BranchId === branchId
    );
    setBranch({ City: result[0].City, Name: result[0].Name });
  };
  const logout = () => {
    localStorage.removeItem("cmsToken");
    localStorage.removeItem("branchId");
    history("/admin");
    dispatch(setToken({ branchId: "", token: "" }));
  };
  const toggleMenu = () => {
    if (!isMenuToggled) {
      document.querySelector(".menu-btn__burger").classList.add("open");
      document.querySelector(".content-nav-floating").style.display = "flex";
      isMenuToggled = true;
    } else {
      document.querySelector(".menu-btn__burger").classList.remove("open");
      document.querySelector(".content-nav-floating").style.display = "none";
      isMenuToggled = false;
    }
  };
  const showBranchDialog = () => {
    document.querySelector(".branch-selection").style.display = "flex";
  };
  const [passwordEdit, setpasswordEdit] = useState(false)
  const closePasswordEdit = (e) => {
    e.preventDefault()
    setpasswordEdit(false)
  }
  const [password, setPassword] = useState({
    newPassword: "",
    confirmPassword: ""
  })
  const [passwordE, setPasswordE] = useState({
    newPassword: "",
    confirmPassword: "",
    errorMessage: ""
  })

  const changePasswordValue = (e) => {
    const { name, value } = e.target
    setPassword(oldValue => {
      return {
        ...oldValue,
        [name]: value
      }
    })
  }
  const submitChangePassword = (e) => {
    e.preventDefault()
    setPasswordE({
      newPassword: "",
      confirmPassword: "",
      errorMessage: ""
    })
    if (_.isEmpty(password.newPassword) && _.isEmpty(password.confirmPassword)) {
      setPasswordE({
        newPassword: "Please fill this field",
        confirmPassword: "Please fill this field"
      })
    } else if (_.isEmpty(password.newPassword)) {
      setPasswordE({
        newPassword: "Please fill this field"
      })
    } else if (_.isEmpty(password.confirmPassword)) {
      setPasswordE({
        confirmPassword: "Please fill this field"
      })
    } else {
      if (password.newPassword !== password.confirmPassword) {
        setPasswordE({
          errorMessage: "Password did not match"
        })
      } else {
        setPasswordE({
          newPassword: "",
          confirmPassword: "",
          errorMessage: ""
        })
        let data = {
          Password: password.newPassword
        }
        ChangePassword(user._id, data)
          .then(
              () => {
              Swal.fire({
                toast: true,
                icon: 'success',
                title: 'Password successfully updated',
                animation: false,
                position: 'top-right',
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener('mouseenter', Swal.stopTimer)
                  toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
              })
              setPassword({
                newPassword: "",
                confirmPassword: ""
              })
              closePasswordEdit(e)
            }
          )
          .catch(
            err => {
              Swal.fire({
                icon: "error",
                title: "Failed",
                text: "There was a problem occurred while doing your request! 😔",
                footer: "&#169; An Nam 2021"
              })
              console.log(err.response);
            }
          )
      }
    }
  }
  const [newPasswordType, setnewPasswordType] = useState("password")
  const [confirmPasswordType, setconfirmPasswordType] = useState("password")
  const { newPassword, confirmPassword } = password
  return (
    <nav className="content-nav">
      {branchId ? (
        <div className="content-nav-location" onClick={showBranchDialog}>
          <FmdGoodIcon />
          <span>
            {branch.Name} ~ {branch.City}
          </span>
        </div>
      ) : (
        <div className="content-nav-location"></div>
      )}

      <div className="block-right-container">
        <TaskNotification />

        <div className="content-nav-user">
          <div className="content-nav-user-item">
            <span className="content-nav-user-name">{user.UserName}</span>
            <span className="content-nav-user-role">{user.Role}</span>
          </div>
          <div className="menu-btn" onClick={toggleMenu}>
            <span className="menu-btn__burger"></span>
          </div>
        </div>
        <div className="content-nav-floating">
          <button
              disabled={!branchId}
              onClick={(e) => {
                e.preventDefault()
                setpasswordEdit(true)
              }}>Change password</button>
          <button onClick={() => logout()}>Logout</button>
        </div>
      </div>

      <Modal
        open={passwordEdit}
        onClose={(e) => { closePasswordEdit(e) }}
        aria-labelledby="order edit modal"
        aria-describedby="order edit modal"
        closeAfterTransition
        BackdropComponent={Backdrop}
        style={{
          zIndex: 998
        }}
        BackdropProps={{
          timeout: 500,
        }}>
        <Fade in={passwordEdit}>
          <Box sx={{ ...style, width: 400 }}>
            <div className="flex-column width-100 gap-10">
              <h3>Change Password</h3>
              <div className="input">
                <label htmlFor="newPassword">New Password</label>
                <input onChange={(e) => { changePasswordValue(e) }} value={newPassword} autoComplete="off" type="password" name="newPassword" id="newPassword" placeholder="Enter New Password" />
                <button className="showpassicon" onClick={() => {
                  let type = document.getElementById('newPassword')
                  type.type = type.type === "password" ? "text" : "password"
                  setnewPasswordType(type.type)
                }}>{newPasswordType === "password" ? <VisibilityIcon /> : <VisibilityOffIcon />}</button>
              </div>
              {
                _.isEmpty(passwordE.newPassword) ? <></> : <small className="error-message">{passwordE.newPassword}</small>
              }
              <div className="input">
                <label htmlFor="confirmNewPassword">Confirm New Password</label>
                <input onChange={(e) => { changePasswordValue(e) }} value={confirmPassword} autoComplete="off" type="password" name="confirmPassword" id="confirmNewPassword" placeholder="Confirm New Password" />
                <button className="showpassicon" onClick={() => {
                  let type = document.getElementById('confirmNewPassword')
                  type.type = type.type === "password" ? "text" : "password"
                  setconfirmPasswordType(type.type)
                }}>{confirmPasswordType === "password" ? <VisibilityIcon /> : <VisibilityOffIcon />}</button>
              </div>
              {
                _.isEmpty(passwordE.confirmPassword) ? <></> : <small className="error-message">{passwordE.confirmPassword}</small>
              }
              {
                _.isEmpty(passwordE.errorMessage) ? <></> : <small className="error-message">{passwordE.errorMessage}</small>
              }
              <div className='flex-end gap-10'>
                <button className='btn-cancel' onClick={(e) => {
                  closePasswordEdit(e)
                }}>Cancel</button>
                <button type="submit" className='btn-submit' onClick={(e) => {
                  submitChangePassword(e)
                }}>Save</button>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>


    </nav>
  );
};

export default Navigation;
